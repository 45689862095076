import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDistrictList,
  getProducts,
  getSelectedDistributor,
  getStatesList,
  getOrderListRest,
  getSalesPersonWithoutPage,
  getDispatchByWithOutPage,
  targetAdd,
} from "../reducers/commonReducer";
import moment from "moment";
import SideMenu from "../Components/SideMenu";
import { Row, Col, Form, Button } from "react-bootstrap";
import ExportData from "../Components/ExportData";
import UpdateSelectedDistributor from "../Components/UpdateSelectedDistributor";
import { Link } from "react-router-dom";
import CreateOrder from "../Components/CreateOrder";
import DistributorAdd from "../Components/DistributorAdd";
import TargetAction from "../Targate/TargetAction";

const Distributors = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const status = queryParameters.get("type");
  const dispatch = useDispatch();
  const [state, setState] = useState();
  const [mode, setMode] = useState();
  const [district, setDistrict] = useState();
  const [page, setPage] = useState(1);
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [startDate, setStartDate] = useState();
  const {
    getSelectedDistributorList,
    getDistrict,
    getSelectedDistributorListMsg,
    statesList,
  } = useSelector((state) => state.commonReducer);
  const header = [
    "Sr",
    "FirmName",
    "Town",
    "District",
    "State",
    "SalesPerson",
    "Asm",
    "Dispatch By",
    "Status",
    "Contact No.",
    "GSTIN",
    "Mode",
    "Date",
    "Email",
  ];
  let body = [];
  !!getSelectedDistributorList &&
    getSelectedDistributorList.map((data, index) => {
      body.push({
        Sr: index + 1,
        firmName: data?.firmName,
        Town: data?.address?.town,
        District: data?.address?.district,
        State: data?.address?.state,
        SalesPerson: data?.salesmanName?.name,
        Asm: data?.asm,
        DispatchBy: data?.dispatchBy[0]?.userName
          ? data?.dispatchBy[0]?.userName
          : "" + ", " + data?.dispatchBy[1]?.userName
          ? data?.dispatchBy[1]?.userName
          : "",
        Status: data?.status,
        ContactNo: data?.number,
        // Address:
        //   data?.address?.town +
        //   ", " +
        //   data?.address?.district +
        //   ", " +
        //   data?.address?.state,
        GSTIN: data?.GSTIN,
        Mode: data?.mode,
        Date: moment(data.createdAt).format("DD/MM/YYYY"),
        email: data?.email,
      });
    });
  useEffect(() => {
    dispatch(getOrderListRest());
    dispatch( 
      getSelectedDistributor({
        mode: "OffLine",
        status
      })
    );
    dispatch(getStatesList());
    dispatch(getProducts());
    dispatch(getSalesPersonWithoutPage());
    dispatch(getDispatchByWithOutPage());
  }, [status]);

  return (
    <>
      <SideMenu />
      <div className={`mt-extra content container-fluid close-menu-tab`}>
        <Row className="mb-3">
          <Col md={2} sm={12}>
            <DistributorAdd btnTitle="Add Distributor" mode={"OffLine"} />
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setPage(1);
                setMode(e.target.value);
                dispatch(getOrderListRest());
                dispatch(
                  getSelectedDistributor({
                    mode: e.target.value,
                    state,
                    startDate,
                    endDate,
                    status
                  })
                );
              }}
              name="mode"
              className="form-control"
            >
              <option value="OffLine">OffLine</option>
              <option value="Online">Online</option>
            </Form.Control>
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setPage(1);
                setState(e.target.value);
                dispatch(getOrderListRest());
                dispatch(
                  getSelectedDistributor({
                    state: e.target.value,
                    startDate,
                    endDate,
                    status
                  })
                );
                e.target.value.length > 1 &&
                  dispatch(getDistrictList(e.target.value));
              }}
              name="state"
              className="form-control"
            >
              <option value="">Select State</option>
              {!!statesList &&
                statesList.map((data, index) => {
                  return (
                    <option value={data?.state} key={index}>
                      {data?.state}
                    </option>
                  );
                })}
            </Form.Control>
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setPage(1);
                setDistrict(e.target.value);
                dispatch(getOrderListRest());
                dispatch(
                  getSelectedDistributor({
                    state,
                    district: e.target.value,
                    startDate,
                    endDate,
                    status
                  })
                );
              }}
              name="state"
              className="form-control"
            >
              <option value="">Select District</option>
              {!!getDistrict &&
                getDistrict.map((data, index) => {
                  return (
                    <option value={data} key={index}>
                      {data}
                    </option>
                  );
                })}
            </Form.Control>
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              type="date"
              placeholder="Start Date"
              name="endDate"
              onChange={async (e) => {
                setPage(1);
                setStartDate(e.target.value);
                dispatch(getOrderListRest());
                dispatch(
                  getSelectedDistributor({
                    startDate: e.target.value,
                    endDate,
                    state,
                    district,
                    status
                  })
                );
              }}
              value={startDate}
            />
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              type="date"
              placeholder="End Date"
              name="startDate"
              onChange={async (e) => {
                setPage(1);
                setEndDate(e.target.value);
                dispatch(getOrderListRest());
                dispatch(
                  getSelectedDistributor({
                    startDate,
                    endDate: e.target.value,
                    state,
                    district,
                    status
                  })
                );
              }}
              value={endDate}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={12}>
            <Form.Control
              placeholder="Search Your Firm"
              type="text"
              onChange={async (e) => {
                dispatch(getOrderListRest());
                dispatch(
                  getSelectedDistributor({
                    search: e.target.value,
                    status
                  })
                );
              }}
              className="form-control"
            ></Form.Control>
          </Col>
          <Col md="1">
            <Button
              variant="danger"
              onClick={() => window.location.reload(false)}
            >
              Clear
            </Button>
          </Col>
          <Col className="mbt-phone mb-2">
            <ExportData
              title="Export Distributor"
              header={header}
              body={body}
              tableName="ShyamG All Distributor"
            />
          </Col>
        </Row>
        <table className="table table-scrolling">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">FirmName</th>
              <th className="over-col-size" scope="col">
                Address
              </th>

              <th scope="col">Orders</th>
              <th scope="col">SalesPerson</th>
              <th scope="col">ASM</th>
              <th scope="col">Dispatch By</th>
              <th scope="col">Status</th>
              <th scope="col">Contact No.</th>
              <th scope="col">GSTIN</th>
              <th scope="col">Mode</th>
              <th scope="col">Date</th>
              {localStorage.getItem("x-auth-token") &&
                (localStorage.getItem("userRole") === "1" ||
                  localStorage.getItem("userRole") === "7" ||
                  localStorage.getItem("userRole") === "11") && (
                  <th scope="col">Action</th>
                )}
              <th scope="col">Name</th>
              <th scope="col">UserName</th>
              <th scope="col">User Type</th>
            </tr>
          </thead>
          <tbody>
            {!!getSelectedDistributorList &&
              getSelectedDistributorList.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>
                      {data?.firmName}<br/>
                     {(data?.targetMonth !== moment().month() + 1) && 
                     <TargetAction api={targetAdd} title="Add" distributor={data?._id}/>
                      }  
                     <Link to={`/user-wise-target?userid=${data?._id}`}><Button variant="success" className="m-1">
                           Target <i className="bi bi-arrow-right-short"></i>
                          </Button></Link>
                    </td>
                    <td>
                      {data?.address?.town +
                        ", " +
                        data?.address?.district +
                        ", " +
                        data?.address?.state}
                    </td>
                    <td>
                      {data?.other != 0 && (
                        <Link
                          to={`/distributor-orders/${data?.userName}?so=${
                            data?.roll != 8 ? data?.userName : ""
                          }&distributor=${data?.userName && data?.userName}`}
                        >
                          <Button variant="primary" className="m-1">
                            Pending Order({data?.other})
                          </Button>
                        </Link>
                      )}
                      {data?.deliver != 0 && (
                        <Link
                          to={`/distributor-delivered-orders/${
                            data?.userName
                          }?so=${
                            data?.roll != 8 ? data?.userName : ""
                          }&distributor=${data?.userName && data?.userName}`}
                          className="f-right"
                        >
                          <Button variant="success" className="m-1">
                            Delivered Order({data?.deliver})
                          </Button>
                        </Link>
                      )}
                    </td>
                    <td>{data?.salesmanName?.name}</td>
                    <td>{data?.asm}</td>
                    <td>
                      {!!data?.dispatchBy &&
                        data?.dispatchBy.map((data, index) => (
                          <span>{`${data?.dispatch}, `}</span>
                        ))}
                    </td>
                    <td>
                      <b>{data?.status}</b>
                    </td>
                    <td>{data?.number}</td>

                    <td>{data?.GSTIN}</td>
                    <td
                      className={
                        data?.mode == "Online" ? "text-success" : "text-warning"
                      }
                    >
                      <b>{data?.mode}</b>
                    </td>
                    <td>{moment(data.createdAt).format("DD/MM/YYYY")}</td>
                    <td>
                      {localStorage.getItem("x-auth-token") && status === "Active" &&
                        ((localStorage.getItem("userRole") === "7") || (localStorage.getItem("userRole") === "1")) && (
                          <CreateOrder
                            town={data?.address?.town}
                            district={data?.address?.district}
                            state={data?.address?.state}
                            salesMan={data?.salesmanName}
                            distributor={data?.userName}
                            number={data?.number}
                            firmName={data?.firmName}
                            userName={data?.userName}
                            edispatchStatus={data?.dispatchStatus}
                            assignDispatchBy={data?.dispatchBy}
                          />
                        )}
                      {/* {localStorage.getItem("x-auth-token") && localStorage.getItem("userRole") == 1 && (
                        <Button
                          className="m-1"
                          variant="danger"
                          onClick={async () => {
                            await dispatch(
                              deleteDistributorSelectedRecord(data?._id)
                            );
                            dispatch(getSelectedDistributor());
                          }}
                        >
                          <i className="bi bi-trash"></i>
                        </Button>)} */}
                      {localStorage.getItem("x-auth-token") &&
                        (localStorage.getItem("userRole") === "1" ||
                          localStorage.getItem("userRole") === "11") && (
                          <UpdateSelectedDistributor
                            userStatus={data.status}
                            id={data._id}
                            userSalesmanName={data?.salesmanName}
                            dispatchList={data?.dispatchBy}
                            name={data?.name}
                            number={data?.number}
                            gst={data?.GSTIN}
                            email={data?.email}
                            firmName={data?.firmName}
                            dispatchStatus={data?.dispatchStatus}
                            uaddress={data?.address}
                            status={status}
                            state={state} district ={district} 
                            endDate ={endDate} startDate={startDate}
                          />
                        )}
                    </td>
                    <td>{data?.name}</td>
                    <td>{data?.userName}</td>
                    <td>
                      {data?.dispatchStatus ? "Super Stockist" : "Distributor"}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {!!getSelectedDistributorListMsg &&
        getSelectedDistributorListMsg.length > 0 ? (
          <Row>
            <Button
              onClick={() => {
                setPage(page + 1);
                dispatch(
                  getSelectedDistributor({
                    page: page + 1,
                    state,
                    district,
                    startDate,
                    endDate,
                    status
                  })
                );
              }}
            >
              Load More
            </Button>
          </Row>
        ) : (
          <p className="text-center">
            <b>No record found 😔.</b>
          </p>
        )}
      </div>
    </>
  );
};

export default Distributors;
