import React, { useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  getCreateSalesPerson,
  getOrderListRest,
  updateSalesPerson,
  getDistrictList,
  getASMPersonsWithOutPage,
  getDispatchByWithOutPage,
  getSelectedDistributorWithoutPage,
} from "../reducers/commonReducer";
import { Formik } from "formik";
import * as yup from "yup";

const schema = yup.object().shape({
  name: yup.string().required(),
  number: yup.string().min(10).max(10),
  password: yup.string(),
  town: yup.string().required(),
  district: yup.string(),
  state: yup.string().required(),
  asm: yup.string().required(),
  dispatchBy: yup.array(),
  distributorList: yup.array(),
});

function UpdateSalesMan(props) {
  const timeoutIdRef = React.useRef();
  const dispatch = useDispatch();
  const {
    selectedDistributorWithoutPage,
    asmPersonsWithOutPage,
    statesList,
    getDistrict,
    getProductsList,
    dispatchByWithOutPage,
  } = useSelector((state) => state.commonReducer);
  const {
    userStatus,
    id,
    userAsm,
    name,
    number,
    town,
    userAssignproducts,
    district,
    state,
    dispatchlist,
    distributers,
    filterStatus, filterstate, filterdistrict, filterendDate, filterstartDate
  } = props;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        <i className="bi bi-pencil-square"></i>
      </Button>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update SalesMan</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            dispatch(updateSalesPerson(values));
            resetForm({ values: "" });
            setShow(false);
            dispatch(getOrderListRest());
            clearTimeout(timeoutIdRef.current);
            timeoutIdRef.current = setTimeout(() => {
              dispatch(getCreateSalesPerson({
                userStatus:filterStatus, state:filterstate, 
                district:filterdistrict, endDate:filterendDate, 
                startDate:filterstartDate
              }));
            }, 1000);
            dispatch(getASMPersonsWithOutPage({}));
            dispatch(getSelectedDistributorWithoutPage());
            dispatch(getDispatchByWithOutPage());
          }}
          initialValues={{
            id: id,
            name: name,
            asm: userAsm,
            number: number,
            town: town,
            district: district,
            state: state,
            password: "",
            status: userStatus,
            dispatchBy:
              !!dispatchlist &&
              dispatchlist.map((data) => (data?._id ? data?._id : data?.id)),
            distributorList:
              !!distributers &&
              distributers.map((data) => (data?._id ? data?._id : data?.id)),
            shyamg:
              !!userAssignproducts &&
              userAssignproducts.map((data) => {
                if (data?.itemName.match("WaahJi")) {
                  return null;
                } else {
                  return data?._id ? data?._id : data?.id;
                }
              }),
            waahJi:
              !!userAssignproducts &&
              userAssignproducts.map((data) => {
                if (!data?.itemName.match("WaahJi")) {
                  return null;
                } else {
                  return data?._id ? data?._id : data?.id;
                }
              }),
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
            <Form noValidate onSubmit={handleSubmit} className="p-3">
              <Row className="pb-2">
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="number"
                      name="number"
                      value={values.number}
                      onChange={handleChange}
                      isInvalid={!!errors.number}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.number}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} sm={12}>
                  <Form.Label>State</Form.Label>
                  <br />
                  <Form.Control
                    style={{ textTransform: "capitalize" }}
                    as="select"
                    onChange={async (e) => {
                      await setFieldValue("state", e.target.value);
                      dispatch(getDistrictList(e.target.value));
                    }}
                    name="state"
                    className="form-control"
                    value={values.state}
                    isInvalid={!!errors.state}
                  >
                    <option value="">Select State</option>
                    {statesList.map((data, index) => {
                      return (
                        <option
                          className="first-latter-capital"
                          key={index}
                          value={data.state}
                        >
                          {data.state}
                        </option>
                      );
                    })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.state}
                  </Form.Control.Feedback>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Label>District</Form.Label>
                  <br />
                  <Form.Control
                    style={{ textTransform: "capitalize" }}
                    as="select"
                    name="district"
                    onChange={handleChange}
                    value={values.district}
                    className="form-control"
                    isInvalid={!!errors.district}
                  >
                    <option value="">Select District</option>
                    {!!getDistrict &&
                      getDistrict.map((data, index) => {
                        return (
                          <option
                            className="first-latter-capital"
                            value={data}
                            key={index}
                          >
                            {data}
                          </option>
                        );
                      })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.district}
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>Town</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Town"
                      name="town"
                      value={values.town}
                      onChange={handleChange}
                      isInvalid={!!errors.town}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.town}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      isInvalid={!!errors.password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="pb-2">
                <Col md={6}>
                  <Form.Group>
                    <label>Status</label>
                    <Form.Control
                      as="select"
                      onChange={handleChange}
                      name="status"
                      className="form-control"
                      value={values.status}
                      isInvalid={!!errors.status}
                    >
                      <option value="">Select Status</option>
                      <option value={"Active"}>Active</option>
                      <option value={"Inactive"}>Inactive</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Label>ASM</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={handleChange}
                    name="asm"
                    className="form-control"
                    value={values.asm}
                    isInvalid={!!errors.asm}
                  >
                    <option value="">Select ASM</option>
                    {!!asmPersonsWithOutPage &&
                      asmPersonsWithOutPage.map((data, index) => {
                        return (
                          <option
                            className="first-latter-capital"
                            key={index}
                            value={data.userName}
                          >
                            {data.userName}
                          </option>
                        );
                      })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.asm}
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <Row className="pb-2">
                <Col md={6} sm={6}>
                  <Form.Label>
                    <b>Select Disrtibutor</b>
                  </Form.Label>
                  <br />
                  <Form.Control
                    as="select"
                    multiple={true}
                    onChange={handleChange}
                    name="distributorList"
                    className="form-control"
                    value={values.distributorList}
                    isInvalid={!!errors.distributorList}
                  >
                    {!!selectedDistributorWithoutPage &&
                      selectedDistributorWithoutPage.map((data, index) => {
                        return (
                          <option
                            className="first-latter-capital"
                            key={index}
                            value={data?._id}
                          >
                            {data?.firmName}
                          </option>
                        );
                      })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.distributorList}
                  </Form.Control.Feedback>
                </Col>
                <Col md={6} sm={6} className="mt-1">
                  <Form.Label>
                    <b>Select SuperStockist</b>
                  </Form.Label>
                  <br />
                  <Form.Control
                    as="select"
                    multiple={true}
                    onChange={handleChange}
                    name="dispatchBy"
                    className="form-control"
                    value={values.dispatchBy}
                    isInvalid={!!errors.dispatchBy}
                  >
                    {!!dispatchByWithOutPage &&
                      dispatchByWithOutPage.map((data, index) => {
                        return (
                          <option
                            className="first-latter-capital"
                            key={index}
                            value={data?._id ? data?._id : data?.id}
                          >
                            {data?.dispatch}
                          </option>
                        );
                      })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.dispatchBy}
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <Row>
                <Col md={6} sm={12}>
                  <Form.Label>
                    <b>Select ShyamG Products</b>
                  </Form.Label>
                  <br />
                  <Form.Control
                    as="select"
                    multiple={true}
                    name="shyamg"
                    onChange={handleChange}
                    className="form-control"
                    value={values.shyamg}
                  >
                    {!!getProductsList &&
                      getProductsList.map((data, index) => {
                        return (
                          !data?.itemName.match("WaahJi") && (
                            <option
                              className="first-latter-capital"
                              key={index}
                              value={data._id}
                            >
                              {data?.itemName}
                            </option>
                          )
                        );
                      })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.products}
                  </Form.Control.Feedback>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Label>
                    <b>Select WaahJi Products</b>
                  </Form.Label>
                  <br />
                  <Form.Control
                    as="select"
                    multiple={true}
                    name="waahJi"
                    value={values.waahJi}
                    onChange={handleChange}
                    className="form-control"
                  >
                    {!!getProductsList &&
                      getProductsList.map((data, index) => {
                        return (
                          data?.itemName.match("WaahJi") && (
                            <option
                              className="first-latter-capital"
                              key={index}
                              value={data.id ? data.id : data._id}
                            >
                              {data?.itemName}
                            </option>
                          )
                        );
                      })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.products}
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <Button type="submit">Submit</Button>
              <Button onClick={handleClose} variant="danger">
                Close
              </Button>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default UpdateSalesMan;
