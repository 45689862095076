import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCreateSalesPerson,
  getOrderListRest,
  getSoMonthlyReports,
  getStatesList,
} from "../reducers/commonReducer";
import moment from "moment";
import SideMenu from "../Components/SideMenu";
import { Row, Col, Form, Button } from "react-bootstrap";
import { commonFunction } from "./commonFunction/commonFunction";

const SalesManMonthWiseReport = () => {
  const dispatch = useDispatch();
  const [salesMan, setSalesMan] = useState();
  const [startMonth, setStartMonth] = useState();
  const [endMonth, setEndMonth] = useState();
  const [state, setState] = useState();
  const [number, setNumber] = useState();
  const [userState, setUserState] = useState();
  const [district, setDistrict] = useState();
  const [town, setTown] = useState();
  const [email, setEmail] = useState();
  const [aSMuser, setASMuser] = useState();
  const [year, setYear] = useState(moment().format("YYYY"));

  const { getSalesPerson, statesList, getDistrict, getSoMonthlyReportsList } =
    useSelector((state) => state.commonReducer);
  useEffect(() => {
    dispatch(getOrderListRest());
    {(localStorage.getItem("x-auth-token") && ((localStorage.getItem("userRole") == 1) || (localStorage.getItem("userRole") == 10))) &&
    dispatch(getCreateSalesPerson());}
    dispatch(getStatesList());
    dispatch(
      getSoMonthlyReports({
        year: moment().format("YYYY")
      })
    )
  }, []);
  let selectedOption;
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <Row className="mb-3">
        {(localStorage.getItem("x-auth-token") && ((localStorage.getItem("userRole") == 1) || (localStorage.getItem("userRole") == 10))) &&
          <Col md={2} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setSalesMan(e.target.value);
                selectedOption = document.querySelector(`[name=state] option:nth-of-type(${e.target.selectedIndex})`)
                setNumber(selectedOption.getAttribute("data-number"))
                setUserState(selectedOption.getAttribute("userState"))
                setDistrict(selectedOption.getAttribute("district"))
                setTown(selectedOption.getAttribute("town"))
                setEmail(selectedOption.getAttribute("email"))
                setASMuser(selectedOption.getAttribute("userasm"))
              }}
              name="state"
              className="form-control"
            >
              <option value={"All"}>Select SO</option>
              {!!getSalesPerson &&
                getSalesPerson.map((data, index) => {
                  return (
                    <option key={index} value={data?.userName}
                      data-number={data?.number} 
                      town={data?.address?.town} 
                      district={data?.address?.district}  
                      userState={data?.address?.state}
                      email={data?.email}
                      userasm={data?.asm}
                      >
                      {data?.userName}
                    </option>
                  );
                })}
            </Form.Control>
          </Col>}
          {/* <Col md={2} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setState(e.target.value);
                dispatch(getDistrictList(e.target.value));
              }}
              name="state"
              className="form-control"
            >
              <option value={"All"}>Select State</option>
              {!!statesList &&
                statesList.map((data, index) => {
                  return <option value={data?.state}>{data?.state}</option>;
                })}
            </Form.Control>
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setDistrict(e.target.value);
              }}
              name="state"
              className="form-control"
            >
              <option value="">Select District</option>
              {!!getDistrict &&
                getDistrict.map((data, index) => {
                  return <option value={data}>{data}</option>;
                })}
            </Form.Control>
          </Col> */}
          <Col md={2} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setStartMonth(e.target.value);
                commonFunction(e.target.value);
              }}
              name="state"
              className="form-control"
            >
              <option value="">StartMonth</option>
              <option value="1">Jan</option>
              <option value="2">Feb</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">Aug</option>
              <option value="9">Sep</option>
              <option value="10">Oct</option>
              <option value="11">Nov</option>
              <option value="12">Dec</option>
            </Form.Control>
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setEndMonth(e.target.value);
              }}
              name="state"
              className="form-control"
            >
              <option value="">EndMonth</option>
              <option value="1">Jan</option>
              <option value="2">Feb</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">Aug</option>
              <option value="9">Sep</option>
              <option value="10">Oct</option>
              <option value="11">Nov</option>
              <option value="12">Dec</option>
            </Form.Control>
          </Col>
          <Col md={1} sm={12}>
            <Form.Control
              type="text"
              placeholder="Year"
              name="year"
              value={year}
              onChange={async (e) => {
                setYear(e.target.value);
              }}
            />
          </Col>
          <Col md="1">
            <Button
              variant="success"
              onClick={() => {
                dispatch(
                  getSoMonthlyReports({
                    salesMan: salesMan,
                    startMonth: startMonth,
                    endMonth: endMonth,
                    year: year,
                    state: state,
                    // district: district,
                  })
                )
              }}
            >
              Submit
            </Button>
          </Col>
          <Col md="1">
            <Button
              variant="danger"
              onClick={() => window.location.reload(false)}
            >
              Clear
            </Button>
          </Col>
        </Row>
        {(localStorage.getItem("x-auth-token") && ((localStorage.getItem("userRole") == 1) || (localStorage.getItem("userRole") == 10))) &&
        <Row>
          <Col md={6}>
            <h4>
              <b>Monthly Sale</b>
            </h4>
            <p className="mb-0">
              <b>SO: </b> {salesMan}
            </p>
            <p className="mb-0">
              <b>Headquarter: </b> {!!town && town + ", " + district + ", " + userState}
            </p>
            <p className="mb-0">
              <b>Mobile No.: </b> {number}
            </p>
            <p className="mb-0">
              <b>ASM: </b> {aSMuser}
            </p>
            <p className="mb-0">
              <b>Year: </b> {year}
            </p>
          </Col>
        </Row>}
        <table className="table table-scrolling">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Party Name</th>
              <th scope="col">Address</th>
              {!!getSoMonthlyReportsList?.selectMonth &&
                Object.entries(getSoMonthlyReportsList?.selectMonth).map(
                  ([data, index]) => (
                    <th scope="col">
                      {(data == "jantomaravg" && "Avr.") ||
                        (data == "jantomar" && "Jan to March") ||
                        (data == "aprtojun" && "Apr to Jun") ||
                        (data == "aprtojunavg" && "Avr.") ||
                        (data == "julytosep" && "July to Sep") ||
                        (data == "julytosepavg" && "Avr.") ||
                        (data == "octtodec" && "Oct to Dec") ||
                        (data == "octtodecavg" && "Avr.") ||
                        data}
                    </th>
                  )
                )}
                 <th scope="col">Total CB</th>
            </tr>
          </thead>
          <tbody>
            {!!getSoMonthlyReportsList?.monthOrder &&
              Object.values(getSoMonthlyReportsList?.monthOrder).map(
                (data, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <td>{index + 1}.</td>
                        <td>{data?.name}</td>
                        <td>{`${data?.address?.town}, ${data?.address?.district}, ${data?.address?.state}`}</td>
                        {!!getSoMonthlyReportsList?.selectMonth &&
                          Object.keys(getSoMonthlyReportsList?.selectMonth).map(
                            function (key, value) {
                              const currentDate = new Date();
                              const currentMonth = currentDate.getMonth() + 1;
                              return (
                                (key == "jantomaravg" && (
                                  <td>
                                    <b>{Math.round(data["jantomar"] / (currentMonth === 3 ? 3 : (currentMonth % 3)))}</b>
                                  </td>
                                )) ||
                                (key == "aprtojunavg" && (
                                  <td>
                                    <b>{Math.round(data["aprtojun"] / (currentMonth === 6 ? 3 : (currentMonth % 3)))}</b>
                                  </td>
                                )) ||
                                (key == "julytosepavg" && (
                                  <td>
                                    <b>{Math.round(data["julytosep"] / (currentMonth === 9 ? 3 : (currentMonth % 3)))}</b>
                                  </td>
                                )) ||
                                (key == "octtodecavg" && (
                                  <td>
                                    <b>{Math.round(data["octtodec"] / (currentMonth === 12 ? 3 : (currentMonth % 3)))}</b>
                                  </td>
                                )) || <td>{data[key]}</td>
                              );
                            }
                          )}
                           <td>{data?.cbtotal}</td>
                      </tr>
                    </>
                  );
                }
              )}
            <tr>
              <td colSpan="3" className="text-center">
                <b>Total</b>
              </td>
              {!!getSoMonthlyReportsList?.total &&
                Object.keys(getSoMonthlyReportsList?.selectMonth).map(function (
                  key,
                  value
                ) {
                  const currentDate = new Date();
                              const currentMonth = currentDate.getMonth() + 1;
                  return (
                    (key == "jantomaravg" && (
                      <td>
                        <b>
                          {Math.round(
                            getSoMonthlyReportsList?.total["jantomar"] / (currentMonth === 3 ? 3 : (currentMonth % 3))
                          )}
                        </b>
                      </td>
                    )) ||
                    (key == "aprtojunavg" && (
                      <td>
                        <b>
                          {Math.round(
                            getSoMonthlyReportsList?.total["aprtojun"] / (currentMonth === 6 ? 3 : (currentMonth % 3))
                          )}
                        </b>
                      </td>
                    )) ||
                    (key == "julytosepavg" && (
                      <td>
                        <b>
                          {Math.round(
                            getSoMonthlyReportsList?.total["julytosep"] / (currentMonth === 9 ? 3 : (currentMonth % 3))
                          )}
                        </b>
                      </td>
                    )) ||
                    (key == "octtodecavg" && (
                      <td>
                        <b>
                          {Math.round(
                            getSoMonthlyReportsList?.total["octtodec"] / (currentMonth === 12 ? 3 : (currentMonth % 3))
                          )}
                        </b>
                      </td>
                    )) || (
                      <td>
                        <b>{getSoMonthlyReportsList?.total[key]}</b>
                      </td>
                    )
                  );
                })}
                <td>
                <b>
                  {!!getSoMonthlyReportsList && getSoMonthlyReportsList?.total?.mainTotel}
                </b>
                </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default SalesManMonthWiseReport;
