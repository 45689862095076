import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  userComplaintList,
  getStatesList,
  getDistrictList,
  deleteComplaintRecord,
  getOrderListRest,
  baseUrl,
} from "../reducers/commonReducer";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Button } from "react-bootstrap";
import SideMenu from "../Components/SideMenu";
import EditComplaint from "../Components/EditComplaint";
import moment from "moment";
import ComplaintsView from "../Components/ComplaintsView";
import ExportData from "../Components/ExportData";

const ComplaintList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [states, setStates] = useState();
  const [district, setDistrict] = useState();
  const [userStatus, setUserStatus] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const { userQueryList, statesList, userQueryListBlank, getDistrict } =
    useSelector((state) => state.commonReducer);
  const header = [
    "Sr",
    "Name",
    "Email",
    "ContactNo",
    "State",
    "District",
    "Town",
    "FirmName",
    "UserComment",
    "Comment",
    "Status",
  ];
  let body = [];
  !!userQueryList &&
    userQueryList.map((data, index) => {
      body.push({
        Sr: index + 1,
        Name: data.name,
        Email: data?.email,
        ContactNo: data?.number,
        State: data?.address?.state,
        District: data?.address?.district,
        Town: data?.address?.town,
        FirmName: data?.firmName,
        UserComment: data?.comment,
        Comment: data?.adminComment,
        Status: data?.status,
      });
    });

  useEffect(() => {
    dispatch(getOrderListRest());
    dispatch(userComplaintList());
    dispatch(getStatesList());
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
  }, []);
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <Row className="mb-3">
          <Col md={2} sm={12}>
            <Form.Control
              style={{ textTransform: "capitalize" }}
              as="select"
              value={states}
              onChange={async (e) => {
                setPage(1);
                dispatch(getOrderListRest());
                setStates(e.target.value);
                dispatch(
                  userComplaintList({
                    state: e.target.value,
                    district,
                    userStatus,
                    startDate,
                    endDate,
                  })
                );
                dispatch(getDistrictList(e.target.value));
              }}
              name="state"
              className="form-control"
            >
              <option value="">Select State</option>
              {statesList.map((data, index) => {
                return <option
                      className="first-latter-capital"
                      key={index}
                      value={data.state}
                    >
                      {data.state}
                    </option>
              })}
            </Form.Control>
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              style={{ textTransform: "capitalize" }}
              as="select"
              value={district}
              onChange={async (e) => {
                setPage(1);
                dispatch(getOrderListRest());
                setDistrict(e.target.value);
                dispatch(
                  userComplaintList({
                    state: states,
                    district: e.target.value,
                    userStatus,
                    startDate,
                    endDate,
                  })
                );
              }}
              name="district"
              className="form-control"
            >
              <option value="">Select District</option>
              {getDistrict.map((data, index) => {
                return <option
                      className="first-latter-capital"
                      key={index}
                      value={data}
                    >
                      {data}
                    </option>
              })}
            </Form.Control>
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              style={{ textTransform: "capitalize" }}
              as="select"
              onChange={async (e) => {
                setPage(1);
                dispatch(getOrderListRest());
                setUserStatus(e.target.value);
                dispatch(
                  userComplaintList({
                    state: states,
                    district,
                    userStatus: e.target.value,
                    startDate,
                    endDate,
                  })
                );
              }}
              name="userStatus"
              className="form-control"
            >
              <option value="">Select Status</option>
              <option value="Pending">Pending</option>
              <option value={"Selected"}>Selected</option>
              <option value={"Confirmd"}>Confirmd</option>
              <option value={"Discussion"}>Discussion</option>
              <option value={"Reject"}>Reject</option>
            </Form.Control>
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              type="date"
              placeholder="Start Date"
              name="endDate"
              onChange={async (e) => {
                setPage(1);
                dispatch(getOrderListRest());
                setStartDate(e.target.value);
                dispatch(
                  userComplaintList({
                    state: states,
                    district,
                    userStatus,
                    startDate: e.target.value,
                    endDate,
                  })
                );
              }}
              value={startDate}
            />
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              type="date"
              placeholder="End Date"
              name="startDate"
              onChange={async (e) => {
                setPage(1);
                dispatch(getOrderListRest());
                setEndDate(e.target.value);
                dispatch(
                  userComplaintList({
                    state: states,
                    district,
                    userStatus,
                    startDate,
                    endDate: e.target.value,
                  })
                );
              }}
              value={endDate}
            />
          </Col>
          <Col md={2} sm={12} className="mbt-phone">
            <ExportData title="Export Complaint" header={header} body={body} />
          </Col>
        </Row>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Name</th>
              <th scope="col">Contact No.</th>
              <th scope="col">Date</th>
              <th scope="col">Image</th>
              <th scope="col">UserComment</th>
              <th scope="col">Complaint</th>
              <th scope="col">Comment</th>
              <th scope="col">Status</th>
              {(localStorage.getItem("x-auth-token") &&
                (localStorage.getItem("userRole") === "1" || localStorage.getItem("userRole") === "2")) && <th scope="col">Action</th>}
            </tr>
          </thead>
          <tbody>
            {!!userQueryList &&
              userQueryList.map((data, index) => {
                return <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{data.name}</td>
                      <td>{data.number}</td>
                      <td>{moment(data.createdAt).format("DD/MM/YYYY")}</td>
                      <td>
                        {data?.image && (
                          <Button
                            variant="primary"
                            onClick={() =>
                              openInNewTab(
                                `${baseUrl}/public/uploads/complaint/${data?.image?.url}`
                              )
                            }
                          >
                            <i className="bi bi-eye-fill"></i>
                          </Button>
                        )}
                      </td>
                      <td>{data.comment}</td>
                      <td>
                        <span
                          className={
                            (data.complaintType == "Product Complaint" &&
                              "text-primary") ||
                            (data.complaintType == "Delay Delivery" &&
                              "text-warning") ||
                            (data.complaintType == "Other Complaint" &&
                              "text-danger")
                          }
                        >
                          <b>{data.complaintType}</b>
                        </span>
                      </td>
                      <td>{data.adminComment}</td>
                      <td>
                        <span
                          className={
                            (data.status == "Pending" && "text-primary") ||
                            (data.status == "Resolved" && "text-success") ||
                            (data.status == "Discussion" && "text-warning") ||
                            (data.status == "Reject" && "text-danger")
                          }
                        >
                          <b>{data.status}</b>
                        </span>
                      </td>
                      <td>
                        {localStorage.getItem("x-auth-token") &&
                          localStorage.getItem("userRole") === "1" && (
                            <Button
                              className="m-1"
                              variant="danger"
                              onClick={async () => {
                                await dispatch(
                                  deleteComplaintRecord(data?._id)
                                );
                                dispatch(userComplaintList());
                              }}
                            >
                              <i className="bi bi-trash"></i>
                            </Button>
                          )}
                        <ComplaintsView
                          address={
                            data?.address?.town +
                            "," +
                            data?.address?.district +
                            "," +
                            data?.address?.state
                          }
                          firmName={data.firmName}
                        />
                        {localStorage.getItem("x-auth-token") &&
                          (localStorage.getItem("userRole") === "1" ||
                            localStorage.getItem("userRole") === "2" || localStorage.getItem("userRole") === "9") && (
                            <EditComplaint
                              adminCmnt={data?.adminComment}
                              userStatus={data.status}
                              id={data._id}
                              state={states} 
                              district={district} 
                              endDate={endDate} startDate={startDate}
                              filterStatus={userStatus}
                            />
                          )}
                      </td>
                    </tr>
              })}
          </tbody>
        </table>
        {!!userQueryListBlank && userQueryListBlank.length > 0 ? (
          <Row>
            <Button
              onClick={() => {
                setPage(page + 1);
                dispatch(
                  userComplaintList({
                    state: states,
                    district,
                    userStatus,
                    startDate,
                    endDate,
                    page: page + 1,
                  })
                );
              }}
            >
              Load More
            </Button>
          </Row>
        ) : (
          <p className="text-center">
            <b>No record found 😔.</b>
          </p>
        )}
      </div>
    </>
  );
};

export default ComplaintList;
