import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const Cards = (props) => {
  const { cardTitle, cardText, cardSubTitle, cardLink } =
    props;

  return (
    <div className="position-relative report-card">
      <div className="card card-width text-bg-light">
        <div className="card-body ">
          <h5 className="card-title">{cardTitle}</h5>
          <h6 className="card-subtitle mb-2 text-muted">{cardSubTitle}</h6>
          <Link to={cardLink}><Button className="position-absolute" variant="success">View Report</Button></Link>
        </div>
      </div>
    </div>
  );
};

export default Cards;
