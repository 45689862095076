import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrderList,
  getProducts,
  getOrderListRest,
  baseUrl,
} from "../reducers/commonReducer";
import moment from "moment";
import SideMenu from "../Components/SideMenu";
import { Row, Col, Button, Form } from "react-bootstrap";
import UpdateOrder from "../Components/UpdateOrder";
import ExportData from "../Components/ExportData";
import ViewOrderDetail from "../Components/ViewOrderDetail";

const CompanyWiseOrder = () => {
  let params = useParams();
  const dispatch = useDispatch();
  const [userStatus, setUserStatus] = useState();
  const [page, setPage] = useState(1);
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [startDate, setStartDate] = useState();
  const { getUsersOrderList, getOrderBlank, getProductsList } = useSelector(
    (state) => state.commonReducer
  );
  useEffect(() => {
    dispatch(getOrderListRest());
    dispatch(getProducts());
    dispatch(
      getOrderList({
        companyName: params?.name,
      })
    );
    dispatch(getProducts());
  }, []);

  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <Form action={`${baseUrl}/api/pending/csv`}>
          <Row className="mb-3">
            <input type="text" hidden name="dispatchBy" value={params?.name} />
            <Col md={3} sm={12}>
              <Form.Control
                as="select"
                onChange={async (e) => {
                  setPage(1);
                  setUserStatus(e.target.value);
                  dispatch(getOrderListRest());
                  dispatch(
                    getOrderList({
                      companyName: params?.name,
                      userStatus: e.target.value,
                      startDate,
                      endDate,
                    })
                  );
                }}
                name="status"
                className="form-control"
              >
                <option value="">Select Status</option>
                <option value={"Pending"}>Pending</option>
                <option value="Processing">Processing</option>
              </Form.Control>
            </Col>
            <Col md={3} sm={12}>
              <Form.Control
                type="date"
                placeholder="Start Date"
                name="endDate"
                onChange={async (e) => {
                  setPage(1);
                  setStartDate(e.target.value);
                  dispatch(getOrderListRest());
                  dispatch(
                    getOrderList({
                      companyName: params?.name,
                      userStatus,
                      startDate: e.target.value,
                      endDate,
                    })
                  );
                }}
                value={startDate}
              />
            </Col>
            <Col md={3} sm={12}>
              <Form.Control
                type="date"
                placeholder="End Date"
                name="endDate"
                onChange={async (e) => {
                  setPage(1);
                  setEndDate(e.target.value);
                  dispatch(getOrderListRest());
                  dispatch(
                    getOrderList({
                      companyName: params?.name,
                      userStatus,
                      startDate,
                      endDate: e.target.value,
                    })
                  );
                }}
                value={endDate}
              />
            </Col>
            <Col md="2">
              <Button variant="warning" type="submit">
                Export Report
              </Button>
            </Col>
          </Row>
        </Form>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Order</th>
              <th scope="col">FirmName</th>
              <th scope="col">Town</th>
              <th scope="col">Order Date</th>
              <th scope="col">Expected Date</th>
              <th scope="col">Comment</th>
              <th scope="col">OrderCancel</th>
              <th scope="col">Status</th>
              {localStorage.getItem("x-auth-token") &&
                localStorage.getItem("userRole") === "6" && (
                  <th scope="col">Action</th>
                )}
            </tr>
          </thead>
          <tbody>
            {!!getUsersOrderList &&
              getUsersOrderList.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>
                      <ViewOrderDetail orders={data?.order} />
                    </td>
                    <td>{data?.firmName}</td>
                    <td>{data?.distributorAddress?.town}</td>
                    <td>
                      {moment(data.createdAt).format("DD/MM/YYYY HH:mm:ss")}
                    </td>
                    <td>
                      {data.deliverDate
                        ? moment(data.deliverDate).format("DD/MM/YYYY HH:mm:ss")
                        : ""}
                    </td>
                    <td>{data?.comment}</td>
                    <td className="text-danger">{data?.orderStatus}</td>
                    <td>
                      <span
                        className={
                          (data.status == "Processing" && "text-primary") ||
                          (data.status == "Pending" && "text-warning") ||
                          (data.status == "Deliver" && "text-success")
                        }
                      >
                        <b>{data?.status}</b>
                      </span>
                    </td>
                    {localStorage.getItem("x-auth-token") &&
                      localStorage.getItem("userRole") === "6" &&
                      data?.orderStatus !== "Cancel" && (
                        <td>
                          <UpdateOrder
                            usersPayment={data?.userPayment}
                            id={data._id}
                            quantity={data?.order}
                            companyName={params?.name}
                            status={data?.status}
                          />
                        </td>
                      )}
                  </tr>
                );
              })}
          </tbody>
        </table>
        {!!getOrderBlank && getOrderBlank.length > 0 ? (
          <Row>
            <Button
              onClick={() => {
                setPage(page + 1);
                dispatch(
                  getOrderList({
                    companyName: params?.name,
                    page: page + 1,
                    startDate: startDate,
                    endDate: endDate,
                    userStatus: userStatus,
                  })
                );
              }}
            >
              Load More
            </Button>
          </Row>
        ) : (
          <p className="text-center">
            <b>No record found 😔.</b>
          </p>
        )}
      </div>
    </>
  );
};

export default CompanyWiseOrder;
