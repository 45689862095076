import React, { useEffect, useState } from "react";
import CareerAction from "../Components/CareerAction";
import { useDispatch, useSelector } from "react-redux";
import {
  baseUrl,
  deleteCareerRecord,
  getCareerList,
  getDepartmentDropDown,
  getOrderListRest,
} from "../reducers/commonReducer";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Button } from "react-bootstrap";
import SideMenu from "../Components/SideMenu";
import { Row, Col, Form } from "react-bootstrap";
import ExportData from "../Components/ExportData";

const CareerList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getOrderListRest());
    dispatch(getCareerList());
    dispatch(getDepartmentDropDown())
  }, []);

  const [department, setDepartment] = useState();
  const [page, setPage] = useState(1);
  const [userStatus, setUserStatus] = useState();
  const [endDate, setEndDate] = useState();
  //   moment(new Date()).format("YYYY-MM-DD")
  // );
  const [startDate, setStartDate] = useState();
  const { careerList, departmentDropDownList, careerListBlank } = useSelector(
    (state) => state.commonReducer
  );
  const header = [
    "Sr",
    "Name",
    "Town",
    "Email",
    "ContactNo",
    "Department",
    "Status",
    "Comment",
    "ExpectedSalary",
  ];
  let body = [];
  careerList.map((data, index) => {
    body.push({
      Sr: index + 1,
      Name: data.name,
      Town: data.town,
      Email: data?.email,
      ContactNo: data?.number,
      Department: data?.department,
      Status: data?.status,
      Comment: data?.comment,
      ExpectedSalary: data?.expectedSalary,
    });
  });
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <Row className="mb-3">
          <Col md={2} sm={12}>
            <label>Department</label>
            <Form.Control
              style={{ textTransform: "capitalize" }}
              as="select"
              onChange={async (e) => {
                setPage(1);
                dispatch(getOrderListRest());
                setDepartment(e.target.value);
                dispatch(
                  getCareerList({
                    department: e.target.value,
                    userStatus, endDate, startDate
                  })
                );
              }}
              name="state"
              className="form-control"
            >
              <option value="">Select Department</option>
              {
                !!departmentDropDownList && departmentDropDownList?.map((item, index) => <option key={index} value={item?.name}>{item?.name}</option>)
              }
              {/* <option value="Dispatch">Dispatch</option>
              <option value="Store">Store</option>
              <option value="Production">Production</option>
              <option value="Sales">Sales</option>
              <option value="Quality">Quality</option>
              <option value="Fire & Sales">Fire & Sales</option>
              <option value="HR">HR</option>
              <option value="Accounts">Accounts</option>
              <option value="IT">IT</option>
              <option value="CRM">CRM</option>
              <option value="Digital Marketing">Digital Marketing</option>
              <option value="Legal">Legal</option>
              <option value="others">Others</option> */}
            </Form.Control>
          </Col>
          <Col md={3} sm={12}>
            <label>Status</label>
            <Form.Control
              style={{ textTransform: "capitalize" }}
              as="select"
              onChange={async (e) => {
                setPage(1);
                dispatch(getOrderListRest());
                setUserStatus(e.target.value);
                dispatch(
                  getCareerList({
                    department,
                    userStatus: e.target.value,
                    endDate, startDate
                  })
                );
              }}
              name="userStatus"
              className="form-control"
            >
              <option value="">Select Status</option>
              <option value="Pending">Pending</option>
              <option value={"Selected"}>Selected</option>
              <option value={"Confirmd"}>Confirmd</option>
              <option value={"Read"}>Read</option>
              <option value={"Discussion"}>Discussion</option>
              <option value={"Reject"}>Reject</option>
            </Form.Control>
          </Col>
          <Col md={2} sm={12}>
          <label>Start Date</label>
            <Form.Control
              type="date"
              placeholder="Start Date"
              name="endDate"
              onChange={async (e) => {
                setPage(1);
                dispatch(getOrderListRest());
                setStartDate(e.target.value);
                dispatch(
                  getCareerList({
                    department: department,
                    userStatus: userStatus,
                    startDate: e.target.value,
                    endDate: endDate,
                  })
                );
              }}
              value={startDate}
            />
          </Col>
          <Col md={2} sm={12}>
          <label>End Date</label>
            <Form.Control
              type="date"
              placeholder="End Date"
              name="startDate"
              onChange={async (e) => {
                setPage(1);
                dispatch(getOrderListRest());
                setEndDate(e.target.value);
                dispatch(
                  getCareerList({
                    department: department,
                    userStatus: userStatus,
                    startDate: startDate,
                    endDate: e.target.value,
                  })
                );
              }}
              value={endDate}
            />
          </Col>
          <Col md="1">
            <Button
            className="mt-4"
              variant="danger"
              onClick={() => window.location.reload(false)}
            >
              Clear
            </Button>
          </Col>
          <Col md={2} sm={12} className="mbt-phone mt-4">
            <ExportData title="Export Career" header={header} body={body} />
          </Col>
        </Row>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Name</th>
              <th scope="col">Question & Ans.</th>
              <th scope="col">Contact</th>
              <th scope="col">Department</th>
              <th scope="col">Resume</th>
              <th scope="col">Date</th>
              <th scope="col">Status</th>
              <th scope="col">Comment</th>
              {(localStorage.getItem("x-auth-token") &&
                (localStorage.getItem("userRole") === "1" || localStorage.getItem("userRole") === "4")) && <th scope="col">Action</th>}
            </tr>
          </thead>
          <tbody>
            {!!careerList &&
              careerList.map((data, index) => {
                return (
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{data.name}<br/>
                       <b>Town</b>: {data?.town}<br/>
                      <b>Exp CTC</b>: {data?.expectedSalary}/-</td>
                      <td>{
                        data?.reqQuestions?.map((item, itemindex) => <>
                        <p key={itemindex}>
                          <b>Q. </b>{item?.question}<br/>
                          <b>Ans.</b>{item?.status}
                        </p>
                        </>)
                        }</td>
                      <td>{data.number}<br/>{data.email}</td>
                      <td>{data.department}</td>
                      {/* <td><img src={data?.resume?.url} /></td> */}
                      <td> 
                        <Button
                        className="px-1 py-0"
                          variant="primary"
                          onClick={() =>
                            openInNewTab(
                              `${baseUrl}/public/uploads/resume/${data?.resume?.url}`
                            )
                          }
                        >
                          <i className="bi bi-eye-fill"></i>
                        </Button>
                      </td>
                      <td>{moment(data.createdAt).format("DD/MM/YYYY")}</td>
                      <td>
                        <span
                          className={
                            (data.status == "Pending" && "text-primary") ||
                            (data.status == "Selected" && "text-warning") ||
                            (data.status == "Confirmd" && "text-success") ||
                            (data.status == "Discussion" && "text-secondary") ||
                            (data.status == "Reject" && "text-danger") ||
                            (data.status == "Read" && "text-dark")
                          }
                        >
                          <b>{data.status}</b>
                        </span>
                      </td>
                      <td>{data.comment}</td>
                      <td>
                        {localStorage.getItem("x-auth-token") &&
                          localStorage.getItem("userRole") === "1" && (
                            <Button
                              className="m-1"
                              variant="danger"
                              onClick={async () => {
                                await dispatch(deleteCareerRecord(data?._id));
                                dispatch(getOrderListRest());
                                dispatch(getCareerList());
                              }}
                            >
                              <i className="bi bi-trash"></i>
                            </Button>
                          )}
                        {localStorage.getItem("x-auth-token") &&
                          (localStorage.getItem("userRole") === "1" ||
                            localStorage.getItem("userRole") === "4") && (
                            <CareerAction
                              dataUserStatus={data.status}
                              userComment={data.comment}
                              id={data._id}
                              department= {department} 
                              endDate={endDate} 
                              startDate={startDate}
                              userStatus={userStatus}
                            />
                          )}
                      </td>
                    </tr>
                );
              })}
          </tbody>
        </table>
        {!!careerListBlank && careerListBlank.length > 0 ? (
          <Row>
            <Button
              onClick={() => {
                setPage(page + 1);
                dispatch(
                  getCareerList({
                    department: department,
                    userStatus: userStatus,
                    startDate: startDate,
                    endDate,
                    page: page + 1,
                  })
                );
              }}
            >
              Load More
            </Button>
          </Row>
        ) : (
          <p className="text-center">
            <b>No record found 😔.</b>
          </p>
        )}
      </div>
    </>
  );
};

export default CareerList;
