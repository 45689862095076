import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getASMPersonsWithOutPage,
  getCreateSalesPerson,
  getDispatchByWithOutPage,
  getDistrictList,
  getOrderListRest,
  getProducts,
  getSelectedDistributorWithoutPage,
  getStatesList,
} from "../reducers/commonReducer";
import { Link } from "react-router-dom";
import moment from "moment";
import SideMenu from "../Components/SideMenu";
import { Row, Col, Form, Button } from "react-bootstrap";
import ExportData from "../Components/ExportData";
import UpdateSalesMan from "../Components/UpdateSalesMan";
import SalesPersonCreate from "../Components/SalesPersonCreate";

const SalesManList = () => {
  const dispatch = useDispatch();
  const [distributorName, setDistributorName] = useState();
  const [page, setPage] = useState(1);
  const [state, setState] = useState();
  const [district, setDistrict] = useState();
  const [userStatus, setUserStatus] = useState();
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [startDate, setStartDate] = useState();
  const { getSalesPerson, getDistrict, statesList, getSalesPersonMsg } =
    useSelector((state) => state.commonReducer);
  const header = [
    "Sr",
    "Name",
    "UserName",
    "ContactNo",
    "Address",
    "Date",
    "Status",
  ];
  let body = [];
  !!getSalesPerson &&
    getSalesPerson.map((data, index) => {
      body.push({
        Sr: index + 1,
        Name: data.name,
        UserName: data?.userName,
        ContactNo: data?.number,
        Address:
          data?.address?.town +
          ", " +
          data?.address?.district +
          ", " +
          data?.address?.state,
        Date: moment(data.createdAt).format("DD/MM/YYYY"),
        Status: data?.status,
      });
    });
  useEffect(() => {
    dispatch(getOrderListRest());
    dispatch(getProducts());
    dispatch(getCreateSalesPerson());
    localStorage.getItem("userRole") == 1 &&
      dispatch(getASMPersonsWithOutPage());
    localStorage.getItem("userRole") == 1 &&
      dispatch(getDispatchByWithOutPage());
    dispatch(getStatesList());
    dispatch(getSelectedDistributorWithoutPage());
  }, []);
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        {localStorage.getItem("x-auth-token") &&
          localStorage.getItem("userRole") == 1 && (
            <Row className="mb-2">
              <SalesPersonCreate />
            </Row>
          )}
        <Row className="mb-3">
          <Col md={2} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setPage(1);
                setState(e.target.value);
                dispatch(getOrderListRest());
                dispatch(
                  getCreateSalesPerson({
                    distributorName,
                    state: e.target.value,
                    userStatus,
                    startDate,
                    endDate,
                    district,
                  })
                );
                e.target.value.length > 1 &&
                  dispatch(getDistrictList(e.target.value));
              }}
              name="state"
              className="form-control"
            >
              <option value={""}>Select State</option>
              {!!statesList &&
                statesList.map((data, index) => {
                  return (
                    <option key={index} value={data?.state}>
                      {data?.state}
                    </option>
                  );
                })}
            </Form.Control>
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setPage(1);
                setDistrict(e.target.value);
                dispatch(getOrderListRest());
                dispatch(
                  getCreateSalesPerson({
                    distributorName,
                    state,
                    district: e.target.value,
                    userStatus,
                    startDate,
                    endDate,
                  })
                );
              }}
              name="state"
              className="form-control"
            >
              <option value="">Select District</option>
              {!!getDistrict &&
                getDistrict.map((data, index) => {
                  return (
                    <option key={index} value={data}>
                      {data}
                    </option>
                  );
                })}
            </Form.Control>
          </Col>
          <Col md={1} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setPage(1);
                setUserStatus(e.target.value);
                dispatch(getOrderListRest());
                dispatch(
                  getCreateSalesPerson({
                    distributorName,
                    userStatus: e.target.value,
                    state,
                    startDate,
                    endDate,
                    district,
                  })
                );
              }}
              name="userStatus"
              className="form-control"
            >
              <option value={""}>Status</option>
              <option value={"Active"}>Active</option>
              <option value={"Inactive"}>Inactive</option>
            </Form.Control>
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              type="date"
              placeholder="Start Date"
              name="endDate"
              onChange={async (e) => {
                setPage(1);
                setStartDate(e.target.value);
                dispatch(getOrderListRest());
                dispatch(
                  getCreateSalesPerson({
                    distributorName,
                    userStatus,
                    startDate: e.target.value,
                    endDate,
                    state,
                    district,
                  })
                );
              }}
              value={startDate}
            />
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              type="date"
              placeholder="End Date"
              name="startDate"
              onChange={async (e) => {
                setPage(1);
                setEndDate(e.target.value);
                dispatch(getOrderListRest());
                dispatch(
                  getCreateSalesPerson({
                    distributorName,
                    userStatus,
                    startDate,
                    endDate: e.target.value,
                    state,
                    district,
                  })
                );
              }}
              value={endDate}
            />
          </Col>
          <Col md="1">
            <Button
              variant="danger"
              onClick={() => window.location.reload(false)}
            >
              Clear
            </Button>
          </Col>
          <Col md="2">
            <ExportData
              title="Export SalesMan"
              header={header}
              body={body}
              tableName="ShyamG Salesman"
            />
          </Col>
        </Row>
        <table className="table table-scrolling">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Name</th>
              <th scope="col">UserName</th>
              <th scope="col">View Distributor</th>
              <th scope="col">ASM</th>
              <th scope="col">DispatchBy</th>
              <th scope="col">Order</th>
              <th scope="col">Contact No.</th>
              <th scope="col">Address</th>
              <th scope="col">Date</th>
              <th scope="col">Status</th>
              {localStorage.getItem("x-auth-token") &&
                (localStorage.getItem("userRole") == 1 ||
                  localStorage.getItem("userRole") == 11) && (
                  <th scope="col">Action</th>
                )}
            </tr>
          </thead>
          <tbody>
            {!!getSalesPerson &&
              getSalesPerson.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>{data.name}</td>
                    <td>{data?.userName}</td>
                    <td>
                      <Link to={`/distributor-salesman-wise/${data?.userName}`}>
                        <Button variant="warning">View Distributor</Button>
                      </Link>
                    </td>
                    <td>{data?.asmName}</td>
                    <td>
                      {data?.dispatchBy.map((data, index) => (
                        <span key={index}>{`${data?.dispatch}, `}</span>
                      ))}
                    </td>
                    <td>
                      {data?.other != 0 && (
                        <Link to={`/salesman-wise-orders/${data?.userName}`}>
                          <Button variant="primary" className="m-1">
                            Pending Order({data?.other})
                          </Button>
                        </Link>
                      )}
                      {data?.deliver != 0 && (
                        <Link
                          to={`/salesman-wise-delivered-orders/${data?.userName}`}
                          className="f-right"
                        >
                          <Button variant="success" className="m-1">
                            Delivered Order({data?.deliver})
                          </Button>
                        </Link>
                      )}
                    </td>
                    <td>{data.number}</td>
                    <td>
                      {data?.address?.town +
                        ", " +
                        data?.address?.district +
                        ", " +
                        data?.address?.state}
                    </td>
                    <td>{moment(data.createdAt).format("DD/MM/YYYY")}</td>
                    <td>
                      <span
                        className={
                          (data.status == "Active" && "text-success") ||
                          (data.status == "Inactive" && "text-danger")
                        }
                      >
                        <b>{data.status}</b>
                      </span>
                    </td>
                    {localStorage.getItem("x-auth-token") &&
                      (localStorage.getItem("userRole") == 1 ||
                        localStorage.getItem("userRole") == 11) && (
                        <td>
                          {/* <Button
                          className="m-1"
                          variant="danger"
                          onClick={async () => {
                            await dispatch(deleteSalesmanRecord(data?._id));
                            dispatch(getCreateSalesPerson());
                          }}
                        >
                          <i className="bi bi-trash"></i>
                        </Button> */}
                          <UpdateSalesMan
                            userStatus={data.status}
                            id={data._id}
                            userAsm={data?.asm}
                            dispatchlist={data?.dispatchBy}
                            distributers={data?.distributers}
                            name={data.name}
                            number={data.number}
                            town={data?.address?.town}
                            district={data?.address?.district}
                            state={data?.address?.state}
                            userAssignproducts={data?.allproducts}
                            filterStatus={userStatus} filterstate={state}
                            filterdistrict={district} filterendDate = {endDate}
                            filterstartDate={startDate}
                          />
                        </td>
                      )}
                  </tr>
                );
              })}
          </tbody>
        </table>
        {!!getSalesPersonMsg && getSalesPersonMsg.length > 0 ? (
          <Row>
            <Button
              onClick={() => {
                setPage(page + 1);
                dispatch(
                  getCreateSalesPerson({
                    page: page + 1,
                    district,
                    state,
                    userStatus,
                    startDate,
                    endDate,
                  })
                );
              }}
            >
              Load More
            </Button>
          </Row>
        ) : (
          <p className="text-center">
            <b>No record found 😔.</b>
          </p>
        )}
      </div>
    </>
  );
};

export default SalesManList;
