import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getCreateSalesPerson,
  getDistrictList,
  getStatesList,
} from "../reducers/commonReducer";
import moment from "moment";
import SideMenu from "../Components/SideMenu";
import { Row, Col,Button, Form } from "react-bootstrap";
import ExportData from "../Components/ExportData";
import UpdateSalesMan from "../Components/UpdateSalesMan";

const SalesManDistributorWise = () => {
  let params = useParams();
  const dispatch = useDispatch();
  const [state, setState] = useState();
  const [district, setDistrict] = useState();
  const [userStatus, setUserStatus] = useState();
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [startDate, setStartDate] = useState();
  const {
    careerList,
    getSalesPerson,
    getDistrict,
    selectedDistributor,
    statesList,
  } = useSelector((state) => state.commonReducer);
  const header = [
    "Sr",
    "Name",
    "UserName",
    "DistributorName",
    "ContactNo",
    "Address",
    "Date",
    "Status",
  ];
  let body = [];
  careerList.map((data, index) => {
    body.push({
      Sr: index + 1,
      Name: data.name,
      UserName: data?.userName,
      DistributorName: data?.distributorName,
      ContactNo: data?.number,
      Address:
        data?.address?.town +
        ", " +
        data?.address?.district +
        ", " +
        data?.address?.state,
      Date: moment(data.createdAt).format("DD/MM/YYYY"),
      Status: data?.status,
    });
  });
  useEffect(() => {
    dispatch(
      getCreateSalesPerson({
        distributorName: params?.name,
      })
    );
    dispatch(getStatesList());
  }, []);
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <Row className="mb-3">
          <Col md={2} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setState(e.target.value);
                dispatch(
                  getCreateSalesPerson({
                    state: e.target.value,
                    userStatus: userStatus,
                  })
                );
                dispatch(getDistrictList(e.target.value));
              }}
              name="state"
              className="form-control"
            >
              <option value="">Select State</option>
              {!!statesList &&
                statesList.map((data, index) => {
                  return <option value={data?.state}>{data?.state}</option>;
                })}
            </Form.Control>
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setDistrict(e.target.value);
                dispatch(
                  getCreateSalesPerson({
                    state: state,
                    district: e.target.value,
                    userStatus: userStatus,
                  })
                );
              }}
              name="state"
              className="form-control"
            >
              <option value="">Select District</option>
              {!!getDistrict &&
                getDistrict.map((data, index) => {
                  return <option value={data}>{data}</option>;
                })}
            </Form.Control>
          </Col>
          <Col md={1} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setUserStatus(e.target.value);
                dispatch(
                  getCreateSalesPerson({
                    userStatus: e.target.value,
                  })
                );
              }}
              name="userStatus"
              className="form-control"
            >
              <option value="">Status</option>
              <option value="Active">Active</option>
              <option value={"Inactive"}>Inactive</option>
            </Form.Control>
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              type="date"
              placeholder="Start Date"
              name="endDate"
              onChange={async (e) => {
                setStartDate(e.target.value);
                dispatch(
                  getCreateSalesPerson({
                    userStatus: userStatus,
                    startDate: e.target.value,
                    endDate: endDate,
                  })
                );
              }}
              value={startDate}
            />
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              type="date"
              placeholder="End Date"
              name="startDate"
              onChange={async (e) => {
                setEndDate(e.target.value);
                dispatch(
                  getCreateSalesPerson({
                    userStatus: userStatus,
                    startDate: startDate,
                    endDate: e.target.value,
                  })
                );
              }}
              value={endDate}
            />
          </Col>
          <Col md="1">
            <Button
              variant="danger"
              onClick={() => window.location.reload(false)}
            >
              Clear
            </Button>
          </Col>
          <Col md={2} className="mbt-phone">
            <ExportData title="Export SalesMan" header={header} body={body} />
          </Col>
        </Row>
        <table className="table table-scrolling">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Name</th>
              <th scope="col">UserName</th>
              <th scope="col">Contact No.</th>
              <th scope="col">Address</th>
              <th scope="col">Date</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {!!getSalesPerson &&
              getSalesPerson.map((data, index) => {
                return (
                  <>
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{data.name}</td>
                      <td>{data?.userName}</td>
                      <td>{data.number}</td>
                      <td>
                        {data?.address?.town +
                          ", " +
                          data?.address?.district +
                          ", " +
                          data?.address?.state}
                      </td>
                      <td>{moment(data.createdAt).format("DD/MM/YYYY")}</td>
                      <td>
                        <span
                          className={
                            (data.status == "Active" && "text-success") ||
                            (data.status == "Inactive" && "text-danger")
                          }
                        >
                          <b>{data.status}</b>
                        </span>
                      </td>
                      <td>
                        <UpdateSalesMan
                          userStatus={data.status}
                          id={data._id}
                        />
                      </td>
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default SalesManDistributorWise;
