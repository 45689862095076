import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getCreateSalesPerson,
  getOrderListRest,
  getDistrictList,
  getStatesList,
  getDispatchBy,
  getSelectedDistributor
} from "../reducers/commonReducer";
import moment from "moment";
import SideMenu from "../Components/SideMenu";
import { Row, Col, Form, Button } from "react-bootstrap";
import ExportData from "../Components/ExportData";
import UpdateSalesMan from "../Components/UpdateSalesMan";

const SuperStockistsWiseSalesMan = () => {
  let params = useParams();
  const dispatch = useDispatch();
  const [state, setState] = useState();
  const [district, setDistrict] = useState();
  const [userStatus, setUserStatus] = useState();
  const [page, setPage] = useState(1);
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [startDate, setStartDate] = useState();
  useEffect(() => {
    dispatch(getOrderListRest());
    dispatch(
      getCreateSalesPerson({
        dispatch: params?.name,
      })
    );
    dispatch(getStatesList());
    dispatch(getSelectedDistributor());
    localStorage.getItem("userRole") == 1 && dispatch(getDispatchBy())
  }, []);
  const { getSalesPerson, getDistrict, statesList, getSalesPersonMsg } =
    useSelector((state) => state.commonReducer);
  const header = ["Sr", "Name", "UserName", "ContactNo", "Address", "Date"];
  let body = [];
  !!getSalesPerson &&
    getSalesPerson.map((data, index) => {
      body.push({
        Sr: index + 1,
        Name: data.name,
        UserName: data?.userName,
        ContactNo: data?.number,
        Address:
          data?.address?.town +
          ", " +
          data?.address?.district +
          ", " +
          data?.address?.state,
        Date: moment(data.createdAt).format("DD/MM/YYYY"),
      });
    });

  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <Row className="mb-3">
          <Col md={2} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setPage(1);
                setState(e.target.value);
                dispatch(getOrderListRest());
                dispatch(
                  getCreateSalesPerson({
                    state: e.target.value,
                    userStatus: userStatus,
                  })
                );
                e.target.value.length > 1 && dispatch(getDistrictList(e.target.value));
              }}
              name="state"
              className="form-control"
            >
              <option value="">Select State</option>
              {!!statesList &&
                statesList.map((data, index) => {
                  return <option value={data?.state} key={index}>{data?.state}</option>;
                })}
            </Form.Control>
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setPage(1);
                setDistrict(e.target.value);
                dispatch(getOrderListRest());
                dispatch(
                  getCreateSalesPerson({
                    state: state,
                    district: e.target.value,
                    userStatus: userStatus,
                  })
                );
              }}
              name="state"
              className="form-control"
            >
              <option value="">Select District</option>
              {!!getDistrict &&
                getDistrict.map((data, index) => {
                  return <option value={data} key={index}>{data}</option>;
                })}
            </Form.Control>
          </Col>
          {/* <Col md={2} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setUserStatus(e.target.value);
                dispatch(
                  getCreateSalesPerson({
                    userStatus: e.target.value,
                  })
                );
              }}
              name="userStatus"
              className="form-control"
            >
              <option value="">Select Status</option>
              <option value="Active">Active</option>
              <option value={"Inactive"}>Inactive</option>
            </Form.Control>
          </Col> */}
          <Col md={2} sm={12}>
            <Form.Control
              type="date"
              placeholder="Start Date"
              name="endDate"
              onChange={async (e) => {
                setPage(1);
                setStartDate(e.target.value);
                dispatch(getOrderListRest());
                dispatch(
                  getCreateSalesPerson({
                    userStatus: userStatus,
                    startDate: e.target.value,
                    endDate: endDate,
                  })
                );
              }}
              value={startDate}
            />
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              type="date"
              placeholder="End Date"
              name="startDate"
              onChange={async (e) => {
                setPage(1);
                setEndDate(e.target.value);
                dispatch(getOrderListRest());
                dispatch(
                  getCreateSalesPerson({
                    userStatus: userStatus,
                    startDate: startDate,
                    endDate: e.target.value,
                  })
                );
              }}
              value={endDate}
            />
          </Col>
          <Col md="1">
            <Button
              variant="danger"
              onClick={() => window.location.reload(false)}
            >
              Clear
            </Button>
          </Col>
          <Col md={3}>
            <ExportData
              title="Export SalesMan"
              header={header}
              body={body}
              tableName="Super Stockists Wise Salesman"
            />
          </Col>
        </Row>
        <table className="table table-scrolling">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Name</th>
              <th scope="col">UserName</th>
              <th scope="col">Contact No.</th>
              <th scope="col">Address</th>
              <th scope="col">Date</th>
              <th scope="col">Order</th>
              <th scope="col">Distributor</th>
              <th scope="col">Status</th>
              {localStorage.getItem("x-auth-token") &&
                localStorage.getItem("userRole") == 1 && (
                  <th scope="col">Action</th>
                )}
            </tr>
          </thead>
          <tbody>
            {!!getSalesPerson &&
              getSalesPerson.map((data, index) => {
                return <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{data.name}</td>
                      <td>{data?.userName}</td>
                      <td>{data.number}</td>
                      <td>
                        {data?.address?.town +
                          ", " +
                          data?.address?.district +
                          ", " +
                          data?.address?.state}
                      </td>
                      <td>{moment(data.createdAt).format("DD/MM/YYYY")}</td>
                      <td>
                        {data?.other != 0 && (
                          <Link to={`/salesman-wise-orders/${data?.userName}`}>
                            <Button variant="primary" className="m-1">
                              Pending Order({data?.other})
                            </Button>
                          </Link>
                        )}
                        {data?.deliver != 0 && (
                          <Link
                            to={`/salesman-wise-delivered-orders/${data?.userName}`}
                            className="f-right"
                          >
                            <Button variant="success" className="m-1">
                              Delivered Order({data?.deliver})
                            </Button>
                          </Link>
                        )}
                      </td>
                      <td>
                        <Link
                          to={`/distributor-salesman-wise/${data?.userName}?dispatch=${params?.name}`}
                        >
                          <Button variant="warning">View Distributor</Button> 
                        </Link>
                      </td>
                      <td>
                        <span
                          className={
                            (data.status == "Active" && "text-success") ||
                            (data.status == "Inactive" && "text-danger")
                          }
                        >
                          <b>{data.status}</b>
                        </span>
                      </td>
                      {localStorage.getItem("x-auth-token") &&
                        localStorage.getItem("userRole") == 1 && (
                          <td>
                            {/* <Button
                          className="m-1"
                          variant="danger"
                          onClick={async () => {
                            await dispatch(deleteSalesmanRecord(data?._id));
                            dispatch(
                              getCreateSalesPerson({
                                dispatch: params?.name,
                              })
                            );
                          }}
                        >
                          <i className="bi bi-trash"></i>
                        </Button> */}
                            <UpdateSalesMan
                              userStatus={data.status}
                              id={data._id}
                              userAsm = {data?.asm}
                              dispatchlist={data?.dispatchBy}
                              distributers={data?.distributers}
                              name={data.name}
                              number={data.number}
                              town={data?.address?.town}
                              district={data?.address?.district}
                              state={data?.address?.state}
                            />
                          </td>
                        )}
                    </tr>
              })}
          </tbody>
        </table>
        {!!getSalesPersonMsg && getSalesPersonMsg.length > 0 ? (
          <Row>
            <Button
              onClick={() => {
                setPage(page + 1);
                dispatch(
                  getCreateSalesPerson({
                    dispatch: params?.name,
                    state,
                    page: page + 1,
                    userStatus,
                    district,
                    endDate,
                    startDate,
                  })
                );
              }}
            >
              Load More
            </Button>
          </Row>
        ) : (
          <p className="text-center">
            <b>No record found 😔.</b>
          </p>
        )}
      </div>
    </>
  );
};

export default SuperStockistsWiseSalesMan;
