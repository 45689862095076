import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDepartments,
  addCategory,
  updateCategory,
  addDepartments,
  updateDepartment,
} from "../reducers/commonReducer";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import SideMenu from "../Components/SideMenu";
import DepartmentAction from "./DepartmentAction";

const DepartmentList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getDepartments())
  }, []);
  const { departmentList } = useSelector((state) => state.commonReducer);
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <Row className="mb-3">
        <Col>
        <DepartmentAction title={"Add"} api={addDepartments}/>
        </Col>
        </Row>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Name</th>
              <th scope="col">Questions</th>
              <th scope="col">Status</th>
              {localStorage.getItem("x-auth-token") &&
                (localStorage.getItem("userRole") === "1" || localStorage.getItem("userRole") === "11") && <th scope="col">Action</th>}
            </tr>
          </thead>
          <tbody>
            {!!departmentList &&
              departmentList?.map((data, index) => {
                return <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{data?.name}</td>
                      <td>{data?.reqQuestions?.map((item, itemIndex) => <><b>{itemIndex + 1}. </b><span key={itemIndex}>{item}</span><br/></>)}</td>
                      <td>{data?.status ? <b className="text-success">Active</b> : <b className="text-danger">Inactive</b>}</td>
                      <td>
                        {localStorage.getItem("x-auth-token") &&
                          (localStorage.getItem("userRole") === "1" ||
                            localStorage.getItem("userRole") === "11") && (
                            <DepartmentAction
                              id={data?._id}
                              name={data?.name}
                              reqQuestions={data?.reqQuestions}
                              status={data?.status}
                              title={"Update"}
                              api={updateDepartment}
                              />
                          )}
                      </td>
                    </tr>
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default DepartmentList;
