import React, { useEffect } from "react";
import "../App.css";
import { Button } from "react-bootstrap";

const Thankyou = () => {
  useEffect(() => {
    localStorage.removeItem("distributorAply")
  },[])
  return (
    <>
      <div className="login-page bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="bg-white shadow rounded">
                <div className="row thankyou-title">
                <h1 className="text-success">Thankyou for Applying😊</h1>
                <a href="https://www.shyamg.in/"><Button onClick={() => localStorage.removeItem("distributorAply")}>Go Back to Home</Button></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Thankyou;
