import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  baseUrl,
  getDispatchByWithOutPage,
  getDispatchbyOrderMonthlyReports,
  getOrderListRest,
  getStatesList,
} from "../reducers/commonReducer";
import moment from "moment";
import SideMenu from "../Components/SideMenu";
import { Row, Col, Form, Button } from "react-bootstrap";
import { commonFunction } from "./commonFunction/commonFunction";

const OpningColsingStock = () => {
  const dispatch = useDispatch();
  const [year, setYear] = useState(moment().format("YYYY"));

  useEffect(() => {
    dispatch(getOrderListRest());
    dispatch(getDispatchByWithOutPage());
    dispatch(getStatesList());
    dispatch(
      getDispatchbyOrderMonthlyReports({
        year: moment().format("YYYY"),
      })
    );
  }, []);
  const { dispatchByWithOutPage } = useSelector((state) => state.commonReducer);
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <Form action={`${baseUrl}/api/stock/report`}>
          <Row className="mb-3">
            <Col md={3} sm={12} className="mt-4">
              <Form.Control
                as="select"
                name="superstockistid"
                className="form-control"
              >
                <option value="">Select Super</option>
                {!!dispatchByWithOutPage &&
                  dispatchByWithOutPage?.map((data, index) => {
                    return (
                      <option key={index} value={data?._id}>
                        {data?.dispatch}
                      </option>
                    );
                  })}
              </Form.Control>
            </Col>
            <Col md={3} sm={12} className="mt-4">
              <Form.Control as="select" name="month" className="form-control">
                <option value="">Select Month</option>
                <option value={1}>Jan</option>
                <option value={2}>Feb</option>
                <option value={3}>March</option>
                <option value={4}>April</option>
                <option value={5}>May</option>
                <option value={6}>June</option>
                <option value={7}>July</option>
                <option value={8}>Aug</option>
                <option value={9}>Sep</option>
                <option value={10}>Oct</option>
                <option value={11}>Nov</option>
                <option value={12}>Dec</option>
              </Form.Control>
            </Col>
            <Col md={2} sm={12} className="mt-4">
              <Form.Control
                type="text"
                placeholder="Year"
                name="year"
                value={year}
                onChange={async (e) => {
                  setYear(e.target.value);
                }}
              />
            </Col>
            <Col md="1" className="mt-4">
              <Button
                variant="danger"
                onClick={() => window.location.reload(false)}
              >
                Clear
              </Button>
            </Col>
            <Col md="2" className="mt-4">
              <Button variant="warning" type="submit">
                Export Report
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default OpningColsingStock;
