import React from "react";
import { Col, Row } from "react-bootstrap";
import Cards from "../Components/Cards";
import SideMenu from "../Components/SideMenu";
const Reporting = () => {
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <Row>
          <Col md={3} className="mb-5">
            <Cards
              cardLink={"/report"}
              cardTitle="Month wise Report"
              cardSubTitle="View Company, State and District wise Report"
            />
          </Col>
          <Col md={3} className="mb-5">
            <Cards
              cardLink={"/product-report"}
              cardTitle="Productive wise Report"
              cardSubTitle="View Company, State and District Product Wise Sale"
            />
          </Col>
          <Col md={3} className="mb-5">
            <Cards
              cardLink={"/asm-monthly-reporting"}
              cardTitle="ASM/SO Month Report"
              cardSubTitle="View ASM/SO Month wise Report"
            />
          </Col>
          <Col md={3} className="mb-5">
            <Cards
              cardLink={"/asm-disrtibutor-product-reporting"}
              cardTitle="ASM/So Productive Report"
              cardSubTitle="View ASM/SO Product Wise Sale"
            />
          </Col>
        </Row>
        <Row>
          <Col md={3} className="mb-5">
            <Cards
              cardLink={"/dispatchby-order-wise-monthly-reporting"}
              cardTitle="SuperStockest Order Month Report"
              cardSubTitle="View Dispatchby Month wise Report"
            />
          </Col>
          <Col md={3} className="mb-5">
            <Cards
              cardLink={"/dispatchby-order-wise-product-reporting"}
              cardTitle="SuperStockest Order Productive Report"
              cardSubTitle="View Dispatchby Product Wise Sale"
            />
          </Col>
          <Col md={3} className="mb-5">
            <Cards
              cardLink={"/dispatchby-wise-monthly-reporting"}
              cardTitle="SuperStockest Deliver Month Report"
              cardSubTitle="View Dispatchby Month wise Report"
            />
          </Col>
          <Col md={3} className="mb-5">
            <Cards
              cardLink={"/dispatchby-wise-product-reporting"}
              cardTitle="SuperStockest Deliver Productive Report"
              cardSubTitle="View Dispatchby Product Wise Sale"
            />
          </Col>
        </Row>
        <Row>
          <Col md={3} className="mb-5">
            <Cards
              cardLink={"/orderd-deliverd-monthly"}
              cardTitle="Company, State, District, ASM, SO - Montly(Compare)"
              cardSubTitle="View Company, State, District, ASM, SO wise Report"
            />
          </Col>
          <Col md={3} className="mb-5">
            <Cards
              cardLink={"/orderd-deliverd-product"}
              cardTitle="Company, State, District, ASM, SO - Productive(Compare)"
              cardSubTitle="View Company, State, District, ASM, SO wise Report"
            />
          </Col>
          <Col md={3} className="mb-5">
            <Cards
              cardLink={"/opening-closing-stock"}
              cardTitle="Opening and Closing Stock"
              cardSubTitle="View Super, Opening and Closing Stock"
            />
          </Col>
          <Col md={3} className="mb-5">
            <Cards
              cardLink={"/group-wise"}
              cardTitle="Group Wise"
              cardSubTitle="View with all filters wise Report"
            />
          </Col>
        </Row>
        <Row>
          <Col md={3} className="mb-5">
            <Cards
              cardLink={"/cat-wise"}
              cardTitle="Category Wise"
              cardSubTitle="View with all filters wise Report"
            />
          </Col>
          <Col md={3} className="mb-5">
            <Cards
              cardLink={"/target-vs-achievement"}
              cardTitle="Target vs Achievement"
              cardSubTitle="View with all filters wise Report"
            />
          </Col>
          <Col md={3} className="mb-5">
            <Cards
              cardLink={"/focus-target-vs-achievement"}
              cardTitle="Focus Product"
              cardSubTitle="View with all filters wise Report"
            />
          </Col>
          <Col md={3} className="mb-5">
            <Cards
              cardLink={"/primary-billing-report"}
              cardTitle="Primary Billing Report"
              cardSubTitle="View with all filters wise Report"
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Reporting;
