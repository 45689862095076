import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getGroupsList,
  addGroup,
  updateGroup,
} from "../reducers/commonReducer";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import SideMenu from "../Components/SideMenu";
import moment from "moment";
import GroupsAction from "./GroupsAction";

const GroupsList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getGroupsList())
  }, []);
  const { getGroupsData } =
    useSelector((state) => state.commonReducer);
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <Row className="mb-3">
        <Col>
        <GroupsAction title={"Add"} api={addGroup}/>
        </Col>
        </Row>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Name</th>
              <th scope="col">Price</th>
              <th scope="col">Date</th>
              <th scope="col">Status</th>
              {localStorage.getItem("x-auth-token") &&
                (localStorage.getItem("userRole") === "1" || localStorage.getItem("userRole") === "11") && <th scope="col">Action</th>}
            </tr>
          </thead>
          <tbody>
            {!!getGroupsData &&
              getGroupsData.map((data, index) => {
                return <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{data?.name}</td>
                      <td>{data?.price}</td>
                      <td>{moment(data.createdAt).format("DD/MM/YYYY")}</td>
                      <td>{data?.status ? <b className="text-success">Active</b> : <b className="text-danger">Inactive</b>}</td>
                      <td>
                        {localStorage.getItem("x-auth-token") &&
                          (localStorage.getItem("userRole") === "1" || localStorage.getItem("userRole") === "11") && (
                            <GroupsAction
                              id={data?._id}
                              name={data?.name}
                              price={data?.price}
                              status={data?.status}
                              title={"Update"}
                              api={updateGroup}
                              />
                          )}
                      </td>
                    </tr>
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default GroupsList;
