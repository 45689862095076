import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategoryList,
  addCategory,
  updateCategory,
} from "../reducers/commonReducer";
import { useNavigate } from "react-router-dom";
import { Button, Row, Col } from "react-bootstrap";
import SideMenu from "../Components/SideMenu";
import moment from "moment";
import CategoryAction from "./CategoryAction";

const CategoryList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getCategoryList())
  }, []);
  const { getCategoryData } =
    useSelector((state) => state.commonReducer);
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <Row className="mb-3">
        <Col>
        <CategoryAction title={"Add"} api={addCategory}/>
        </Col>
        </Row>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Name</th>
              <th scope="col">Date</th>
              <th scope="col">Status</th>
              {localStorage.getItem("x-auth-token") &&
                (localStorage.getItem("userRole") === "1" || localStorage.getItem("userRole") === "11") && <th scope="col">Action</th>}
            </tr>
          </thead>
          <tbody>
            {!!getCategoryData &&
              getCategoryData.map((data, index) => {
                return <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{data?.name}</td>
                      <td>{moment(data.createdAt).format("DD/MM/YYYY")}</td>
                      <td>{data?.status ? <b className="text-success">Active</b> : <b className="text-danger">Inactive</b>}</td>
                      <td>
                        {localStorage.getItem("x-auth-token") &&
                          (localStorage.getItem("userRole") === "1" ||
                            localStorage.getItem("userRole") === "11") && (
                            <CategoryAction
                              id={data?._id}
                              name={data?.name}
                              status={data?.status}
                              title={"Update"}
                              api={updateCategory}
                              />
                          )}
                      </td>
                    </tr>
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CategoryList;
