import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as yup from "yup";
import {
  getOrderList,
  getOrderListRest,
  updateOrder,
} from "../reducers/commonReducer";

const schema = yup.object().shape({
  // itemName: yup.string().required(),
  // prise: yup.string().required(),
  // dispatchBy: yup.array()
});

const CancelOrder = (props) => {
  const dispatch = useDispatch();
  const { id, companyName, orders } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <button type="button" className="btn btn-primary" onClick={handleShow}>
        <i className="bi bi-pencil-square"></i>
      </button>
      <Modal show={show} onHide={handleClose}>
        <div className="row px-2">
          <Row className="text-center">
            <h3 className="mb-4 mt-4">Update Order</h3>
          </Row>
          <div className="container-fluid">
            <Formik
              validationSchema={schema}
              onSubmit={async (values, { resetForm }) => {
                await dispatch(updateOrder(values));
                resetForm({ values: "" });
                await dispatch(getOrderListRest());
                dispatch(
                  getOrderList({
                    companyName,
                  })
                );
                setShow(false);
              }}
              initialValues={{
                id: id,
                orderStatus: "",
                order:
                  !!orders &&
                  orders.map(
                    (data, index) => (
                      {
                        itemName: data?.itemName,
                        quantity: data?.quantity,
                        prise: data?.prise,
                        itemId: data?.itemId,
                        dquantity: 0,
                      }
                    )
                  ),
              }}
            >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                values,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Row>
                      <Form.Group className="p-3">
                        <label>Order Cancel</label>
                        <Form.Select
                          value={values.orderStatus}
                          onChange={async (e) => {
                            setFieldValue("orderStatus", e.target.value);
                          }}
                        >
                          <option value="">Select</option>
                          <option value={"Cancel"}>Cancel</option>
                        </Form.Select>
                      </Form.Group>
                    </Row>
                  </Row>
                  <Row>
                    <p className="text-danger">
                      <b>Please Fill Correct Product Quantity.</b>
                    </p>
                  </Row>
                  {
                  !!orders &&
                    orders.map((data, index) => {
                      return <Row className="mt-3" key={index}>
                            <Col md={5} sm={6}>
                              {data?.itemName + " (" + data?.prise + "/-" + ")"}
                            </Col>
                            <Col md={3} sm={6}>
                              <b>{data?.quantity}</b> Box
                            </Col>
                            <Col md={4} sm={6}>
                              <Form.Group>
                                <Form.Control
                                  type="number"
                                  placeholder="UpdateQuantity"
                                  name={`order.${index}.quantity`}
                                  onChange={handleChange}
                                  isInvalid={!!errors.order}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.order}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                    })}
                  <Button type="submit" className="mb-2">
                    Submit
                  </Button>
                  <Button
                    variant="danger"
                    onClick={handleClose}
                    className="mb-2"
                  >
                    Close
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CancelOrder;
