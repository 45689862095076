import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function ComplaintsView(props) {
  const { address, firmName } = props;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="warning" onClick={handleShow} className="m-1">
        <i className="bi bi-eye-fill"></i>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>View Complaints Details</Modal.Title>
        </Modal.Header>
        <Row className="text-center m-2">
          <Col md={6}>
            <b>Address</b>
          </Col>
          <Col md={6}>{address}</Col>
        </Row>
        <Row className="text-center m-2">
          <Col md={6}>
            <b>FirmName</b>
          </Col>
          <Col md={6}>{firmName}</Col>
        </Row>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ComplaintsView;
