import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import { Formik } from "formik";
import * as yup from "yup";
import {getCareerOpening, getDealershipOpening} from "../reducers/commonReducer";
import Modal from 'react-bootstrap/Modal';

const schema = yup.object().shape({
  type: yup.string().required(),
  state: yup.string().required(),
  citys: yup.string().required(),
  desc: yup.string().required(),
  status: yup.string().required(),
});

function DilershipOpeningAction(props) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {id, title, api, type,
    state,
    citys,
    desc,
    status} = props;
    const { statesList } =
    useSelector((state) => state.commonReducer);
  return (
    <>
     <Button variant="primary" onClick={handleShow}>
        {title}
      </Button>
      <Modal size={'lg'} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title} Dealership Opening</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <div className="container-fluid">
                  <Formik
                    validationSchema={schema}
                    onSubmit={async (values, { resetForm }) => {
                     await dispatch(api(values))
                     await dispatch(getDealershipOpening())
                     handleClose();
                      resetForm({ values: "" });
                    }}
                    initialValues={{
                    id,
                    type,
                    state,
                    citys,
                    desc,
                    status
                    }}
                  >
                    {({
                      handleSubmit,
                      setFieldValue,
                      handleChange,
                      values,
                      errors,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Row className="mb-3">
                        <Col md={6} sm={12}>
                        <Form.Label>Select Type</Form.Label>
                          <Form.Control
                            as="select"
                            multiple={false}
                            name="type"
                            onChange={handleChange}
                            className="form-control"
                            value={values.type}
                            isInvalid={!!errors.type}
                          >
                                <option value="">Select Type</option>
                                <option value="Distributor">Dispatch</option>
                                <option value="Super Stockest">Super Stockest</option>
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {errors.type}
                          </Form.Control.Feedback>
                        </Col>
                        <Col md={6} sm={12}>
                        <Form.Label>Select State</Form.Label>
                          <Form.Control
                            as="select"
                            multiple={false}
                            name="state"
                            onChange={handleChange}
                            className="form-control"
                            value={values.state}
                            isInvalid={!!errors.state}
                          >
                                <option value="">Select Type</option>
                                {!!statesList && statesList?.map((data, index) => {
                                  return <option value={data?.state}>{data?.state}</option>
                                })}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {errors.state}
                          </Form.Control.Feedback>
                        </Col>
                        </Row>
                        <Row className="mb-3">
                        <Col md={6} sm={12}>
                            <Form.Group >
                              <Form.Label>City's</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter your City"
                                name="citys"
                                value={values.citys}
                                onChange={handleChange}
                                isInvalid={!!errors.citys}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.citys}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md={6} sm={12}>
                            <Form.Label>Select Status</Form.Label>
                              <Form.Control
                                as="select"
                                name="status"
                                onChange={handleChange}
                                className="form-control"
                                value={values.status}
                                isInvalid={!!errors.status}
                              >
                                    <option value="">Select Type</option>
                                    <option value={true}>Active</option>
                                    <option value={false}>Inactive</option>
                              </Form.Control>
                              <Form.Control.Feedback type="invalid">
                                {errors.status}
                              </Form.Control.Feedback>
                            </Col>
                          </Row>
                        <Row className="mb-3">
                          <Col md={12} sm={12}>
                            <Form.Group >
                              <Form.Label>
                                Write Your Description.
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Write Your Description...."
                                name="desc"
                                value={values.desc}
                                onChange={handleChange}
                                isInvalid={!!errors.desc}
                                as="textarea"
                                rows={3}
                              />

                              <Form.Control.Feedback type="invalid">
                                {errors.desc}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Button type="submit">Submit</Button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Modal.Body>
              </Modal>
    </>
  );
}

export default DilershipOpeningAction;
