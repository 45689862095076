import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  baseUrl,
  getASMPersonsWithOutPage,
  getDispatchByWithOutPage,
  getDistrictList,
  getGroupWiseReports,
  getSalesPersonWithoutPage,
  getStatesList,
} from "../reducers/commonReducer";
import moment from "moment";
import SideMenu from "../Components/SideMenu";
import { Row, Col, Form, Button } from "react-bootstrap";

const GroupWiseReport = () => {
  const dispatch = useDispatch();
  const [startMonth, setStartMonth] = useState();
  const [endMonth, setEndMonth] = useState();
  const [state, setState] = useState();
  const [district, setDistrict] = useState();
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const [salesMan, setSalesMan] = useState();
  const [asm, setAsm] = useState();
  const [dispatchBy, setDispatchBy] = useState();
  const [year, setYear] = useState(moment().format("YYYY"));

  useEffect(() => {
    dispatch(getStatesList());
    dispatch(
      getGroupWiseReports({
        year: moment().format("YYYY"),
      })
    );
    dispatch(getDispatchByWithOutPage())
    dispatch(getASMPersonsWithOutPage())
    dispatch(getSalesPersonWithoutPage())
  }, []);
  const { statesList, getDistrict, getGroupWiseReportsList, dispatchByWithOutPage, asmPersonsWithOutPage, getSalesPersonWithoutPageList } = useSelector(
    (state) => state.commonReducer
  );
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <Form className="print-hidden" action={`${baseUrl}/api/main/report/productscsv`}>
          <Row className="mb-3">
            <Col md={3} sm={12}>
              <Form.Control
                as="select"
                onChange={async (e) => {
                  setState(e.target.value);
                  e.target.value.length > 1 &&
                    dispatch(getDistrictList(e.target.value));
                }}
                name="state"
                className="form-control"
              >
                <option value={""}>Select State</option>
                {!!statesList &&
                  statesList.map((data, index) => {
                    return (
                      <option key={index} value={data?.state}>
                        {data?.state}
                      </option>
                    );
                  })}
              </Form.Control>
            </Col>
            <Col md={3} sm={12}>
              <Form.Control
                as="select"
                onChange={async (e) => {
                  setDistrict(e.target.value);
                }}
                name="district"
                className="form-control"
              >
                <option value="">Select District</option>
                {!!getDistrict &&
                  getDistrict.map((data, index) => {
                    return (
                      <option key={index} value={data}>
                        {data}
                      </option>
                    );
                  })}
              </Form.Control>
            </Col>
            <Col md={3} sm={12}>
              <Form.Control
                as="select"
                onChange={async (e) => {
                  setStartMonth(e.target.value);
                }}
                name="startMonth"
                className="form-control"
              >
                <option value="">StartMonth</option>
                <option value="1">Jan</option>
                <option value="2">Feb</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">Aug</option>
                <option value="9">Sep</option>
                <option value="10">Oct</option>
                <option value="11">Nov</option>
                <option value="12">Dec</option>
              </Form.Control>
            </Col>
            <Col md={3} sm={12}>
              <Form.Control
                as="select"
                onChange={async (e) => {
                  setEndMonth(e.target.value);
                }}
                name="endMonth"
                className="form-control"
              >
                <option value="">EndMonth</option>
                <option value="1">Jan</option>
                <option value="2">Feb</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">Aug</option>
                <option value="9">Sep</option>
                <option value="10">Oct</option>
                <option value="11">Nov</option>
                <option value="12">Dec</option>
              </Form.Control>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col md={2} sm={12}>
              <label>Start Date</label>
              <Form.Control
                type="date"
                placeholder="Start Date"
                name="startDate"
                value={startDate}
                onChange={async (e) => {
                  setStartDate(e.target.value);
                }}
              />
            </Col>
            <Col md={2} sm={12}>
              <label>End Date</label>
              <Form.Control
                type="date"
                placeholder="End Date"
                name="endDate"
                value={endDate}
                onChange={async (e) => {
                  setEndDate(e.target.value);
                }}
              />
            </Col>
            <Col md={2} sm={12}>
              <label>Select ASM</label>
              <Form.Control
                as="select"
                onChange={async (e) => {
                  setAsm(e.target.value);
                }}
                name="asm"
                className="form-control"
              >
                <option value="">Select ASM</option>
                {!!asmPersonsWithOutPage &&
                  asmPersonsWithOutPage.map((data, index) => {
                    return (
                      <option key={index} value={data?.userName}>
                        {data?.name}
                      </option>
                    );
                  })}
              </Form.Control>
            </Col>
            <Col md={2} sm={12}>
              <label>Select SO</label>
              <Form.Control
                as="select"
                onChange={async (e) => {
                  setSalesMan(e.target.value);
                }}
                name="so"
                className="form-control"
              >
                <option value="">Select SO</option>
                {!!getSalesPersonWithoutPageList &&
                  getSalesPersonWithoutPageList.map((data, index) => {
                    return (
                      <option key={index} value={data?.userName}>
                        {data?.name}
                      </option>
                    );
                  })}
              </Form.Control>
            </Col>
            <Col md={2} sm={12}>
              <label>Select DispatchBy</label>
              <Form.Control
                as="select"
                onChange={async (e) => {
                    setDispatchBy(e.target.value);
                }}
                name="dispatch"
                className="form-control"
              >
                <option value="">Select DispatchBy</option>
                {!!dispatchByWithOutPage &&
                  dispatchByWithOutPage.map((data, index) => {
                    return (
                      <option key={index} value={data?._id}>
                        {data?.dispatch}
                      </option>
                    );
                  })}
              </Form.Control>
            </Col>
            <Col md={1} sm={12} className="mt-4">
              <Form.Control
                type="text"
                placeholder="Year"
                name="year"
                value={year}
                onChange={async (e) => {
                  setYear(e.target.value);
                }}
              />
            </Col>
            <Col md="1" className="mt-4">
              <Button
                variant="success"
                onClick={() => {
                  dispatch(
                    getGroupWiseReports({
                      startMonth: startMonth,
                      endMonth: endMonth,
                      year: year,
                      state: state,
                      district: district,
                      startDate,
                      endDate,
                      salesMan,
                      asm,
                      dispatchBy
                    })
                  );
                }}
              >
                Submit
              </Button>
            </Col>
            <Col md={6} className="mt-4">
              <Button
                variant="danger"
                onClick={() => window.location.reload(false)}
              >
                Clear
              </Button>
            </Col>
            <Col md={6} className="text-align-end mt-4">
            <Button onClick={() => window.print()}>
              <i className="bi bi-printer-fill"></i>
            </Button>
          </Col>
          </Row>
        </Form>
        <Row className="mt-3">
          <Col md={6}>
            <h4>
              <b>
                Group Wise Report
              </b>
            </h4>
            <p className="mb-0">
              <b>Year: </b> {year}
            </p>
          </Col>
        </Row>
        <table className="table table-scrolling">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Party Name</th>
              <th scope="col">Address</th>
              <th scope="col">SO</th>
              <th scope="col">ASM</th>
              <th scope="col">DispatchBy</th>
              {!!getGroupWiseReportsList &&
                !!getGroupWiseReportsList?.allGroup &&
                Object.entries(getGroupWiseReportsList?.allGroup).map(
                  ([key, value]) => <th scope="col" key={key}>{value}</th>
                )}
              <th scope="col">Total CB</th>
            </tr>
          </thead>
          <tbody>
            {(!!getGroupWiseReportsList && !!getGroupWiseReportsList?.productWiseOrder) && Object.values(getGroupWiseReportsList?.productWiseOrder)?.map(
                (data, index) => {
                  return (
                      <tr key={index}>
                        <td>{index + 1}.</td>
                        <td>{data?.firmName}</td>
                        <td>{`${data?.address?.town}, ${data?.address?.district}, ${data?.address?.state}`}</td>
                        <td>{data?.salesManName}</td>
                        <td>{data?.asmUser?.name}</td>
                        <td>{data?.dispatchUser?.dispatch}</td>
                        {!!getGroupWiseReportsList?.allGroup &&
                          Object.keys(getGroupWiseReportsList?.allGroup).map(
                            function (key, value) {
                              return <td>{data?.[key]}</td>;
                            }
                          )}
                        <td>{data?.cbtotal}</td>
                      </tr>
                  );
                }
              )}
            <tr>
              <td colSpan="6" className="text-center">
                <b>Total</b>
              </td>
              {!!getGroupWiseReportsList &&
                !!getGroupWiseReportsList?.total &&
                Object.keys(getGroupWiseReportsList?.allGroup).map(function (
                  key,
                  value
                ) {
                  return (
                    <td>
                      <b>{getGroupWiseReportsList?.total[key]}</b>
                    </td>
                  );
                })}
              <td>
                <b>
                  {!!getGroupWiseReportsList &&
                    getGroupWiseReportsList?.total?.cbTotel}
                </b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default GroupWiseReport;
