import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import Row from "react-bootstrap/Row";
import { Formik } from "formik";
import * as yup from "yup";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getBlogs} from "../reducers/commonReducer";
import Modal from 'react-bootstrap/Modal';

const schema = yup.object().shape({
    title: yup.string().required(),
    metatitle: yup.string().required(),
    metadesc: yup.string().required(),
    metakeyword: yup.string().required(),
    image: yup.mixed().when('id', {
      is: id => id === undefined || id < 1, 
      then: yup.mixed().required('Image is required'),
      otherwise: yup.mixed() 
    })
});

function BlogAction(props) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {id,
    popUptitle,
    title,
    imgalt,
    metatitle,
    metadesc,
    metakeyword,
    description,
    image, api} = props;
  return (
    <>
     <Button variant="primary" onClick={handleShow}>
        {popUptitle}
      </Button>
      <Modal size={'lg'} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{popUptitle} Blog</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <div className="container-fluid">
                  <Formik
                    validationSchema={schema}
                    onSubmit={async (values, { resetForm }) => {
                     await dispatch(api(values))
                     await dispatch(getBlogs())
                     handleClose();
                      resetForm({ values: "" });
                    }}
                    initialValues={{
                    id,
                    title,
                    imgalt,
                    metatitle,
                    metadesc,
                    metakeyword,
                    description,
                    image
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      setFieldValue,
                      values,
                      errors,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Row className="mb-3">
                          <Col md={12} sm={12}>
                            <Form.Group >
                              <Form.Label>Title</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Title"
                                name="title"
                                value={values.title}
                                onChange={handleChange}
                                isInvalid={!!errors.title}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.title}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md={6} sm={12}>
                            <Form.Group >
                              <Form.Label>Image Alt</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Image Alt"
                                name="imgalt"
                                value={values.imgalt}
                                onChange={handleChange}
                                isInvalid={!!errors.imgalt}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.imgalt}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md={6} sm={12}>
                            <Form.Group>
                              <Form.Label for="myfile">Image</Form.Label>
                              <Form.Control
                                id="myfile"
                                type="file"
                                name="image"
                                onChange={(event) => {
                                  const imageFile = event.target.files[0];
                                  setFieldValue("image", imageFile);
                                }}
                                isInvalid={!!errors.image}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.image}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md={4} sm={12}>
                            <Form.Group >
                              <Form.Label>Meta Title</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Meta Title"
                                name="metatitle"
                                value={values.metatitle}
                                onChange={handleChange}
                                isInvalid={!!errors.metatitle}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.metatitle}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md={4} sm={12}>
                            <Form.Group >
                              <Form.Label>Meta Desc</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Meta Desc"
                                name="metadesc"
                                value={values.metadesc}
                                onChange={handleChange}
                                isInvalid={!!errors.metadesc}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.metadesc}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md={4} sm={12}>
                            <Form.Group >
                              <Form.Label>Meta keyword</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Meta Keyword"
                                name="metakeyword"
                                value={values.metakeyword}
                                onChange={handleChange}
                                isInvalid={!!errors.metakeyword}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.metakeyword}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md={12}>
                            <CKEditor
                              editor={ClassicEditor}
                              data={values?.description ? values?.description : "Enter Your Blog Description......"}
                              onReady={(editor) => {
                                console.log("Editor is ready to use!", editor);
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setFieldValue("description", data);
                                console.log({ event, editor, data });
                              }}
                              onBlur={(event, editor) => {
                                console.log("Blur.", editor);
                              }}
                              onFocus={(event, editor) => {
                                console.log("Focus.", editor);
                              }}
                            />
                          </Col>
                        </Row>
                        <Button type="submit">Submit</Button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Modal.Body>
              </Modal>
    </>
  );
}

export default BlogAction;
