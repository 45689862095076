import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closingStockEdit,
  getClosingStock,
  getProducts,
  getSelectedDistributorWithoutPage,
} from "../reducers/commonReducer";
import SideMenu from "../Components/SideMenu";
import { Col, Row } from "react-bootstrap";
import ClosingStock from "../Components/ClosingStock";

const SuperStockistsClosingStock = () => { 
  const queryParameters = new URLSearchParams(window.location.search);
  const superid = queryParameters.get("superId");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSelectedDistributorWithoutPage());
    dispatch(getProducts());
    dispatch(
      getClosingStock({
        superstockistid: superid,
      })
    );
  }, []);
  const { getClosingStockList } = useSelector((state) => state.commonReducer);
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Month/Year</th>
              <th scope="col">Product</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {!!getClosingStockList &&
              getClosingStockList.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>
                      {(data?.month === 1 && "Jan") ||
                        (data?.month === 2 && "Feb") ||
                        (data?.month === 3 && "March") ||
                        (data?.month === 4 && "April") ||
                        (data?.month === 5 && "May") ||
                        (data?.month === 6 && "June") ||
                        (data?.month === 7 && "July") ||
                        (data?.month === 8 && "Aug") ||
                        (data?.month === 9 && "Sep") ||
                        (data?.month === 10 && "Oct") ||
                        (data?.month === 11 && "Nov") ||
                        (data?.month === 12 && "Dec")}/{data?.year}
                    </td>
                    <td>
                      <Row key={index}>
                        {data?.product?.map((item, index) => {
                          return (
                            <Col md={3}>
                              {item?.id?.itemName}: <b>{item?.value}</b>
                            </Col>
                          );
                        })}
                      </Row>
                    </td>
                    <td>
                     {index === 0 && <ClosingStock
                        btnTitle={"Update Stock"}
                        title={"Update Closing Stock"}
                        superId={data?._id}
                        eProducts={data?.product}
                        apiUrl={closingStockEdit}
                        eMonth={data?.month}
                        eYear={data?.year}
                        eSuper={data?.superDistributor}
                        edistributer={data?.distributer}
                        superid={superid}
                        id={data?._id}
                      />}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default SuperStockistsClosingStock;
