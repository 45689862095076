import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import Row from "react-bootstrap/Row";
import { Formik } from "formik";
import * as yup from "yup";
import { getContractorList} from "../reducers/commonReducer";
import Modal from 'react-bootstrap/Modal';

const schema = yup.object().shape({
  name: yup.string().required(),
  price: yup.string().required(),
});

function ContractorAction(props) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {id,name,password,number,email,price,address, title, api, btnColor, perKg, perPec, 
    hellperTotal, hellperAmount, operaterTotal, operaterAmount, supervisorTotal, supervisorAmount, ladieshalperAmount,ladieshalperTotal} = props;
  return (
    <>
     <Button variant={btnColor} onClick={handleShow}>
     Contractor {title}
      </Button>
      <Modal size={'lg'} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title} Contractor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <div className="container-fluid">
                  <Formik
                    validationSchema={schema}
                    onSubmit={async (values, { resetForm }) => {
                     await dispatch(api(values))
                     await dispatch(getContractorList())
                     handleClose();
                      resetForm({ values: "" });
                    }}
                    initialValues={{
                    id,
                    name,
                    password, 
                    number,
                    email,
                    price,
                    address,
                    perKg,
                    perPec,
                    operater:{
                      amount:operaterAmount,
                      total:operaterTotal
                    },
                    hellper:{
                      amount:hellperAmount,
                      total:hellperTotal
                    },
                    supervisor:{
                      amount:supervisorAmount,
                      total:supervisorTotal
                    },
                    ladieshalper:{
                      amount:ladieshalperAmount,
                      total:ladieshalperTotal
                    }
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      values,
                      errors,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Row className="mb-3">
                          <Col md={4} sm={12}>
                            <Form.Group >
                              <Form.Label>Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Name"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                isInvalid={!!errors.name}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.name}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md={4} sm={12}>
                            <Form.Group >
                              <Form.Label>Price</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Enter Price"
                                name="price"
                                value={values.price}
                                onChange={handleChange}
                                isInvalid={!!errors.price}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.price}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md={4} sm={12}>
                            <Form.Group >
                              <Form.Label>Password</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter password"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                isInvalid={!!errors.password}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.password}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md={6} sm={12}>
                            <Form.Group >
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                type="email"
                                placeholder="Email"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                isInvalid={!!errors.email}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.email}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md={6} sm={12}>
                            <Form.Group >
                              <Form.Label>Number</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Enter Phone"
                                name="number"
                                value={values.number}
                                onChange={handleChange}
                                isInvalid={!!errors.number}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.number}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md={12} sm={12}>
                            <Form.Group >
                              <Form.Label>
                              Address
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Address"
                                name="address"
                                value={values.address}
                                onChange={handleChange}
                                isInvalid={!!errors.address}
                                as="textarea"
                                rows={3}
                              />

                              <Form.Control.Feedback type="invalid">
                                {errors.address}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md={6} sm={12}>
                            <Form.Group>
                              <Form.Label>Operator's Total</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Operator's Total"
                                name="operater.total"
                                value={values.operater ? values.operater.total : ''}
                                onChange={handleChange}
                                isInvalid={!!errors.operater?.total}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.operater?.total}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md={6} sm={12}>
                            <Form.Group >
                              <Form.Label>Operater Monthly Amount</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Operater's Amount"
                                name="operater.amount"
                                value={values.operater ? values.operater.amount : ''}
                                onChange={handleChange}
                                isInvalid={!!errors.operater?.amount}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.operater?.amount}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md={6} sm={12}>
                            <Form.Group>
                              <Form.Label>Halper Total</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Halper's Total"
                                name="hellper.total"
                                value={values.hellper ? values.hellper.total : ''}
                                onChange={handleChange}
                                isInvalid={!!errors.hellper?.total}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.hellper?.total}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md={6} sm={12}>
                            <Form.Group >
                              <Form.Label>Halper Monthly Amount</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Halper's Amount"
                                name="hellper.amount"
                                value={values.hellper ? values.hellper.amount : ''}
                                onChange={handleChange}
                                isInvalid={!!errors.hellper?.amount}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.hellper?.amount}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md={6} sm={12}>
                            <Form.Group>
                              <Form.Label>Supervisor Total</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Supervisor's Total"
                                name="supervisor.total"
                                value={values.supervisor ? values.supervisor.total : ''}
                                onChange={handleChange}
                                isInvalid={!!errors.supervisor?.total}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.supervisor?.total}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md={6} sm={12}>
                            <Form.Group >
                              <Form.Label>Supervisor Monthly Amount</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Supervisor's Amount"
                                name="supervisor.amount"
                                value={values.supervisor ? values.supervisor.amount : ''}
                                onChange={handleChange}
                                isInvalid={!!errors.supervisor?.amount}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.supervisor?.amount}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md={6} sm={12}>
                            <Form.Group>
                              <Form.Label>Ladies Halper Total</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Ladies Halper's Total"
                                name="ladieshalper.total"
                                value={values.ladieshalper ? values.ladieshalper.total : ''}
                                onChange={handleChange}
                                isInvalid={!!errors.ladieshalper?.total}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.ladieshalper?.total}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md={6} sm={12}>
                            <Form.Group >
                              <Form.Label>Ladies Halper Monthly Amount</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Ladies Halper's Amount"
                                name="ladieshalper.amount"
                                value={values.ladieshalper ? values.ladieshalper.amount : ''}
                                onChange={handleChange}
                                isInvalid={!!errors.ladieshalper?.amount}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.ladieshalper?.amount}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md={6} sm={12}>
                            <Form.Group >
                              <Form.Label>Per Kg Price</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Per Kg Price"
                                name="perKg"
                                value={values.perKg}
                                onChange={handleChange}
                                isInvalid={!!errors.perKg}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.perKg}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md={6} sm={12}>
                            <Form.Group >
                              <Form.Label>Per Pec Price</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Per Pec Price"
                                name="perPec"
                                value={values.perPec}
                                onChange={handleChange}
                                isInvalid={!!errors.perPec}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.perPec}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Button type="submit">Submit</Button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Modal.Body>
              </Modal>
    </>
  );
}

export default ContractorAction;
