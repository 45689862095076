import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as yup from "yup";
import { getProducts } from "../reducers/commonReducer";

const schema = yup.object().shape({
  itemName: yup.string().required(),
  prise: yup.string().required(),
  group: yup.string().required(),
  category: yup.string().required(),
});

const ProductAction = (props) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const{api, title, category, group, itemName, prise, id} = props;
  const { getGroupsData, getCategoryData} = useSelector(
    (state) => state.commonReducer
  );
  return (
    <>
      <Button variant="success" onClick={handleShow}>
        {title}
      </Button>
      <Modal size={"lg"} show={show} onHide={handleClose}>
        <div className="row px-3 py-2">
          <Row className="text-center">
            <h3 className="mb-4 mt-4">{title} Product</h3>
          </Row>
          <div className="container-fluid">
            <Formik
              validationSchema={schema}
              onSubmit={async (values, { resetForm }) => {
                await dispatch(api(values));
                resetForm({ values: "" });
                dispatch(getProducts());
                setShow(false);
              }}
              initialValues={{
                id,
                itemName,
                prise,
                image:'',
                category,
                group
              }}
            >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                values,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Row>
                  <p className="text-danger text-center">
                        <b>
                          Note:- You are create Waahji product then before
                          product name add Waahji like (Waahji Chips).
                        </b>
                      </p>
                  </Row>
                  {
                    title !== "Edit" && <Row className="mb-3">
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label>Product Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Product Name"
                          name="itemName"
                          value={values.itemName}
                          onChange={handleChange}
                          isInvalid={!!errors.itemName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.itemName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label>Product Price</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Product Price"
                          name="prise"
                          value={values.prise}
                          onChange={handleChange}
                          isInvalid={!!errors.prise}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.prise}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  }
                  <Row className="mb-3">
                  <Col md={6} sm={12}>
                        <Form.Group>
                          <Form.Label for="myfile">Image</Form.Label>
                          <Form.Control
                            id="myfile"
                            type="file"
                            name="image"
                            onChange={(event) => {
                              const imageFile = event.target.files[0];
                              setFieldValue("image", imageFile);
                            }}
                            isInvalid={!!errors.image}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.image}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6} sm={12}>
                      <Form.Label>Category</Form.Label>
                      <br />
                      <Form.Control
                        as="select"
                        onChange={handleChange}
                        name="category"
                        className="form-control"
                        value={values.category}
                        isInvalid={!!errors.category}
                      >
                        <option value="">Select Category</option>
                        {getCategoryData.map((data, index) => {
                          return (
                            <option
                              key={index}
                              value={data?._id}
                            >
                              {data.name}
                            </option>
                          );
                        })}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.category}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                  <Col md={6} sm={12}>
                      <Form.Label>Group</Form.Label>
                      <br />
                      <Form.Control
                        as="select"
                        onChange={handleChange}
                        name="group"
                        className="form-control"
                        value={values.group}
                        isInvalid={!!errors.group}
                      >
                        <option value="">Select Group</option>
                        {getGroupsData.map((data, index) => {
                          return (
                            <option
                              key={index}
                              value={data?._id}
                            >
                              {data.name}
                            </option>
                          );
                        })}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.group}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Button type="submit" className="mt-3">
                    Submit
                  </Button>
                  <Button
                    variant="danger"
                    onClick={handleClose}
                    className="mt-3"
                  >
                    Close
                  </Button>
                </Form>
              )}
            </Formik>
            {/* <p>
              <b>{distributorAddMsg}</b>
            </p> */}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProductAction;
