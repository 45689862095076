import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBlogs,
  addBlog,
  updateBlog,
  deleteBlog,
} from "../reducers/commonReducer";
import { useNavigate } from "react-router-dom";
import { Button, Row, Col } from "react-bootstrap";
import SideMenu from "../Components/SideMenu";
import moment from "moment";
import BlogAction from "./BlogAction";

const BlogList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getBlogs())
  }, []);
  const { getBlogList } =
    useSelector((state) => state.commonReducer);
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <h3 className="text-danger text-center">Blogs</h3>
        <Row className="mb-1">
        <Col>
        <BlogAction popUptitle={"Add"} api={addBlog}/>
        </Col>
        </Row>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Title</th>
              <th scope="col">Date</th>
             <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {!!getBlogList &&
              getBlogList.map((data, index) => {
                return <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{data?.title}</td>
                      <td>{moment(data.updatedAt).format("DD/MM/YYYY")}</td>
                      <td>
                            <BlogAction 
                              popUptitle={"Edit"} 
                              api = {updateBlog} 
                              id = {data?._id} 
                              title = {data?.title}
                              imgalt = {data?.imgalt}
                              metatitle = {data?.metatitle}
                              metadesc = {data?.metadesc}
                              metakeyword = {data?.metakeyword}
                              description = {data?.description}
                              image = {data?.image}
                            />
                            <Button
                              className="m-1"
                              variant="danger"
                              onClick={async () => {
                                const alertStatus = window.confirm('Delete the item?')
                                if(alertStatus){
                                  await(dispatch(deleteBlog(data?._id)));
                                  dispatch(getBlogs());
                                }
                              }}
                            >
                              <i className="bi bi-trash"></i>
                            </Button>
                      </td>
                    </tr>
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BlogList;
