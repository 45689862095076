import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as yup from "yup";
import {
  getOrderList,
  getOrderListRest,
  updateOrder,
} from "../reducers/commonReducer";

const schema = yup.object().shape({
  // itemName: yup.string().required(),
  // prise: yup.string().required(),
  // dispatchBy: yup.array()
});

const UpdateOrder = (props) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const distrName = queryParameters.get("distributor");
  const soNamePrm = queryParameters.get("so");
  const asm = queryParameters.get("asm");
  const dispatch = useDispatch();
  const { distributorAddMsg, createOrderMsg, getProductsList } = useSelector(
    (state) => state.commonReducer
  );
  const {
    id,
    quantity,
    companyName,
    orderdeliverDate,
    userPaymentStatus,
    orderStatus,
    invoiceNumber
  } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button type="button" className="btn btn-primary" onClick={handleShow}>
        <i className="bi bi-pencil-square"></i>
      </button>
      <Modal show={show} onHide={handleClose}>
        <div className="row px-2">
          <Row className="text-center">
            <h3 className="mb-4 mt-4">Update Order</h3>
          </Row>
          <div className="container-fluid">
            <Formik
              validationSchema={schema}
              onSubmit={async (values, { resetForm }) => {
                await dispatch(updateOrder(values));
                resetForm({ values: "" });
                await dispatch(getOrderListRest());
                dispatch(
                  getOrderList({
                    distributor: distrName,
                    salesMan: soNamePrm,
                    asm: asm,
                    userStatus: "Deliver",
                    companyName,
                  })
                );
                setShow(false);
              }}
              initialValues={{
                id: id,
                status: orderStatus,
                comment: props?.comment,
                order:
                  !!quantity &&
                  quantity.map((data, index) => ({
                    itemName: data?.itemName,
                    quantity: data?.quantity,
                    prise: data?.prise,
                    itemId: data?.itemId,
                    dquantity: data?.dquantity,
                  })),
                userPayment: userPaymentStatus,
                deliverDate: orderdeliverDate,
                orderStatus:"",
                invoiceNumber
              }}
            >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                values,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Row>
                      <Col md={6}>
                      <Form.Group>
                        <label>Order Cancel</label>
                        <Form.Select
                          value={values.orderStatus}
                          onChange={async (e) => {
                            setFieldValue("orderStatus", e.target.value);
                          }}
                        >
                          <option value="">Cancel Order</option>
                          <option value={"Cancel"}>Cancel</option>
                        </Form.Select>
                      </Form.Group>
                      </Col>
                      <Col md={6}>
                          <Form.Group>
                          <label>Invoice Number</label>
                              <Form.Control
                                      type="text"
                                      placeholder="Enter invoiceNumber"
                                      name={`invoiceNumber`}
                                      onChange={handleChange}
                                      value={values.invoiceNumber}
                                      isInvalid={!!errors.invoiceNumber}
                                    />
                                  <Form.Control.Feedback type="invalid">
                                      {errors.invoiceNumber}
                                  </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                      {/* <Row>
                      <Form.Group>
                        <label>Order Status</label>
                        <Form.Select
                          value={values.status}
                          onChange={async (e) => {
                            setFieldValue("status", e.target.value);
                          }}
                        >
                          <option value="">Update Order Status</option>
                          <option value={"Pending"}>Pending</option>
                          <option value={"Processing"}>Processing</option>
                          <option value={"Deliver"}>Deliver</option>
                        </Form.Select>
                      </Form.Group>
                    </Row> */}
                    {values.status === "Deliver" && (
                      <>
                        <Row>
                          <p className="text-danger">
                            <b>Please Fill all Product Quantity.</b>
                          </p>
                        </Row>
                        {!!quantity &&
                          quantity.map((data, index) => {
                            return (
                              <Row className="mt-3" key={index}>
                                <Col md={5} sm={6}>
                                  {data?.itemName +
                                    " (" +
                                    data?.prise +
                                    "/-" +
                                    ")"}
                                </Col>
                                <Col md={3} sm={6}>
                                  <b>{data?.quantity}</b> Box
                                </Col>
                                <Col md={4} sm={6}>
                                  <Form.Group>
                                    <Form.Control
                                      type="number"
                                      placeholder="Product Delivered Quantity"
                                      name={`order.${index}.dquantity`}
                                      onChange={handleChange}
                                      value={values.order[index].dquantity}
                                      isInvalid={!!errors.order}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      {errors.order}
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </Col>
                              </Row>
                            );
                          })}
                      </>
                    )}
                    <Row className="mt-2">
                      <Form.Group>
                        <Form.Label>Deliver Date</Form.Label>
                        <Form.Control
                          type="date"
                          name="deliverDate"
                          value={values.deliverDate}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mt-2">
                      <Form.Group>
                        <label>Payment Status</label>
                        <Form.Select
                          value={values.userPayment}
                          onChange={async (e) => {
                            setFieldValue("userPayment", e.target.value);
                          }}
                        >
                          <option value="">Payment Status</option>
                          <option value={"Pending"}>Pending</option>
                          <option value={"Confirmd"}>Confirmd</option>
                        </Form.Select>
                      </Form.Group>
                    </Row>
                    <Row className="mt-2">
                      <Form.Group>
                        <Form.Label>Enter Your Comment</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Comment"
                          name="comment"
                          value={values.comment}
                          onChange={handleChange}
                          as="textarea"
                          rows={3}
                        />
                      </Form.Group>
                    </Row>
                      {/* </>
                    // } */}
                    
                  </Row>
                  <Button type="submit" className="mb-2">
                    Submit
                  </Button>
                  <Button
                    variant="danger"
                    onClick={handleClose}
                    className="mb-2"
                  >
                    Close
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UpdateOrder;
