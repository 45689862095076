import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrderList,
  getOrderListRest,
  getProducts,
} from "../reducers/commonReducer";
import moment from "moment";
import SideMenu from "../Components/SideMenu";
import { Row, Col, Form, Button } from "react-bootstrap";
import ExportData from "../Components/ExportData";
import ViewOrderDetail from "../Components/ViewOrderDetail";
import UpdateOrder from "../Components/UpdateOrder";

const UserWiseDeliverdOrders = () => {
  let params = useParams();
  const queryParameters = new URLSearchParams(window.location.search);
  const distrName = queryParameters.get("distributor");
  const soNamePrm = queryParameters.get("so");
  const roll = queryParameters.get("roll");
  const asm = queryParameters.get("asm");
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [startDate, setStartDate] = useState();

  useEffect(() => {
    dispatch(getOrderListRest());
    dispatch(
      getOrderList({
        distributor: distrName,
        salesMan: soNamePrm,
        asm: asm,
        userStatus: "Deliver",
      })
    );
    dispatch(getProducts());
  }, []);

  const { getUsersOrderList, getOrderBlank } = useSelector(
    (state) => state.commonReducer
  );
  const header = [
    "Sr",
    "Order Date",
    "Order Delivered Date",
    "SalesManName",
    "FirmName",
    "Status",
    "ProductName",
    "Quantity",
    "DeliveredQuantity",
    "Price",
  ];
  let body = [];
  !!getUsersOrderList != undefined &&
    getUsersOrderList.map((data, index) => {
      body.push({
        Sr: index + 1,
        Date: moment(data.createdAt).format("DD/MM/YYYY HH:mm:ss"),
        OrderDeliveredDate: moment(data.updatedAt).format(
          "DD/MM/YYYY HH:mm:ss"
        ),
        SalesManName: data?.salesMan,
        FirmName: data?.firmName,
        Status: data?.status,
        orders: data?.order.map((orderD) => {
          body.push({
            Sr: "",
            Date: "",
            OrderDeliveredDate: "",
            SalesManName: "",
            DistributorName: "",
            Status: "",
            ProductName: orderD?.itemName,
            Quantity: orderD?.quantity,
            DeliveredQuantity: orderD?.dquantity,
            Price: orderD?.prise,
          });
        }),
      });
    });

  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <Row className="mb-3">
          <Col md={3} sm={12}>
            <Form.Control
              type="date"
              placeholder="Start Date"
              name="endDate"
              onChange={async (e) => {
                setPage(1);
                setStartDate(e.target.value);
                dispatch(getOrderListRest());
                dispatch(
                  getOrderList({
                    userStatus: "Deliver",
                    startDate: e.target.value,
                    endDate: endDate,
                    distributor: params?.name,
                  })
                );
              }}
              value={startDate}
            />
          </Col>
          <Col md={3} sm={12}>
            <Form.Control
              type="date"
              placeholder="End Date"
              name="startDate"
              onChange={async (e) => {
                setPage(1);
                setEndDate(e.target.value);
                dispatch(getOrderListRest());
                dispatch(
                  getOrderList({
                    userStatus: "Deliver",
                    distributor: params?.name,
                    startDate: startDate,
                    endDate: e.target.value,
                  })
                );
              }}
              value={endDate}
            />
          </Col>
          <Col>
            <ExportData
              title="Export Order"
              header={header}
              body={body}
              tableName="Distributor Delivered Order"
            />
          </Col>
        </Row>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Order</th>
              <th scope="col">InvoiceNumber</th>
              <th scope="col">Order Date</th>
              <th scope="col">Delivered Date</th>
              <th scope="col">SalesMan</th>
              <th scope="col">DispatchBy</th>
              <th scope="col">FirmName</th>
              <th scope="col">Status</th>
              {/* {localStorage.getItem("x-auth-token") &&
                  localStorage.getItem("userRole") == 6 && (
              <th scope="col">Action</th>)} */}
            </tr>
          </thead>
          <tbody>
            {!!getUsersOrderList &&
              getUsersOrderList.map((data, index) => {
                return (
                  <Fragment key={index}>
                    <tr>
                      <td>{index + 1}.</td>
                      <td>
                        <ViewOrderDetail orders={data?.order} />
                      </td>
                      <td>
                        {data?.invoiceNumber}
                      </td>
                      <td>
                        {moment(data.createdAt).format("DD/MM/YYYY HH:mm:ss")}
                      </td>
                      <td>
                        {data?.deliverDate
                          ? moment(data.deliverDate).format("DD/MM/YYYY")
                          : moment(data.updatedAt).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )}
                      </td>
                      <td>{data?.salesMan}</td>
                      <td>{data?.dispatchBy?.userName}</td>
                      <td>{data?.firmName}</td>
                      <td>
                        <span
                          className={
                            (data.status == "Processing" && "text-primary") ||
                            (data.status == "Pending" && "text-warning") ||
                            (data.status == "Deliver" && "text-success")
                          }
                        >
                          <b>{data?.status}</b>
                        </span>
                      </td>
                      {/* {localStorage.getItem("x-auth-token") &&
                  localStorage.getItem("userRole") == 6 && (
                      <td>
                        <UpdateOrder id={orders._id}/>
                      </td>)} */}
                      {((localStorage.getItem("userRole") == 1) || localStorage.getItem("userName") === "shyamg@4321") && (
                        <td>
                          <UpdateOrder
                            id={data._id}
                            comment={data.comment}
                            quantity={data?.order}
                            orderdeliverDate={data?.deliverDate}
                            orderStatus={data?.status}
                            userPaymentStatus={data?.userPayment}
                            invoiceNumber={data?.invoiceNumber}
                          />
                        </td>
                      )}
                    </tr>
                  </Fragment>
                );
              })}
          </tbody>
        </table>
        {!!getOrderBlank && getOrderBlank.length > 0 ? (
          <Row>
            <Button
              onClick={async () => {
                await setPage(page + 1);
                dispatch(
                  getOrderList({
                    distributor: distrName,
                    salesMan: soNamePrm,
                    asm: asm,
                    page: page + 1,
                    userStatus: "Deliver",
                  })
                );
              }}
            >
              Load More
            </Button>
          </Row>
        ) : (
          <p className="text-center">
            <b>No record found 😔.</b>
          </p>
        )}
      </div>
    </>
  );
};

export default UserWiseDeliverdOrders;
