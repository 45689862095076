import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getContractorAttendance,
  contractorAttendanceUpdate,
  userWiseAttendance,
  contractorAttendanceAdd,
  getMyProfile,
} from "../reducers/commonReducer";
import { useNavigate } from "react-router-dom";
import { Row, Form, Col, Button } from "react-bootstrap";
import SideMenu from "../Components/SideMenu";
import moment from "moment";
import AttendanceAction from "./AttendanceAction";

const ContractorsAttendance = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const userid = queryParameters.get("id");
  const contractorAmount = queryParameters.get("amount");
  const contractorName = queryParameters.get("name");
  const [month, setMonth] = useState();
  const [year, setYear] = useState(moment().format("YYYY"));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    (localStorage.getItem("userRole") === "1") ? dispatch(getContractorAttendance({
        id:userid
    })) :
    dispatch(userWiseAttendance({
      id:userid
    }))
    dispatch(getMyProfile());
  }, []);
  const { getContractorAttendanceData, getMyProfileData, userWiseAttendanceList } =
    useSelector((state) => state.commonReducer);
      //calculation
      const calculationColoums = (localStorage.getItem("userRole") === "1" ? getContractorAttendanceData : userWiseAttendanceList).reduce((total, item) => {
        if (item?.status === 1) {
          const operatorAmount = item?.operater?.total * item?.operater?.amount;
          const supervisorAmount = item?.supervisor?.total * item?.supervisor?.amount;
          const helperAmount = item?.hellper?.total * item?.hellper?.amount;
          const ladieshalper = item?.ladieshalper?.total * item?.ladieshalper?.amount;

          const userAmount = operatorAmount + supervisorAmount + helperAmount + ladieshalper;
          const totalPec = item?.totalPacket?.total;
          const pecAmount	 = item?.totalPacket?.total * item?.totalPacket?.amount;
          const totalKg = item?.totalUnit?.total;
          const kgAmount = item?.totalUnit?.total * item?.totalUnit?.amount;
          return {
            userAmount: total.userAmount + userAmount,
            totalPec: total.totalPec + totalPec,
            pecAmount: total.pecAmount + pecAmount,
            totalKg: total.totalKg + totalKg,
            kgAmount: total.kgAmount + kgAmount,
          };
        }
        return total;
      }, {
        userAmount:0,
        totalPec: 0,
        pecAmount: 0,
        totalKg: 0,
        kgAmount:0
      });
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <Row className="print-hidden">
        <Col md={3} sm={12} className="mb-3">
              <Form.Control
                as="select"
                onChange={async (e) => {
                  setMonth(e.target.value);
                }}
                name="month"
                className="form-control"
              >
                <option value="">Select Month</option>
                <option value="1">Jan</option>
                <option value="2">Feb</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">Aug</option>
                <option value="9">Sep</option>
                <option value="10">Oct</option>
                <option value="11">Nov</option>
                <option value="12">Dec</option>
              </Form.Control>
            </Col>
            <Col md={1} sm={12}>
              <Form.Control
                type="text"
                placeholder="Year"
                name="year"
                value={year}
                onChange={async (e) => {
                  setYear(e.target.value);
                }}
              />
            </Col>
            <Col md={1}>
              <Button
                variant="success"
                onClick={() => {
                  (localStorage.getItem("userRole") === "1") ? dispatch(getContractorAttendance({
                    id:userid,
                    month,year
                })) :
                dispatch(userWiseAttendance({
                  month,
                  year,
                  id:userid
                }))
                }}
              >
                Submit
              </Button>
            </Col>
            <Col md={1}>
              <Button
                variant="danger"
                onClick={() => window.location.reload(false)}
              >
                Clear
              </Button>
            </Col>
        </Row>
        <Row className="mb-3 print-hidden">
        <Col>
        {/* {
        (getContractorAttendanceData || userWiseAttendanceList) && (moment((localStorage.getItem("userRole") == 1 ? getContractorAttendanceData : userWiseAttendanceList)?.[0]?.createdAt).format("DD-MM-YY") !== moment().format("DD-MM-YY")) && <AttendanceAction title={"Add"} api={contractorAttendanceAdd} btnColor={"success"}/>
        }  */}
        {
          (localStorage.getItem("userRole") === "1" || (localStorage.getItem("userRole") === "13") || (localStorage.getItem("userRole") === "15") || (localStorage.getItem("userRole") === "16")) && <AttendanceAction title={"Add"} api={contractorAttendanceAdd} btnColor={"success"}/>
        }
        </Col>
        <Col>
          <Button className="float-right" onClick={() => window.print()}>
              <i className="bi bi-printer-fill"></i>
            </Button>
        </Col>
        </Row>
        <h3>{contractorName}</h3>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Sr.</th>
              <th scope="col">Date</th>
              <th scope="col">Halper</th>
              <th scope="col">Ladies Halper</th>
              <th scope="col">Operater</th>
              <th scope="col">Supervisor</th>
              <th scope="col">Person Amount</th>
              <th scope="col">Total Pec</th>
              <th scope="col">Pec Amount</th>
              <th scope="col">Total Kg</th>
              <th scope="col">Kg Amount</th>
              <th scope="col">Attendance Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {((localStorage.getItem("userRole") === "1") ? getContractorAttendanceData : userWiseAttendanceList)?.map((data, index) => {
                return <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{moment(data.date).format("DD-MMM-YY")}</td>
                      <td>{data?.hellper?.total}</td>
                      <td>{data?.ladieshalper?.total}</td>
                      <td>{data?.operater?.total}</td>
                      <td>{data?.supervisor?.total}</td>
                      <td><b>{((data?.operater?.total * data?.operater?.amount) +
                      (data?.supervisor?.total * data?.supervisor?.amount) +
                      (data?.hellper?.total * data?.hellper?.amount) + 
                      (data?.ladieshalper?.total * data?.ladieshalper?.amount)).toFixed(0)} Rs.</b></td>
                      <td>{data?.totalPacket?.total}</td>
                      <td><b>{(data?.totalPacket?.total * data?.totalPacket?.amount).toFixed(0)} Rs.</b></td>
                      <td>{data?.totalUnit?.total.toFixed(0)}</td>
                      <td><b>{(data?.totalUnit?.total * data?.totalUnit?.amount).toFixed(0)} Rs.</b></td>
                      <td>{data?.status === 0 && <b className="text-primary">Pending</b> ||
                          data?.status === 1 && <b className="text-success">Verify</b> ||
                          data?.status === 2 && <b className="text-danger">Reject</b>}</td>
                      <td> 
                        {
                        ((localStorage.getItem("userRole") === "1" || localStorage.getItem("userRole") === "12") && data?.status === 0) && 
                        <AttendanceAction title={"Verify"} status={data?.status} api={contractorAttendanceUpdate} btnColor={"primary"}
                        id={data?._id} date={data?.date}/>}
                        {((data?.status === 0 || data?.status === 2) && (localStorage.getItem("userRole") !== "12")) && <AttendanceAction title={"Edit"} api={contractorAttendanceUpdate} btnColor={"warning"}
                        id={data?._id} totalUnit={data?.totalUnit?.total} supervisor={data?.supervisor?.total} ladieshalper={data?.ladieshalper?.total} totalPacket={data?.totalPacket?.total} 
                        operater={data?.operater?.total} hellper={data?.hellper?.total} date={data?.date}/>}
                      </td>
                    </tr>
              })}
              <tr>
                <td colSpan="6"><b>Total</b></td>
                <td><b>{(calculationColoums?.userAmount).toFixed(0)} Rs.</b></td>
                <td><b>{calculationColoums?.totalPec.toFixed(0)} Pec</b></td>
                <td><b>{calculationColoums?.pecAmount.toFixed(0)} Rs.</b></td>
                <td><b>{calculationColoums?.totalKg.toFixed(0)} Kg</b></td>
                <td><b>{calculationColoums?.kgAmount.toFixed(0)} Rs.</b></td>
              </tr>
          </tbody>
        </table>
        <Row>
          <Col md={12}>
            <p className="mb-0"><b>Total Amount:</b> {localStorage.getItem("userRole") === "13" ? getMyProfileData?.price : contractorAmount} Rs.</p>
            <p className="mb-0"><b>Men Power:</b> {calculationColoums?.userAmount.toFixed(0)} Rs.</p>
            <p className="mb-0"><b>Production:</b> {(calculationColoums?.pecAmount + calculationColoums?.kgAmount).toFixed(0)} Rs.</p>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ContractorsAttendance;
