import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import Row from "react-bootstrap/Row";
import { Formik } from "formik";
import * as yup from "yup";
import moment from "moment";
import { getContractorAttendance, userWiseAttendance} from "../reducers/commonReducer";
import Modal from 'react-bootstrap/Modal';

const schema = yup.object().shape({
  // operater: yup.string().required(),
  // hellper: yup.string().required(),
  // supervisor: yup.string().required(),
  // totalPacket: yup.string().required(),
  // totalUnit: yup.string().required(),
  // date: yup.string().required(),
});

function AttendanceAction(props) {
  const queryParameters = new URLSearchParams(window.location.search);
  const userid = queryParameters.get("id");
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {id, operater,hellper,supervisor,ladieshalper,totalPacket,totalUnit, date, title, api, status, btnColor} = props;

  return (
    <>
     <Button variant={btnColor} onClick={handleShow} className="mx-1">
     {localStorage.getItem("userRole") === "16" ? "Production" : "Attendance"} {title}
      </Button>
      <Modal show={show} onHide={handleClose} size={"lg"}>
        <Modal.Header closeButton>
          <Modal.Title>{title} {localStorage.getItem("userRole") === "16" ? "Production" : "Attendance"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <div className="container-fluid">
                  <Formik
                    validationSchema={schema}
                    onSubmit={async (values, { resetForm }) => {
                     const result = await dispatch(api(values))
                     if(result?.payload.status){
                      handleClose();
                     }
                     setTimeout(() => {
                      localStorage.getItem("userRole") === "1" ? dispatch(getContractorAttendance({
                        id:userid
                        })) :
                        dispatch(userWiseAttendance({
                          id:userid
                        }))
                    }, 1000);
                      resetForm({ values: "" });
                    }}
                    initialValues={{
                    userid:userid,
                    id,
                    operater,
                    hellper,
                    supervisor,
                    ladieshalper,
                    totalPacket,
                    totalUnit,
                    status,
                    date : date ? moment(date).format('YYYY-MM-DD') : moment(new Date())
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      values,
                      errors,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        {
                          ((localStorage.getItem("userRole") === "15") || (localStorage.getItem("userRole") === "1")) && <>
                          <Row className="mb-3">
                          <Col md={6} sm={12}>
                            <Form.Group >
                              <Form.Label>Today Operater</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Operater"
                                name="operater"
                                value={values.operater}
                                onChange={handleChange}
                                isInvalid={!!errors.operater}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.operater}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md={6} sm={12}>
                            <Form.Group >
                              <Form.Label>Hellper</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter hellper"
                                name="hellper"
                                value={values.hellper}
                                onChange={handleChange}
                                isInvalid={!!errors.hellper}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.hellper}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md={6} sm={12}>
                            <Form.Group >
                              <Form.Label>Today Supervisor</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Supervisor"
                                name="supervisor"
                                value={values.supervisor}
                                onChange={handleChange}
                                isInvalid={!!errors.supervisor}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.supervisor}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md={6} sm={12}>
                            <Form.Group >
                              <Form.Label>Today Ladies Halper</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Ladies Halper"
                                name="ladieshalper"
                                value={values.ladieshalper}
                                onChange={handleChange}
                                isInvalid={!!errors.ladieshalper}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.ladieshalper}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                          </>
                        }
                        {
                          ((localStorage.getItem("userRole") === "15") || (localStorage.getItem("userRole") === "16")) && 
                          <Row className="mb-3">
                            <Col md={12} sm={12}>
                              <Form.Group >
                                <Form.Label>Date</Form.Label>
                                <Form.Control
                                  type="date"
                                  placeholder="date"
                                  name="date"
                                  value={values.date}
                                  onChange={handleChange}
                                  isInvalid={!!errors.date}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.date} 
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                        }
                        {
                          ((localStorage.getItem("userRole") === "16") || (localStorage.getItem("userRole") === "1")) && <Row className="mb-3">
                          <Col md={6} sm={12}>
                            <Form.Group >
                              <Form.Label>Total Packet</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Enter Packet (200)"
                                name="totalPacket"
                                value={values.totalPacket}
                                onChange={handleChange}
                                isInvalid={!!errors.totalPacket}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.totalPacket}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md={6} sm={12}>
                            <Form.Group >
                              <Form.Label>Total Kg</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Enter Kg (100)"
                                name="totalUnit"
                                value={values.totalUnit}
                                onChange={handleChange}
                                isInvalid={!!errors.totalUnit}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.totalUnit}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        }
                        {
                          ((localStorage.getItem("userRole") === "12") || (localStorage.getItem("userRole") === "1")) &&
                          <Col md={12} sm={12} className="mb-4">
                            <Form.Label>Status</Form.Label>
                            <br />
                            <Form.Control
                              as="select"
                              onChange={handleChange}
                              name="status"
                              className="form-control"
                              value={+values.status}
                              isInvalid={!!errors.status}
                            >
                              <option value="">Select Attendance Status</option>
                              <option value={1}>Verify</option>
                              <option value={2}>Reject</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.status}
                            </Form.Control.Feedback>
                          </Col> 
                        }
                        <Button type="submit">Submit</Button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Modal.Body>
              </Modal>
    </>
  );
}

export default AttendanceAction;
