import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getDispatchByWithOutPage,
  getOrderList,
  getOrderListRest,
} from "../reducers/commonReducer";
import moment from "moment";
import SideMenu from "../Components/SideMenu";
import { Row, Col, Form, Button } from "react-bootstrap";
import UpdateOrder from "../Components/UpdateOrder";
import ViewOrderDetail from "../Components/ViewOrderDetail";
import CancelOrder from "../Components/CancelOrder";

const UserWiseOrders = (props) => {
  let params = useParams();
  const dispatch = useDispatch();
  const [userStatus, setUserStatus] = useState();
  const [page, setPage] = useState(1);
  const [dispatchID, setDispatchID] = useState();
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [startDate, setStartDate] = useState();
  useEffect(() => {
    dispatch(getOrderListRest());
    dispatch(
      getOrderList({
        salesMan: params?.name,
        getUsersOrderList,
      })
    );
    localStorage.getItem("userRole") == 1 &&
      dispatch(getDispatchByWithOutPage());
  }, []);
  const { getUsersOrderList, dispatchByWithOutPage, getOrderBlank } =
    useSelector((state) => state.commonReducer);

  const header = [
    "Sr",
    "Order Date",
    "SalesManName",
    "FirmName",
    "Status",
    "ProductName",
    "Quantity",
    "Price",
  ];
  let body = [];
  !!getUsersOrderList != undefined &&
    getUsersOrderList?.map((data, index) => {
      body.push({
        Sr: index + 1,
        Date: moment(data.createdAt).format("DD/MM/YYYY HH:mm:ss"),
        SalesManName: data?.salesMan,
        FirmName: data?.firmName,
        Status: data?.status,
        orders: data?.order?.map((orderD, index) => {
          body.push({
            Sr: "",
            Date: "",
            SalesManName: "",
            DistributorName: "",
            Status: "",
            ProductName: orderD?.itemName,
            Quantity: orderD?.quantity,
            Price: orderD?.prise,
          });
        }),
      });
    });
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <Row className="mb-3">
          <Col md={3} sm={12}>
            <Form.Control
              as="select"
              onChange={(e) => {
                setPage(1);
                setDispatchID(e.target.value);
                dispatch(getOrderListRest());
                dispatch(
                  getOrderList({
                    companyName: e.target.value,
                    startDate,
                    endDate,
                  })
                );
              }}
              name="userStatus"
              className="form-control"
            >
              <option value={""}>Select DispatchBy</option>
              {dispatchByWithOutPage?.length > 0 &&
                dispatchByWithOutPage?.map((data, index) => {
                  return (
                    <option
                      key={index}
                      value={data?._id ? data?._id : data?.id}
                    >
                      {data?.dispatch}
                    </option>
                  );
                })}
            </Form.Control>
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              as="select"
              onChange={(e) => {
                setPage(1);
                setUserStatus(e.target.value);
                dispatch(getOrderListRest());
                dispatch(
                  getOrderList({
                    userStatus: e.target.value,
                    startDate,
                    endDate,
                  })
                );
              }}
              name="userStatus"
              className="form-control"
            >
              <option value={""}>Select Status</option>
              <option value={"Pending"}>Pending</option>
              <option value={"Processing"}>Processing</option>
              <option value={"Verify"}>Verify</option>
            </Form.Control>
          </Col>
          <Col md={3} sm={12}>
            <Form.Control
              type="date"
              placeholder="Start Date"
              name="endDate"
              onChange={async (e) => {
                setPage(1);
                setStartDate(e.target.value);
                dispatch(getOrderListRest());
                dispatch(
                  getOrderList({
                    userStatus,
                    startDate: e.target.value,
                    endDate,
                  })
                );
              }}
              value={startDate}
            />
          </Col>
          <Col md={3} sm={12}>
            <Form.Control
              type="date"
              placeholder="End Date"
              name="startDate"
              onChange={async (e) => {
                setPage(1);
                setEndDate(e.target.value);
                dispatch(getOrderListRest());
                dispatch(
                  getOrderList({
                    userStatus,
                    startDate,
                    endDate: e.target.value,
                  })
                );
              }}
              value={endDate}
            />
          </Col>
          <Col md="1">
            <Button
              variant="danger"
              onClick={() => window.location.reload(false)}
            >
              Clear
            </Button>
          </Col>
          {/* <Col md={3}>
            <ExportData
              title="Export Orders"
              header={header}
              body={body}
              tableName={"ShyamG Orders"}
            />
          </Col> */}
        </Row>
        <table className="table tableFixHead">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Order</th>
              <th scope="col">Date</th>
              <th scope="col">Expected Date</th>
              <th scope="col">Town</th>
              {localStorage.getItem("x-auth-token") &&
                (localStorage.getItem("userRole") == 6 ||
                  localStorage.getItem("userRole") == 1) && (
                  <th scope="col">SalesMan</th>
                )}
              <th scope="col">FirmName</th>
              <th scope="col">Dispatch By</th>
              <th scope="col">Comment</th>
              <th scope="col">Status</th>
              <th scope="col">PaymentStatus</th>
              <th scope="col">CancelOrder</th>
              {(localStorage.getItem("userRole") == 6 ||
                localStorage.getItem("userRole") == 7 ||
                localStorage.getItem("userRole") == 10) && (
                <th scope="col">Action</th>
              )}
            </tr>
          </thead>
          <tbody>
            {!!getUsersOrderList &&
              getUsersOrderList?.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>
                      <ViewOrderDetail orders={data?.order} />
                    </td>
                    <td>
                      {moment(data?.createdAt).format("DD/MM/YYYY HH:mm:ss")}
                    </td>
                    <td>
                      {data?.deliverDate
                        ? moment(data?.deliverDate).format("DD/MM/YYYY")
                        : ""}
                    </td>
                    <td>{data?.distributorAddress?.town}</td>
                    {localStorage.getItem("x-auth-token") &&
                      (localStorage.getItem("userRole") == 6 ||
                        localStorage.getItem("userRole") == 1) && (
                        <td>{data?.salesManName}</td>
                      )}
                    <td>{data?.firm}</td>
                    <td>{data?.dispatchBy?.dispatch}</td>
                    <td>{data?.comment}</td>
                    <td>
                      <span
                        className={
                          (data.status == "Processing" && "text-primary") ||
                          (data.status == "Pending" && "text-warning") ||
                          (data.status == "Deliver" && "text-success")
                        }
                      >
                        <b>{data?.status}</b>
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          data.userPayment == "Confirmd"
                            ? "text-success"
                            : "text-danger"
                        }
                      >
                        <b>{data?.userPayment}</b>
                      </span>
                    </td>
                    <td>
                      <span
                        className={
                          data.orderStatus == "Cancel" && "text-danger"
                        }
                      >
                        <b>{data?.orderStatus}</b>
                      </span>
                    </td>
                    {localStorage.getItem("userRole") == 6 && (
                      <td>
                        <UpdateOrder
                          id={data._id}
                          comment={data.comment}
                          quantity={data?.order}
                        />
                      </td>
                    )}
                    {(localStorage.getItem("userRole") == 7 ||
                      localStorage.getItem("userRole") == 10) &&
                    data?.status == "Pending" &&
                    data?.orderStatus != "Cancel" ? (
                      <td>
                        <CancelOrder id={data._id} orders={data?.order} />
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                );
              })}
          </tbody>
        </table>
        {!!getOrderBlank && getOrderBlank.length > 0 ? (
          <Row>
            <Button
              onClick={() => {
                setPage(page + 1);
                dispatch(
                  getOrderList({
                    companyName: dispatchID,
                    salesMan: params?.name,
                    page: page + 1,
                    userStatus,
                    endDate,
                    startDate,
                  })
                );
              }}
            >
              Load More
            </Button>
          </Row>
        ) : (
          <p className="text-center">
            <b>No record found 😔.</b>
          </p>
        )}
      </div>
    </>
  );
};
export default UserWiseOrders;
