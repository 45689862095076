import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrderList,
  getOrderListRest,
  getProducts,
} from "../reducers/commonReducer";
import moment from "moment";
import SideMenu from "../Components/SideMenu";
import { Row, Col, Button, Form } from "react-bootstrap";
import ExportData from "../Components/ExportData";
import ViewOrderDetail from "../Components/ViewOrderDetail";

const CompanyWiseDeliverdOrder = () => {
  let params = useParams();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [startDate, setStartDate] = useState();
  const { getUsersOrderList, getOrderBlank } = useSelector(
    (state) => state.commonReducer
  );
  useEffect(() => {
    dispatch(getOrderListRest());
    dispatch(
      getOrderList({
        companyName: params?.name,
        userStatus: "Deliver",
      })
    );
    dispatch(getProducts());
  }, []);
  const header = [
    "Sr",
    "Order Date",
    "Order Delivered Date",
    "SalesManName",
    "FirmName",
    "Status",
    "ProductName",
    "Quantity",
    "DeliveredQuantity",
    "Price",
  ];
  let body = [];
  !!getUsersOrderList != undefined &&
    getUsersOrderList.map((data, index) => {
      body.push({
        Sr: index + 1,
        Date: moment(data.createdAt).format("DD/MM/YYYY HH:mm:ss"),
        OrderDeliveredDate: moment(data.updatedAt).format(
          "DD/MM/YYYY HH:mm:ss"
        ),
        SalesManName: data?.salesMan,
        FirmName: data?.firmName,
        Status: data?.status,
        orders: data?.order.map((orderD, index) => {
          body.push({
            Sr: "",
            Date: "",
            OrderDeliveredDate: "",
            SalesManName: "",
            DistributorName: "",
            Status: "",
            ProductName: orderD?.itemName,
            Quantity: orderD?.quantity,
            DeliveredQuantity: orderD?.dquantity,
            Price: orderD?.prise,
          });
        }),
      });
    });

  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <Row className="mb-3">
          {/* <Col md={3} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setUserStatus(e.target.value);
                dispatch(
                  getOrderList({
                    companyName: params?.name,
                    userStatus: e.target.value,
                  })
                );
              }}
              name="userStatus"
              className="form-control"
            >
              <option value="">Select Status</option>
              <option value={"Pending"}>Pending</option>
              <option value="Processing">Processing</option>
            </Form.Control>
          </Col> */}
          <Col md={3} sm={12}>
            <Form.Control
              type="date"
              placeholder="Start Date"
              name="endDate"
              onChange={async (e) => {
                setPage(1);
                setStartDate(e.target.value);
                dispatch(getOrderListRest());
                dispatch(
                  getOrderList({
                    companyName: params?.name,
                    userStatus: "Deliver",
                    startDate: e.target.value,
                    endDate: endDate,
                  })
                );
              }}
              value={startDate}
            />
          </Col>
          <Col md={3} sm={12}>
            <Form.Control
              type="date"
              placeholder="End Date"
              name="startDate"
              onChange={async (e) => {
                setPage(1);
                setEndDate(e.target.value);
                dispatch(getOrderListRest());
                dispatch(
                  getOrderList({
                    companyName: params?.name,
                    userStatus: "Deliver",
                    startDate: startDate,
                    endDate: e.target.value,
                  })
                );
              }}
              value={endDate}
            />
          </Col>
          {/* <Col>
            <ExportData
              title="Export Orders"
              header={header}
              body={body}
              tableName="ShyamG Super Stockist Delivered Order"
            />
          </Col> */}
        </Row>
        <table className="table table-scrolling">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Order</th>
              <th scope="col">InvoiceNumber</th>
              <th scope="col">FirmName</th>
              <th scope="col">Town</th>
              <th scope="col">Order Date</th>
              <th scope="col">Delivered Date</th>
              {/* <th scope="col">Comment</th> */}
              <th scope="col">Status</th>
              {/* {localStorage.getItem("x-auth-token") && localStorage.getItem("userRole") === 6 || 1 && (
              <th scope="col">Action</th>)} */}
            </tr>
          </thead>
          <tbody>
            {!!getUsersOrderList &&
              getUsersOrderList.map((data, index) => {
                return (
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>
                        <ViewOrderDetail orders={data?.order} />
                      </td>
                      <td>{data?.invoiceNumber}</td>
                      <td>{data?.firmName}</td>
                      <td>{data?.distributorAddress?.town}</td>
                      <td>
                        {moment(data.createdAt).format("DD/MM/YYYY HH:mm:ss")}
                      </td>
                      <td>
                        {data?.deliverDate ? moment(data.deliverDate).format("DD/MM/YYYY HH:mm:ss") : moment(data.updatedAt).format("DD/MM/YYYY HH:mm:ss")}
                      </td>
                      {/* <td>{data?.comment}</td> */}
                      <td>
                        <span
                          className={
                            (data.status == "Processing" && "text-primary") ||
                            (data.status == "Pending" && "text-warning") ||
                            (data.status == "Deliver" && "text-success")
                          }
                        >
                          <b>{data?.status}</b>
                        </span>
                      </td>
                      {/* {localStorage.getItem("x-auth-token") && localStorage.getItem("userRole") === 6 || 1 && (
                      <td>
                        <UpdateOrder id={orders._id} quantity={orders?.order} companyName={params?.name} status={data?.status}/>
                      </td>)} */}
                    </tr>
                );
              })}
          </tbody>
        </table>
        {!!getOrderBlank && getOrderBlank.length > 0 ? (
          <Row>
            <Button
              onClick={() => {
                setPage(page + 1);
                dispatch(
                  getOrderList({
                    companyName: params?.name,
                    page: page + 1,
                    userStatus: "Deliver",
                    startDate: startDate,
                    endDate: endDate,
                  })
                );
              }}
            >
              Load More
            </Button>
          </Row>
        ) : (
          <p className="text-center">
            <b>No record found 😔.</b>
          </p>
        )}
      </div>
    </>
  );
};

export default CompanyWiseDeliverdOrder;
