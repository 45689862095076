import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  baseUrl,
  getDispatchbyOrderProductReports,
  getDispatchbyProductReports,
  getDispatchByWithOutPage,
  getOrderListRest,
  getStatesList,
} from "../reducers/commonReducer";
import moment from "moment";
import SideMenu from "../Components/SideMenu";
import { Row, Col, Form, Button } from "react-bootstrap";
import { commonFunction } from "./commonFunction/commonFunction";

const SuperStockistsOrderProductReport = () => {
  const dispatch = useDispatch();
  const [startMonth, setStartMonth] = useState();
  const [endMonth, setEndMonth] = useState();
  const [year, setYear] = useState(moment().format("YYYY"));
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();

  const { getDispatchbyOrderProductReportsList } = useSelector(
    (state) => state.commonReducer
  );
  useEffect(() => {
    dispatch(getOrderListRest());
    dispatch(getStatesList());
    dispatch(
      getDispatchbyOrderProductReports({
        year: moment().format("YYYY"),
      })
    );
  }, []);

  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <Form action={`${baseUrl}/api/superstokistProducts/csv`}>
          <Row className="mb-3">
            <Col md={4} sm={12}>
              <Form.Control
                as="select"
                onChange={async (e) => {
                  setStartMonth(e.target.value);
                  commonFunction(e.target.value);
                }}
                name="startMonth"
                className="form-control"
              >
                <option value="">StartMonth</option>
                <option value="1">Jan</option>
                <option value="2">Feb</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">Aug</option>
                <option value="9">Sep</option>
                <option value="10">Oct</option>
                <option value="11">Nov</option>
                <option value="12">Dec</option>
              </Form.Control>
            </Col>
            <Col md={4} sm={12}>
              <Form.Control
                as="select"
                onChange={async (e) => {
                  setEndMonth(e.target.value);
                }}
                name="endMonth"
                className="form-control"
              >
                <option value="">EndMonth</option>
                <option value="1">Jan</option>
                <option value="2">Feb</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">Aug</option>
                <option value="9">Sep</option>
                <option value="10">Oct</option>
                <option value="11">Nov</option>
                <option value="12">Dec</option>
              </Form.Control>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col md={2} sm={12}>
              <label>Start Date</label>
              <Form.Control
                type="date"
                placeholder="Start Date"
                name="startDate"
                value={startDate}
                onChange={async (e) => {
                  setStartDate(e.target.value);
                }}
              />
            </Col>
            <Col md={2} sm={12}>
              <label>End Date</label>
              <Form.Control
                type="date"
                placeholder="End Date"
                name="endDate"
                value={endDate}
                onChange={async (e) => {
                  setEndDate(e.target.value);
                }}
              />
            </Col>
            <Col md={1} sm={12} className="mt-4">
              <Form.Control
                type="text"
                placeholder="Year"
                name="year"
                value={year}
                onChange={async (e) => {
                  setYear(e.target.value);
                }}
              />
            </Col>
            <Col md="1" className="mt-4">
              <Button
                variant="success"
                onClick={() => {
                  dispatch(
                    getDispatchbyOrderProductReports({
                      startMonth: startMonth,
                      endMonth: endMonth,
                      year: year,
                      startDate,
                      endDate,
                    })
                  );
                }}
              >
                Submit
              </Button>
            </Col>
            <Col md="1" className="mt-4">
              <Button
                variant="danger"
                onClick={() => window.location.reload(false)}
              >
                Clear
              </Button>
            </Col>
            <Col md="1" className="mt-4">
              <Button variant="warning" type="submit">
                Export
              </Button>
            </Col>
          </Row>
        </Form>
        <table className="table table-scrolling mt-2">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">
                Super Stockest <br />
                (Party Name)
              </th>
              <th scope="col">Address</th>
              <th scope="col">SO</th>
              <th scope="col">ASM</th>
              {/* <th scope="col">DispatchBy</th> */}
              {!!getDispatchbyOrderProductReportsList &&
                !!getDispatchbyOrderProductReportsList?.products &&
                Object.entries(
                  getDispatchbyOrderProductReportsList?.products
                ).map(([key, value]) => <th scope="col">{value}</th>)}
              <th scope="col">Total CB</th>
            </tr>
          </thead>
          <tbody>
            {!!getDispatchbyOrderProductReportsList &&
              !!getDispatchbyOrderProductReportsList?.productWiseOrder &&
              Object.values(
                getDispatchbyOrderProductReportsList?.productWiseOrder
              ).map((data, index) => {
                return (
                  <>
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{data?.firmName}</td>
                      <td>{`${data?.address?.town}, ${data?.address?.district}, ${data?.address?.state}`}</td>
                      <td>{data?.salesManName}</td>
                      <td>{data?.asmUser?.name}</td>
                      {/* <td>{data?.dispatchUser?.dispatch}</td> */}
                      {!!getDispatchbyOrderProductReportsList?.products &&
                        Object.keys(
                          getDispatchbyOrderProductReportsList?.products
                        ).map(function (key, value) {
                          return <td>{data[key]}</td>;
                        })}
                      <td>{data?.cbtotal}</td>
                    </tr>
                  </>
                );
              })}
            <tr>
              <td colSpan="5" className="text-center">
                <b>Total</b>
              </td>
              {!!getDispatchbyOrderProductReportsList &&
                !!getDispatchbyOrderProductReportsList?.total &&
                Object.keys(getDispatchbyOrderProductReportsList?.products).map(
                  function (key, value) {
                    return (
                      <td>
                        <b>
                          {getDispatchbyOrderProductReportsList?.total[key]}
                        </b>
                      </td>
                    );
                  }
                )}
              <td>
                <b>
                  {!!getDispatchbyOrderProductReportsList &&
                    getDispatchbyOrderProductReportsList?.total?.cbTotel}
                </b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default SuperStockistsOrderProductReport;
