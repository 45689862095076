import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import {
  distributorCreate,
  getDistrictList,
  getSelectedDistributor,
  getOrderListRest,
  getDistributorList,
  getSalesPersonWithoutPage,
  getDispatchByWithOutPage,
} from "../reducers/commonReducer";

const schema = yup.object().shape({
  name: yup.string().required(),
  number: yup.string().min(10).max(10).required(),
  userName: yup.string().required(),
  password: yup.string(),
  town: yup.string().required(),
  district: yup.string().required(),
  state: yup.string().required(),
  GSTIN: yup.string(),
  _id: yup.string(),
  firmName: yup.string().required(),
  email: yup.string(),
  salesmanName: yup.string().required(),
  dispatchStatus: yup.string().required(),
  dispatchBy: yup
    .array()
    .max(2, "Please select no more than two dispatchBy")
    .required("Please select at least one dispatchBy"),
});

const DistributorAdd = (props) => {
  const timeoutIdRef = React.useRef();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    dispatchByWithOutPage,
    getSalesPersonWithoutPageList,
    statesList,
    getDistrict,
  } = useSelector((state) => state.commonReducer);
  const {
    town,
    district,
    state,
    firmName,
    GSTIN,
    name,
    number,
    _id,
    email,
    btnTitle,
    edispatchStatus,
  } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="success" onClick={handleShow}>
        <i className="bi bi-plus"></i> {btnTitle}
      </Button>
      <Modal show={show} size="lg" onHide={handleClose}>
        <div className="row px-2">
          <Row className="text-center">
            <h3 className="mb-4 mt-4">Create Distributor</h3>
          </Row>
          <div className="container-fluid">
            <Formik
              validationSchema={schema}
              onSubmit={async (values, { resetForm }) => {
                await dispatch(distributorCreate(values));
                resetForm({ values: "" });
                clearTimeout(timeoutIdRef.current);
                timeoutIdRef.current = setTimeout(() => {
                  dispatch(getOrderListRest());
                  dispatch(getSalesPersonWithoutPage());
                  dispatch(
                    location.pathname === "/distributor-list"
                      ? getDistributorList()
                      : getSelectedDistributor({
                          mode: "OffLine",
                        })
                  );
                }, 1000);
                setShow(false);
              }}
              initialValues={{
                name: name,
                number: number,
                userName: "",
                town: town,
                GSTIN: GSTIN,
                _id: _id,
                email: email,
                district: district,
                state: state,
                password: "",
                firmName: firmName,
                dispatchBy: [],
                salesmanName: "",
                mode: props?.mode,
                dispatchStatus: "",
              }}
            >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                values,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          isInvalid={!!errors.name}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label>Mobile Number</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Mobile Number"
                          name="number"
                          value={values.number}
                          onChange={handleChange}
                          isInvalid={!!errors.number}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={12} sm={12}>
                      <Form.Label className="text-danger">
                        You are SuperStockist
                      </Form.Label>
                      <br />
                      <Form.Control
                        as="select"
                        onChange={async (e) => {
                          await setFieldValue(
                            "dispatchStatus",
                            /true/.test(e.target.value)
                          );
                        }}
                        name="dispatchStatus"
                        className="form-control"
                        value={values.dispatchStatus}
                        isInvalid={!!errors.dispatchStatus}
                      >
                        <option value="">Select User Type</option>
                        <option className="first-latter-capital" value={false}>
                          No, I am Distributor
                        </option>
                        <option className="first-latter-capital" value={true}>
                          Yes, I am SuperStockist
                        </option>
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.dispatchStatus}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          isInvalid={!!errors.email}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label>Town</Form.Label>
                        <br />
                        <Form.Control
                          type="text"
                          placeholder="Town"
                          name="town"
                          value={values.town}
                          onChange={handleChange}
                          isInvalid={!!errors.town}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label>State</Form.Label>
                        <br />
                        <Form.Control
                          style={{ textTransform: "capitalize" }}
                          as="select"
                          onChange={async (e) => {
                            setFieldValue("state", e.target.value);
                            dispatch(getDistrictList(e.target.value));
                          }}
                          name="state"
                          className="form-control"
                          value={values.state}
                          isInvalid={!!errors.state}
                        >
                          <option value="">Select State</option>
                          {statesList.map((data, index) => {
                            return (
                              <option
                                className="first-latter-capital"
                                key={index}
                                value={data.state}
                              >
                                {data.state}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label>District</Form.Label>
                        <br />
                        {!!values.district ? (
                          <Form.Control
                            type="text"
                            placeholder="District"
                            name="district"
                            value={values.district}
                            onChange={handleChange}
                            isInvalid={!!errors.district}
                          />
                        ) : (
                          <Form.Control
                            style={{ textTransform: "capitalize" }}
                            as="select"
                            name="district"
                            onChange={handleChange}
                            value={values.district}
                            className="form-control"
                            isInvalid={!!errors.district}
                          >
                            <option value="">Select District</option>
                            {!!getDistrict &&
                              getDistrict.map((data, index) => {
                                return (
                                  <option
                                    className="first-latter-capital"
                                    value={data}
                                    key={index}
                                  >
                                    {data}
                                  </option>
                                );
                              })}
                          </Form.Control>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label>FirmName</Form.Label>
                        <br />
                        <Form.Control
                          type="text"
                          placeholder="FirmName"
                          name="firmName"
                          value={values.firmName}
                          onChange={handleChange}
                          isInvalid={!!errors.firmName}
                        />
                      </Form.Group>
                      <Form.Control.Feedback type="invalid">
                        {errors.firmName}
                      </Form.Control.Feedback>
                    </Col>
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label>GSTIN</Form.Label>
                        <br />
                        <Form.Control
                          type="text"
                          placeholder="GSTIN"
                          name="GSTIN"
                          value={values.GSTIN}
                          onChange={handleChange}
                          isInvalid={!!errors.GSTIN}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={6} sm={12}>
                      <Form.Label className="text-danger">
                        Select Salesman
                      </Form.Label>
                      <br />
                      <Form.Control
                        as="select"
                        onChange={async (e) => {
                          await setFieldValue("salesmanName", e.target.value);
                          dispatch(
                            getDispatchByWithOutPage({
                              salesMan: e.target.value,
                            })
                          );
                        }}
                        name="salesmanName"
                        className="form-control"
                        value={values.salesmanName}
                        isInvalid={!!errors.salesmanName}
                      >
                        <option value="">Select SO</option>
                        {!!getSalesPersonWithoutPageList &&
                          getSalesPersonWithoutPageList.map((data, index) => {
                            return (
                              <option
                                className="first-latter-capital"
                                key={index}
                                value={data?._id ? data?._id : data?.id}
                              >
                                {data?.name}
                              </option>
                            );
                          })}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.salesmanName}
                      </Form.Control.Feedback>
                    </Col>
                    {!!values.salesmanName && (
                      <Col md={6} sm={12}>
                        <Form.Label className="text-danger">
                          Select DispatchBy
                        </Form.Label>
                        <br />
                        <Form.Control
                          as="select"
                          multiple={true}
                          onChange={handleChange}
                          name="dispatchBy"
                          className="form-control"
                          value={values.dispatchBy}
                          isInvalid={!!errors.dispatchBy}
                        >
                          {!!dispatchByWithOutPage &&
                            dispatchByWithOutPage.map((data, index) => {
                              return (
                                <option
                                  className="first-latter-capital"
                                  key={index}
                                  value={data?._id ? data?._id : data?.id}
                                >
                                  {data?.dispatch}
                                </option>
                              );
                            })}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.dispatchBy}
                        </Form.Control.Feedback>
                      </Col>
                    )}
                  </Row>
                  <Row className="mt-3">
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label className="text-danger">
                          Please enter unique UserName.
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="User Name"
                          name="userName"
                          value={values.userName
                            .toLowerCase()
                            .replace(/ /g, "-")}
                          onChange={handleChange}
                          isInvalid={!!errors.userName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.userName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label className="text-danger">
                          Please enter unique Password.
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          isInvalid={!!errors.password}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button type="submit" className="mt-3">
                    Submit
                  </Button>
                  <Button
                    variant="danger"
                    onClick={handleClose}
                    className="mt-3"
                  >
                    Close
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DistributorAdd;
