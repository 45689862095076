import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDistrictList,
  getOrderdDeliverdProductReports,
  getOrderListRest,
  getStatesList,
  getASMPersonsWithOutPage,
  getSalesPersonWithoutPage,
} from "../reducers/commonReducer";
import moment from "moment";
import SideMenu from "../Components/SideMenu";
import { Row, Col, Form, Button } from "react-bootstrap";

const OrderdDeliverdProduct = () => {
  const dispatch = useDispatch();
  const [startMonth, setStartMonth] = useState();
  const [endMonth, setEndMonth] = useState();
  const [state, setState] = useState();
  const [district, setDistrict] = useState();
  const [selectedSO, setselectedSO] = useState();
  const [selectedAsm, setselectedASM] = useState();
  const [year, setYear] = useState(moment().format("YYYY"));

  const {
    statesList,
    getDistrict,
    asmPersonsWithOutPage,
    getSalesPersonWithoutPageList,
    OdrdDlvrProdtRpotList,
  } = useSelector((state) => state.commonReducer);
  useEffect(() => {
    dispatch(getOrderListRest());
    dispatch(
      getOrderdDeliverdProductReports({
        year: year,
      })
    );
    dispatch(getStatesList());
    dispatch(getASMPersonsWithOutPage());
    dispatch(getSalesPersonWithoutPage());
  }, []);
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <Row className="mb-3">
          <Col md={2} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setState(e.target.value);
                e.target.value.length > 1 &&
                  dispatch(getDistrictList(e.target.value));
              }}
              name="state"
              className="form-control"
            >
              <option value={""}>Select State</option>
              {!!statesList &&
                statesList.map((data, index) => {
                  return (
                    <option key={index} value={data?.state}>
                      {data?.state}
                    </option>
                  );
                })}
            </Form.Control>
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setDistrict(e.target.value);
              }}
              name="state"
              className="form-control"
            >
              <option value="">Select District</option>
              {!!getDistrict &&
                getDistrict.map((data, index) => {
                  return (
                    <option key={index} value={data}>
                      {data}
                    </option>
                  );
                })}
            </Form.Control>
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setselectedASM(e.target.value);
                dispatch(
                  getSalesPersonWithoutPage({
                    asm: e.target.value,
                  })
                );
              }}
              name="asm"
              className="form-control"
            >
              <option value="">Select ASM</option>
              {!!asmPersonsWithOutPage &&
                asmPersonsWithOutPage.map((data, index) => {
                  return (
                    <option key={index} value={data?.userName}>
                      {data?.userName}
                    </option>
                  );
                })}
            </Form.Control>
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setselectedSO(e.target.value);
              }}
              name="so"
              className="form-control"
            >
              <option value="">Select SO</option>
              {!!getSalesPersonWithoutPageList &&
                getSalesPersonWithoutPageList.map((data, index) => {
                  return (
                    <option key={index} value={data?.userName}>
                      {data?.name}
                    </option>
                  );
                })}
            </Form.Control>
          </Col>
          <Col md={1} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setStartMonth(e.target.value);
              }}
              name="state"
              className="form-control"
            >
              <option value="">StartMonth</option>
              <option value="1">Jan</option>
              <option value="2">Feb</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">Aug</option>
              <option value="9">Sep</option>
              <option value="10">Oct</option>
              <option value="11">Nov</option>
              <option value="12">Dec</option>
            </Form.Control>
          </Col>
          <Col md={1} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setEndMonth(e.target.value);
              }}
              name="state"
              className="form-control"
            >
              <option value="">EndMonth</option>
              <option value="1">Jan</option>
              <option value="2">Feb</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">Aug</option>
              <option value="9">Sep</option>
              <option value="10">Oct</option>
              <option value="11">Nov</option>
              <option value="12">Dec</option>
            </Form.Control>
          </Col>
          <Col md={1} sm={12}>
            <Form.Control
              type="text"
              placeholder="Year"
              name="year"
              value={year}
              onChange={async (e) => {
                setYear(e.target.value);
              }}
            />
          </Col>
          <Col md="1">
            <Button
              variant="success"
              onClick={() => {
                dispatch(
                  getOrderdDeliverdProductReports({
                    startMonth: startMonth,
                    endMonth: endMonth,
                    year: year,
                    state: state,
                    district: district,
                    asm: selectedAsm,
                    so: selectedSO,
                  })
                );
              }}
            >
              Submit
            </Button>
          </Col>
          <Col md="1">
            <Button
              variant="danger"
              onClick={() => window.location.reload(false)}
            >
              Clear
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <h4>
              <b>
                (Company, State, District, ASM, SO)
                <br />
                Product Wise Orderd and Deliverd Report
              </b>
            </h4>
            <p className="mb-0">
              <b>Year: </b> {year}
            </p>
          </Col>
        </Row>
        <table className="table table-scrolling">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Party Name</th>
              <th scope="col">Address</th>
              {/* <th scope="col">SO</th>
              <th scope="col">ASM</th>
              <th scope="col">DispatchBy</th> */}
              {!!OdrdDlvrProdtRpotList &&
                !!OdrdDlvrProdtRpotList?.products &&
                Object.entries(OdrdDlvrProdtRpotList?.products).map(
                  ([key, value]) => <th scope="col">{value}</th>
                )}
              <th scope="col">Total CB</th>
            </tr>
          </thead>
          <tbody>
            {!!OdrdDlvrProdtRpotList &&
              !!OdrdDlvrProdtRpotList?.productWiseOrder &&
              Object.values(OdrdDlvrProdtRpotList?.productWiseOrder).map(
                (data, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <td>{index + 1}.</td>
                        <td>{data?.name}</td>
                        <td>{`${data?.address?.town}, ${data?.address?.district}, ${data?.address?.state}`}</td>
                        {/* <td>{data?.salesman}</td>
                        <td>{data?.asmUser?.userName}</td>
                        <td>{data?.dispatchUser?.dispatch}</td> */}
                        {!!OdrdDlvrProdtRpotList?.products &&
                          Object.keys(OdrdDlvrProdtRpotList?.products).map(
                            function (key, value) {
                              return (
                                <td>
                                  <tr>
                                    <td>Orderd</td>
                                    <td>Deliverd</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {data[key]?.Other ? (
                                        <b>{data[key]?.Other}</b>
                                      ) : (
                                        0
                                      )}
                                    </td>
                                    <td>
                                      {data[key]?.Deliver ? (
                                        <b>{data[key]?.Deliver}</b>
                                      ) : (
                                        0
                                      )}
                                    </td>
                                  </tr>
                                </td>
                              );
                            }
                          )}
                        <td>
                          <tr>
                            <td>Orderd</td>
                            <td>Deliverd</td>
                          </tr>
                          <tr>
                            <td>
                              {data?.cbtotal?.Other ? (
                                <b>{data?.cbtotal?.Other}</b>
                              ) : (
                                0
                              )}
                            </td>
                            <td>
                              <b>
                                {data?.cbtotal?.Deliver ? (
                                  <b>{data?.cbtotal?.Deliver}</b>
                                ) : (
                                  0
                                )}
                              </b>
                            </td>
                          </tr>
                        </td>
                      </tr>
                    </>
                  );
                }
              )}
            <tr>
              <td colSpan="3" className="text-center">
                <b>Total</b>
              </td>
              {!!OdrdDlvrProdtRpotList &&
                !!OdrdDlvrProdtRpotList?.total &&
                Object.keys(OdrdDlvrProdtRpotList?.products).map(function (
                  key,
                  value
                ) {
                  return (
                    <td>
                      <tr>
                        <td>Orderd</td>
                        <td>Deliverd</td>
                      </tr>
                      <tr>
                        <td>
                          <b>{OdrdDlvrProdtRpotList?.total[key]?.Other}</b>
                        </td>
                        <td>
                          <b>{OdrdDlvrProdtRpotList?.total[key]?.Deliver}</b>
                        </td>
                      </tr>
                    </td>
                  );
                })}
              <td>
                <tr>
                  <td>Orderd</td>
                  <td>Deliverd</td>
                </tr>
                <tr>
                  <td>
                    <b>
                      {!!OdrdDlvrProdtRpotList &&
                        OdrdDlvrProdtRpotList?.total?.cbTotel?.Other}
                    </b>
                  </td>
                  <td>
                    <b>
                      {!!OdrdDlvrProdtRpotList &&
                        OdrdDlvrProdtRpotList?.total?.cbTotel?.Deliver}
                    </b>
                  </td>
                </tr>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default OrderdDeliverdProduct;
