import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { contactUpdate, feedBackUpdate, getContactList, getFeedBackList, getOrderListRest } from "../reducers/commonReducer";

function WebSiteContactAction(props) {
  const dispatch = useDispatch();
  const { userStatus, id, adminCmnt } = props;
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState(userStatus);
  const [adminComment, setAdminComment] = useState(adminCmnt);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const updateUser = async () => {
    await dispatch(contactUpdate({adminComment, status, id}));
    dispatch(getOrderListRest());
    dispatch(getContactList())
    setShow(false);
  };
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        <i className="bi bi-pencil-square"></i>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update FeedBack Status</Modal.Title>
        </Modal.Header>
        <Form.Group className="p-3">
          <Form.Label>Comment</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={adminComment}
            onChange={(e) => setAdminComment(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="p-3">
          <Form.Select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="">Select Status</option>
            <option value={"Pending"}>Pending</option>
            <option value={"Read"}>Read</option>
          </Form.Select>
        </Form.Group>
        {/* <p className="p-3"><b>{feedBackUpdateMsg}</b></p> */}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={updateUser}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default WebSiteContactAction;
