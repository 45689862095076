import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";

import * as yup from "yup";

const ViewOrderDetail = (props) => {
  const { orders } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true); 

  const totalOrder = orders.reduce((acc, product) => acc + product.quantity, 0);
  const totalDelivered = orders.reduce((acc, product) => acc + product.dquantity, 0);

  return (
    <>
      <button type="button" className="btn btn-info" onClick={handleShow}>
        Order Detail
      </button>
      <Modal show={show} onHide={handleClose}>
        <div className="row px-2">
          <Row className="text-center">
            <h3 className="mb-4 mt-4">Order Details</h3>
          </Row>
          <div className="container-fluid">
            <table className="sub-tbl tbl-100">
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Order</th>
                  <th className="text-success">Delivered</th>
                </tr>
              </thead>
              <tbody>
                {!!orders &&
                  orders.map((product, index) => {
                    return (
                      <tr key={index}> 
                        <td >{product?.itemName}</td>
                        <td className="text-info-emphasis"> 
                            <b>{product?.quantity} </b>
                         
                        </td>
                        <td className="text-success"> <b> {product?.dquantity} </b> </td>
                      </tr>
                    );
                  })}
              </tbody>  
              <tfoot>
    <tr>
                  <td className="text-success"><b>Total</b></td>
                  <td><b>{totalOrder}</b></td>
                  <td className="text-success"><b>{totalDelivered}</b></td>
                </tr>
  </tfoot>
            </table>
            <div className="row">
              <Button variant="danger" onClick={handleClose} className="mt-3">
                Close
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewOrderDetail;
