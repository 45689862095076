import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCareerOpening,
  addCareerOpening,
  updateCareerOpening,
  deleteCareerOpening,
  getDepartmentDropDown,
} from "../reducers/commonReducer";
import { useNavigate } from "react-router-dom";
import { Button, Row, Col } from "react-bootstrap";
import SideMenu from "../Components/SideMenu";
import moment from "moment";
import CareerOpeningAction from "./CareerOpeningAction";

const CareerOpeningList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getCareerOpening())
    dispatch(getDepartmentDropDown())
  }, []);
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  const { careerOpeningList } =
    useSelector((state) => state.commonReducer);
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <Row className="mb-3">
        <Col>
        <CareerOpeningAction title={"Add"} api={addCareerOpening}/>
        </Col>
        </Row>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Department</th>
              <th scope="col">Designation</th>
              <th scope="col">Requirement</th>
              <th scope="col">Experience</th>
              <th scope="col">Salary</th>
              <th scope="col">Location</th>
              <th scope="col">JD</th>
              <th scope="col">JD PDF</th>
              <th scope="col">Date</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {!!careerOpeningList &&
              careerOpeningList.map((data, index) => {
                return <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{data?.department}</td>
                      <td>{data?.designation}</td>
                      <td>{data.requirement}</td>
                      <td>{data.experience}</td>
                      <td>{data.salary}</td>
                      <td>{data.location}</td>
                      <td>{data.jobdescription}</td>
                      <td>
                        <Button variant="success" 
                        onClick={() => openInNewTab(`${window.location.origin}/${data?.jdpdf}`)}>View</Button></td>
                      <td>{moment(data.updatedAt).format("DD/MM/YYYY")}</td>
                      <td>
                        {(localStorage.getItem("x-auth-token") &&
                          (localStorage.getItem("userRole") === "1" || localStorage.getItem("userRole") === "9")) && (
                            <Button
                              className="m-1"
                              variant="danger"
                              onClick={async () => {
                                await(dispatch(deleteCareerOpening(data?._id)));
                                dispatch(getCareerOpening());
                              }}
                            >
                              <i className="bi bi-trash"></i>
                            </Button>
                          )}
                       
                            <CareerOpeningAction
                              id={data?._id}
                              department={data?.department}
                              designation={data?.designation}
                              requirement={data?.requirement}
                              experience={data?.experience}
                              jdpdf={data?.jdpdf}
                              salary={data?.salary}
                              location={data?.location}
                              jobdescription={data?.jobdescription}
                              title={"Update"}
                              api={updateCareerOpening}
                              />
                      </td>
                    </tr>
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CareerOpeningList;
