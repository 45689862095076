import React, { useEffect,Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  baseUrl,
  getASMPersonsWithOutPage,
  getCatWiseSale,
  getDispatchByWithOutPage,
  getDistrictList,
  getGroupWiseSale,
  getSalesPersonWithoutPage,
  getStatesList,
  getTargetVsAchivementMonthly,
  monthWiseOrderDeliverdMap,
} from "../reducers/commonReducer";
import moment from "moment";
import SideMenu from "../Components/SideMenu";
import { Row, Col, Form, Button } from "react-bootstrap";
import { Bar, Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import {CategoryScale} from 'chart.js'; 
Chart.register(CategoryScale);

const PrimaryBillingReport = () => {
  const dispatch = useDispatch();
  const [startMonth, setStartMonth] = useState();
  const [endMonth, setEndMonth] = useState();
  const [state, setState] = useState();
  const [district, setDistrict] = useState();
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const [salesMan, setSalesMan] = useState();
  const [asm, setAsm] = useState();
  const [dispatchBy, setDispatchBy] = useState();
  const [year, setYear] = useState(moment().format("YYYY"));

  useEffect(() => {
    dispatch(getStatesList());
    dispatch(getDispatchByWithOutPage())
    dispatch(getASMPersonsWithOutPage())
    dispatch(getSalesPersonWithoutPage())
    dispatch(getCatWiseSale(
        { year: moment().format("YYYY")}
    ))
    dispatch(getGroupWiseSale({ year: moment().format("YYYY")}))
    dispatch(getTargetVsAchivementMonthly({year: moment().format("YYYY")}))
    dispatch(monthWiseOrderDeliverdMap({year: moment().format("YYYY")}))
  }, []);
  const { statesList,getCatWiseSaleList, monthWiseOrderDlvGrph, getTrgtVsAchiMonthlyList, getGroupWiseSaleList, getDistrict, dispatchByWithOutPage, asmPersonsWithOutPage, getSalesPersonWithoutPageList } = useSelector(
    (state) => state.commonReducer
  );
  const catWiseSales = Object.keys(!!getCatWiseSaleList && getCatWiseSaleList).map(key => getCatWiseSaleList[key]);
  const getGroupWiseSales = Object.keys(!!getGroupWiseSaleList && getGroupWiseSaleList).map(key => getGroupWiseSaleList[key]);
  const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Monthly Deliverd Order",
        data: !!monthWiseOrderDlvGrph &&
          Object.values(monthWiseOrderDlvGrph).map(
            function (data, key) {
             return data
            }
          ),
        fill: true,
        backgroundColor: "rgb(242 68 98 / 22%)",
        borderColor: "#f24462",
        tension: 0.5,
        borderWidth: 1.5,
        pointBorderWidth:0,
        pointRadius:0
      }
    ]
  };

const groupData = {
  labels: !!getGroupWiseSales && getGroupWiseSales?.map((data) => data?.name),
  datasets: [
    {
      label: "Group Wise Deliverd Order",
      data: !!getGroupWiseSales && getGroupWiseSales?.map((data) => data?.total),
      fill: true,
      backgroundColor: "rgb(47, 92, 143 / 22%)",
      borderColor: "#2f5c8f",
      tension: 0.5,
      borderWidth: 1.5,
      pointBorderWidth:0,
      pointRadius:0
    }
  ]
};
const catData = {
  labels: !!catWiseSales && catWiseSales?.map((data) => data?.name),
  datasets: [
    {
      label: "Category Wise Deliverd Order",
      data: !!catWiseSales && catWiseSales?.map((data) => data?.total),
      fill: true,
      backgroundColor: "rgb(253, 240, 18 / 22%)",
      borderColor: "#fdf012",
      tension: 0.5,
      borderWidth: 1.5,
      pointBorderWidth:0,
      pointRadius:0
    }
  ]
};
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <Form className="print-hidden" action={`${baseUrl}/api/targetcsv/month/report`}>
          <Row className="mb-3">
            <Col md={3} sm={12}>
              <Form.Control
                as="select"
                onChange={async (e) => {
                  setState(e.target.value);
                  e.target.value.length > 1 &&
                    dispatch(getDistrictList(e.target.value));
                }}
                name="state"
                className="form-control">
                <option value={""}>Select State</option>
                {!!statesList &&
                  statesList.map((data, index) => {
                    return (
                      <option key={index} value={data?.state}>
                        {data?.state}
                      </option>
                    );
                  })}
              </Form.Control>
            </Col>
            <Col md={3} sm={12}>
              <Form.Control
                as="select"
                onChange={async (e) => {
                  setDistrict(e.target.value);
                }}
                name="district"
                className="form-control"
              >
                <option value="">Select District</option>
                {!!getDistrict &&
                  getDistrict.map((data, index) => {
                    return (
                      <option key={index} value={data}>
                        {data}
                      </option>
                    );
                  })}
              </Form.Control>
            </Col>
            <Col md={3} sm={12}>
              <Form.Control
                as="select"
                onChange={async (e) => {
                  setStartMonth(e.target.value);
                }}
                name="startMonth"
                className="form-control"
              >
                <option value="">StartMonth</option>
                <option value="1">Jan</option>
                <option value="2">Feb</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">Aug</option>
                <option value="9">Sep</option>
                <option value="10">Oct</option>
                <option value="11">Nov</option>
                <option value="12">Dec</option>
              </Form.Control>
            </Col>
            <Col md={3} sm={12}>
              <Form.Control
                as="select"
                onChange={async (e) => {
                  setEndMonth(e.target.value);
                }}
                name="endMonth"
                className="form-control"
              >
                <option value="">EndMonth</option>
                <option value="1">Jan</option>
                <option value="2">Feb</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">Aug</option>
                <option value="9">Sep</option>
                <option value="10">Oct</option>
                <option value="11">Nov</option>
                <option value="12">Dec</option>
              </Form.Control>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col md={2} sm={12}>
              <label>Start Date</label>
              <Form.Control
                type="date"
                placeholder="Start Date"
                name="startDate"
                value={startDate}
                onChange={async (e) => {
                  setStartDate(e.target.value);
                }}
              />
            </Col>
            <Col md={2} sm={12}>
              <label>End Date</label>
              <Form.Control
                type="date"
                placeholder="End Date"
                name="endDate"
                value={endDate}
                onChange={async (e) => {
                  setEndDate(e.target.value);
                }}
              />
            </Col>
            <Col md={2} sm={12}>
              <label>Select ASM</label>
              <Form.Control
                as="select"
                onChange={async (e) => {
                  setAsm(e.target.value);
                }}
                name="asm"
                className="form-control"
              >
                <option value="">Select ASM</option>
                {!!asmPersonsWithOutPage &&
                  asmPersonsWithOutPage.map((data, index) => {
                    return (
                      <option key={index} value={data?.userName}>
                        {data?.name}
                      </option>
                    );
                  })}
              </Form.Control>
            </Col>
            <Col md={2} sm={12}>
              <label>Select SO</label>
              <Form.Control
                as="select"
                onChange={async (e) => {
                  setSalesMan(e.target.value);
                }}
                name="so"
                className="form-control"
              >
                <option value="">Select SO</option>
                {!!getSalesPersonWithoutPageList &&
                  getSalesPersonWithoutPageList.map((data, index) => {
                    return (
                      <option key={index} value={data?.userName}>
                        {data?.name}
                      </option>
                    );
                  })}
              </Form.Control>
            </Col>
            <Col md={2} sm={12}>
              <label>Select DispatchBy</label>
              <Form.Control
                as="select"
                onChange={async (e) => {
                    setDispatchBy(e.target.value);
                }}
                name="dispatch"
                className="form-control"
              >
                <option value="">Select DispatchBy</option>
                {!!dispatchByWithOutPage &&
                  dispatchByWithOutPage.map((data, index) => {
                    return (
                      <option key={index} value={data?._id}>
                        {data?.dispatch}
                      </option>
                    );
                  })}
              </Form.Control>
            </Col>
            <Col md={1} sm={12} className="mt-4">
              <Form.Control
                type="text"
                placeholder="Year"
                name="year"
                value={year}
                onChange={async (e) => {
                  setYear(e.target.value);
                }}
              />
            </Col>
            <Col md="1" className="mt-4">
              <Button
                variant="success"
                onClick={() => {
                  dispatch(monthWiseOrderDeliverdMap({
                    startMonth: startMonth,
                    endMonth: endMonth,
                    year: year,
                    state: state,
                    district: district,
                    startDate,
                    endDate,
                    salesMan,
                    asm,
                    dispatchBy
                  }))
                  dispatch(getCatWiseSale({
                    startMonth: startMonth,
                      endMonth: endMonth,
                      year: year,
                      state: state,
                      district: district,
                      startDate,
                      endDate,
                      salesMan,
                      asm,
                      dispatchBy
                  }))
                  dispatch(
                    getGroupWiseSale({
                      startMonth: startMonth,
                      endMonth: endMonth,
                      year: year,
                      state: state,
                      district: district,
                      startDate,
                      endDate,
                      salesMan,
                      asm,
                      dispatchBy
                    })
                  );
                  dispatch(
                    getTargetVsAchivementMonthly({
                      startMonth: startMonth,
                      endMonth: endMonth,
                      year: year,
                      state: state,
                      district: district,
                      startDate,
                      endDate,
                      salesMan,
                      asm,
                      dispatchBy
                    })
                  )
                }}
              >
                Submit
              </Button>
            </Col>
            <Col md="1" className="mt-4">
              <Button
                variant="danger"
                onClick={() => window.location.reload(false)}
              >
                Clear
              </Button>
            </Col>
            <Col md={2} className="mt-4">
              <Button variant="warning" type="submit">
                Export Report
              </Button>
            </Col>
          </Row>
        </Form>
        <Row className="mt-3">
          <Col md={6}>
            <h4>
              <b>
             Primary Billing Report
              </b>
            </h4>
            <p className="mb-0">
              <b>Year: </b> {year}
            </p>
          </Col>
          <Col md={6} className="text-align-end">
            <Button onClick={() => window.print()}>
              <i className="bi bi-printer-fill"></i>
            </Button>
          </Col>
        </Row>
        <Row>
            <Col md={8}>
              <Line data={data} />
            </Col>
            <Col md={4}>
                <table className="w-100">
                    <thead>
                        <tr className="bg-danger text-white">
                        <th scope="col">Sr.</th>
                        <th scope="col">Category Sales</th>
                        <th scope="col">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {catWiseSales.map((item, index) => (
                            <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.name === undefined ? "Other" : item?.name}</td>
                            <td>{item.total}</td>
                            </tr>
                        ))}
                    </tbody>
                  </table>
                  <table className="w-100 mt-5">
                    <thead>
                        <tr className="bg-info text-white">
                        <th scope="col">Sr.</th>
                        <th scope="col">Group Wise Sales</th>
                        <th scope="col">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getGroupWiseSales.map((item, index) => (
                            <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.name === undefined ? "Other" : item?.name}</td>
                            <td>{item.total}</td>
                            </tr>
                        ))}
                    </tbody>
                  </table>
            </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Bar data={groupData} />
          </Col>
          <Col md={6}>
            <Bar data={catData} />
          </Col>
        </Row>
        <Row>
        <table className="table table-scrolling mt-4">
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    {!!getTrgtVsAchiMonthlyList &&
                      !!getTrgtVsAchiMonthlyList?.selectMonth &&
                      Object.entries(getTrgtVsAchiMonthlyList?.selectMonth).map(
                        ([data]) => (
                        <th colSpan="2" className="text-center">{data}</th>
                        ))}
                    <th colSpan="2" className="text-center">CB</th>
                  </tr>
                  <tr>
                    <th scope="col">Sr</th>
                    <th scope="col">Party Name</th>
                    <th scope="col">Address</th>
                    {!!getTrgtVsAchiMonthlyList &&
                      !!getTrgtVsAchiMonthlyList?.selectMonth &&
                      Object.entries(getTrgtVsAchiMonthlyList?.selectMonth).map(
                        ([data]) => (
                          <>
                          {
                          data === "Jan" && <>
                          <th scope="col">Target</th>
                          <th scope="col">Achive</th>
                          </> || data === "Feb" && <>
                          <th scope="col">Target</th>
                          <th scope="col">Achive</th>
                          </> || data === "Mar" && <>
                          <th scope="col">Target</th>
                          <th scope="col">Achive</th>
                          </> || data === "Apr" && <>
                          <th scope="col">Target</th>
                          <th scope="col">Achive</th>
                          </> || data === "May" && <>
                          <th scope="col">Target</th>
                          <th scope="col">Achive</th>
                          </> || data === "June" && <>
                          <th scope="col">Target</th>
                          <th scope="col">Achive</th>
                          </> || data === "July" && <>
                          <th scope="col">Target</th>
                          <th scope="col">Achive</th>
                          </> || data === "Aug" && <>
                          <th scope="col">Target</th>
                          <th scope="col">Achive</th>
                          </> || data === "Sept" && <>
                          <th scope="col">Target</th>
                          <th scope="col">Achive</th>
                          </> || data === "Oct" && <>
                          <th scope="col">Target</th>
                          <th scope="col">Achive</th>
                          </> || data === "Nov" && <>
                          <th scope="col">Target</th>
                          <th scope="col">Achive</th>
                          </> || data === "Dec" && <>
                          <th scope="col">Target</th>
                          <th scope="col">Achive</th>
                          </>
                          }
                          </>
                        )
                      )}
                    <th scope="col">Target</th>
                    <th scope="col">Achive</th>
                  </tr>
                </thead>
                <tbody>
                  {!!getTrgtVsAchiMonthlyList &&
                    !!getTrgtVsAchiMonthlyList?.monthOrder &&
                    Object.values(getTrgtVsAchiMonthlyList?.monthOrder).map(
                      (data, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}.</td>
                            <td>{data?.firmName}</td>
                            <td>{`${data?.address?.town}, ${data?.address?.district}, ${data?.address?.state}`}</td>
                            {!!getTrgtVsAchiMonthlyList?.selectMonth &&
                              Object.keys(getTrgtVsAchiMonthlyList?.selectMonth).map(
                                function (key) {
                                  return (
                                      <>
                                        <td>{data[key]?.Target}</td>
                                        <td className={`text-white ${data[key]?.Target > data[key]?.Achievement && `bg-danger` || data[key]?.Target < data[key]?.Achievement && `bg-success`}`}>{data[key]?.Achievement}</td>
                                      </>
                                  );
                                }
                              )}
                            <td>
                              {data?.cbTotal?.Target}
                            </td>
                            <td className={`text-white ${data?.cbTotal?.Target > data?.cbTotal?.Achievement && `bg-danger` || data?.cbTotal?.Target < data?.cbTotal?.Achievement && `bg-success`}`}>
                              {data?.cbTotal?.Achievement}
                            </td>
                          </tr>
                        );
                      }
                    )}
                  <tr>
                    <td colSpan="3" className="text-center">
                      <b>Total</b>
                    </td>
                    {!!getTrgtVsAchiMonthlyList &&
                      !!getTrgtVsAchiMonthlyList?.total &&
                      Object.keys(getTrgtVsAchiMonthlyList?.selectMonth).map(function (key) {
                        return (
                          <>
                           <td>
                              <b>{getTrgtVsAchiMonthlyList?.total[key]?.Target}</b>
                            </td>
                          <td className={`text-white ${getTrgtVsAchiMonthlyList?.total[key]?.Target > getTrgtVsAchiMonthlyList?.total[key]?.Achievement && `bg-danger` || getTrgtVsAchiMonthlyList?.total[key]?.Target < getTrgtVsAchiMonthlyList?.total[key]?.Achievement && `bg-success`}`}>
                              <b>{getTrgtVsAchiMonthlyList?.total[key]?.Achievement}</b>
                            </td>
                          </>
                        );
                      })}
                    <td>
                      <b>
                        {!!getTrgtVsAchiMonthlyList &&
                          getTrgtVsAchiMonthlyList?.total?.mainTotel?.Target}
                      </b>
                    </td>
                    <td className={`text-white ${getTrgtVsAchiMonthlyList?.total?.mainTotel?.Target > getTrgtVsAchiMonthlyList?.total?.mainTotel?.Achievement && `bg-danger` || getTrgtVsAchiMonthlyList?.total?.mainTotel?.Target < getTrgtVsAchiMonthlyList?.total?.mainTotel?.Achievement && `bg-success`}`}>
                      <b>
                        {!!getTrgtVsAchiMonthlyList &&
                          getTrgtVsAchiMonthlyList?.total?.mainTotel?.Achievement}
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
        </Row>
      </div>
    </>
  );
};

export default PrimaryBillingReport;
