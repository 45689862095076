import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getContactList,
  deletContactRecord,
  getNewsLetterList,
  newsLetterDelete,
} from "../reducers/commonReducer";
import { useNavigate } from "react-router-dom";
import { Button} from "react-bootstrap";
import SideMenu from "../Components/SideMenu";
import moment from "moment";

const NewsletterList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getNewsLetterList())
  }, []);
  const { getNewsLetterData } =
    useSelector((state) => state.commonReducer);
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Email</th>
              <th scope="col">Date</th>
              {(localStorage.getItem("x-auth-token") &&
                (localStorage.getItem("userRole") === "3" || localStorage.getItem("userRole") === "1")) && <th scope="col">Action</th>}
            </tr>
          </thead>
          <tbody>
            {!!getNewsLetterData &&
              getNewsLetterData.map((data, index) => {
                return <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{data?.email}</td>
                      <td>{moment(data.createdAt).format("DD/MM/YYYY")}</td>
                      <td>
                        {(localStorage.getItem("x-auth-token") &&
                          (localStorage.getItem("userRole") === "1" || localStorage.getItem("userRole") === "9")) && (
                            <Button
                              className="m-1"
                              variant="danger"
                              onClick={async () => {
                                await(dispatch(newsLetterDelete(data?._id)));
                                dispatch(getNewsLetterList());
                              }}
                            >
                              <i className="bi bi-trash"></i>
                            </Button>
                          )}
                      </td>
                    </tr>
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default NewsletterList;
