import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import Row from "react-bootstrap/Row";
import { Formik } from "formik";
import * as yup from "yup";
import { getDepartments} from "../reducers/commonReducer";
import Modal from 'react-bootstrap/Modal';
import TagsInput from "../TagsInput";

const schema = yup.object().shape({
    name: yup.string().required(),
    status: yup.string().required(),
});

function DepartmentAction(props) {
  const {id, name, status, title, reqQuestions, api} = props;
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [tagValues, setTagValues] = useState(reqQuestions ? reqQuestions : [])
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
     <Button variant="primary" onClick={handleShow}>
        {title}
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title} Department</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <div className="container-fluid">
                  <Formik
                    validationSchema={schema}
                    onSubmit={async (values, { resetForm }) => {
                     await dispatch(api({name:values?.name, status : values?.status, id : values?.id, reqQuestions : tagValues}))
                     await dispatch(getDepartments())
                     handleClose();
                      resetForm({ values: "" });
                    }}
                    initialValues={{
                    id,
                    name,
                    reqQuestions,
                    status,
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      values,
                      errors,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Row className="mb-3">
                          <Col md={12} sm={12}>
                            <Form.Group >
                              <Form.Label>Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Name"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                isInvalid={!!errors.name}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.name}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md={12}>
                          <Form.Label>Enter Questions (Enter Like:- Min Exp 5years?)</Form.Label>
                          <TagsInput tagValues={tagValues} setTagValues={setTagValues} values={reqQuestions}/>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                        <Col md={12} sm={12}>
                            <Form.Label>Status</Form.Label>
                            <br />
                            <Form.Control
                              as="select"
                              onChange={handleChange}
                              name="status"
                              className="form-control"
                              value={values.status}
                              isInvalid={!!errors.status}
                            >
                              <option value="">Select Status</option>
                              <option value={true}>Active</option>
                              <option value={false}>Inactive</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              {errors.status}
                            </Form.Control.Feedback>
                          </Col>
                        </Row>
                        <Button type="submit">Submit</Button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Modal.Body>
              </Modal>
    </>
  );
}

export default DepartmentAction;
