import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as yup from "yup";
import { closingStock, getClosingStock } from "../reducers/commonReducer";

const schema = yup.object().shape({
  month: yup.string().required(),
  year: yup.number().required(),
  superDistributor: yup.string().required(),
  distributer: yup.string().required(),
  product: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      value: yup.string(),
    })
  ),
});

const ClosingStock = (props) => {
  const dispatch = useDispatch();
  const { getProductsList, selectedDistributorWithoutPage } = useSelector(
    (state) => state.commonReducer
  );
  const {
    btnTitle,
    title,
    superId,
    btnClass,
    eProducts,
    apiUrl,
    eMonth,
    eSuper,
    eYear,
    superid,
    edistributer,
    id,
  } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dateMonth = new Date();
  let getMonth = dateMonth.getMonth();
  let Products = {};
  !!eProducts &&
    eProducts.map((data) => {
      Products[data?.id?._id] = data?.value;
    });
  return (
    <>
      <Button variant={btnClass ? btnClass : "success"} onClick={handleShow}>
        {btnTitle}
      </Button>
      <Modal show={show} onHide={handleClose} size="lg">
        <div className="row px-2">
          <Row className="text-center">
            <h3 className="mb-4 mt-4">{title}</h3>
          </Row>
          <div className="container-fluid pb-3">
            <Formik
              validationSchema={schema}
              onSubmit={async (values, { resetForm }) => {
                await dispatch(apiUrl ? apiUrl(values) : closingStock(values));
                resetForm({ values: "" });
                dispatch(getClosingStock({ superstockistid: superid }));
                handleClose();
              }}
              initialValues={{
                id: id,
                superstockistid: superId,
                distributer: edistributer ? edistributer : '',
                month: eMonth ? eMonth : getMonth,
                year: eYear ? eYear : '',
                superDistributor: eSuper ? eSuper : "",
                product:
                  !!getProductsList &&
                  getProductsList.map((data) => ({
                    id: data?._id,
                    value: Products[data?._id] ? Products[data?._id] : 0,
                  })),
              }}
            >
              {({ handleSubmit, handleChange, values, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  {
                    !id && <Row>
                    <Col md={2}>
                      <Form.Group>
                        <label className="text-danger">Select Month</label>
                        <Form.Control
                          as="select"
                          onChange={handleChange}
                          name="month"
                          className="form-control"
                          value={values.month}
                          isInvalid={!!errors.month}
                        >
                          <option value={1}>Jan</option>
                          <option value={2}>Feb</option>
                          <option value={3}>March</option>
                          <option value={4}>April</option>
                          <option value={5}>May</option>
                          <option value={6}>June</option>
                          <option value={7}>July</option>
                          <option value={8}>Aug</option>
                          <option value={9}>Sept</option>
                          <option value={10}>Oct</option>
                          <option value={11}>Nov</option>
                          <option value={12}>Dec</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={2}>
                              <Form.Group>
                              <label className="text-danger">Year</label>
                                <Form.Control
                                  type="number"
                                  placeholder="Year"
                                  name={`year`}
                                  value={values.year}
                                  onChange={handleChange}
                                  isInvalid={!!errors.year}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.year}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                    <Col md={4}>
                      <Form.Group>
                        <label className="text-danger">Select Super</label>
                        <Form.Control
                          as="select"
                          onChange={handleChange}
                          name="superDistributor"
                          className="form-control"
                          value={values.superDistributor}
                          isInvalid={!!errors.superDistributor}
                        >
                          <option>Select Super Stockist</option>
                          {!!selectedDistributorWithoutPage &&
                            selectedDistributorWithoutPage?.map(
                              (data, index) =>
                                data?.firmName?.match(/SUPER/) && (
                                  <option value={data?.userName} key={index}>
                                    {data?.firmName}
                                  </option>
                                )
                            )}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <label className="text-danger">Select Local Sale</label>
                        <Form.Control
                          as="select"
                          onChange={handleChange}
                          name="distributer"
                          className="form-control"
                          value={values.distributer}
                          isInvalid={!!errors.distributer}
                        >
                          <option>Select Local Sale</option>
                          {!!selectedDistributorWithoutPage &&
                            selectedDistributorWithoutPage?.map(
                              (data, index) =>
                                data?.firmName?.match(/Local/) && (
                                  <option value={data?.userName} key={index}>
                                    {data?.firmName}
                                  </option>
                                )
                            )}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  }
                  <Row className="mb-3">
                    {!!getProductsList &&
                      getProductsList.map((data, index) => {
                        return (
                          <Row className="mt-3" key={index}>
                            <Col md={6} sm={6}>
                              {data?.itemName}
                            </Col>
                            <Col md={6} sm={6}>
                              <Form.Group>
                                <Form.Control
                                  type="number"
                                  placeholder="Product Quantity in Box"
                                  name={`product.${index}.value`}
                                  value={values?.product?.[index]?.value || ""}
                                  onChange={handleChange}
                                  isInvalid={!!errors.order}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.order}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                        );
                      })}
                  </Row>
                  <Button type="submit" className="mt-3">
                    Submit
                  </Button>
                  <Button
                    variant="danger"
                    onClick={handleClose}
                    className="mt-3"
                  >
                    Close
                  </Button>
                </Form>
              )}
            </Formik>
            {/* <p>
              <b>{distributorAddMsg}</b>
            </p> */}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ClosingStock;
