import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFeedBackList,
  getStatesList,
  getDistrictList,
  deleteFeedbackRecord,
  getOrderListRest,
} from "../reducers/commonReducer";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Button } from "react-bootstrap";
import SideMenu from "../Components/SideMenu";
import moment from "moment";
import FeedBackAction from "../Components/FeedBackAction";
import ExportData from "../Components/ExportData";

const FeedBackList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [states, setStates] = useState();
  const [page, setPage] = useState(1);
  const [district, setDistrict] = useState();
  const [userStatus, setUserStatus] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const { userfeedBackList, statesList, userfeedBackListBlank, getDistrict } =
    useSelector((state) => state.commonReducer);
  const header = [
    "Sr",
    "Name",
    "ContactNo",
    "State",
    "District",
    "town",
    "UserComment",
    "AdminComment",
    "Status",
  ];
  let body = [];
  !!userfeedBackList &&
    userfeedBackList.map((data, index) => {
      body.push({
        Sr: index + 1,
        Name: data.name,
        ContactNo: data?.number,
        State: data?.address?.state,
        District: data?.address?.district,
        town: data?.address?.town,
        UserComment: data?.comment,
        AdminComment: data?.adminComment,
        Status: data?.status,
      });
    });
  useEffect(() => {
    dispatch(getOrderListRest());
    dispatch(getFeedBackList());
    dispatch(getStatesList());
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <Row className="mb-3">
          <Col md={2} sm={12}>
            <Form.Control
              style={{ textTransform: "capitalize" }}
              as="select"
              value={states}
              onChange={async (e) => {
                setPage(1);
                dispatch(getOrderListRest());
                setStates(e.target.value);
                dispatch(
                  getFeedBackList({
                    state: e.target.value,
                    district,
                    userStatus,
                    startDate,
                    endDate,
                  })
                );
                dispatch(getDistrictList(e.target.value));
              }}
              name="state"
              className="form-control"
            >
              <option value="">Select State</option>
              {statesList.map((data, index) => {
                return <option
                      className="first-latter-capital"
                      key={index}
                      value={data.state}
                    >
                      {data.state}
                    </option>
              })}
            </Form.Control>
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              style={{ textTransform: "capitalize" }}
              as="select"
              value={district}
              onChange={async (e) => {
                setPage(1);
                dispatch(getOrderListRest());
                setDistrict(e.target.value);
                dispatch(
                  getFeedBackList({
                    state: states,
                    district: e.target.value,
                    userStatus,
                    startDate,
                    endDate,
                  })
                );
              }}
              name="district"
              className="form-control"
            >
              <option value="">Select District</option>
              {getDistrict.map((data, index) => {
                return <option
                      className="first-latter-capital"
                      key={index}
                      value={data}
                    >
                      {data}
                    </option>
              })}
            </Form.Control>
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              style={{ textTransform: "capitalize" }}
              as="select"
              onChange={async (e) => {
                setPage(1);
                dispatch(getOrderListRest());
                setUserStatus(e.target.value);
                dispatch(
                  getFeedBackList({
                    state: states,
                    district,
                    userStatus: e.target.value,
                    startDate,
                    endDate,
                  })
                );
              }}
              name="userStatus"
              className="form-control"
            >
              <option value="">Select Status</option>
              <option value="Pending">Pending</option>
              <option value="Read">Read</option>
            </Form.Control>
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              type="date"
              placeholder="Start Date"
              name="endDate"
              onChange={async (e) => {
                setPage(1);
                dispatch(getOrderListRest());
                setStartDate(e.target.value);
                dispatch(
                  getFeedBackList({
                    state: states,
                    district,
                    userStatus,
                    startDate: e.target.value,
                    endDate,
                  })
                );
              }}
              value={startDate}
            />
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              type="date"
              placeholder="End Date"
              name="startDate"
              onChange={async (e) => {
                setPage(1);
                dispatch(getOrderListRest());
                setEndDate(e.target.value);
                dispatch(
                  getFeedBackList({
                    state: states,
                    district,
                    userStatus,
                    startDate,
                    endDate: e.target.value,
                  })
                );
              }}
              value={endDate}
            />
          </Col>
          <Col md={2} sm={12} className="mbt-phone">
            <ExportData title="Export FeedBack" header={header} body={body} />
          </Col>
        </Row>
        <table className="table table-scrolling">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Name</th>
              <th scope="col">Firm Name</th>
              <th scope="col">Contact No.</th>
              <th scope="col">Date</th>
              <th scope="col">Address</th>
              <th scope="col">User Comment</th>
              <th scope="col">Comment</th>
              <th scope="col">Status</th>
              {(localStorage.getItem("x-auth-token") &&
                localStorage.getItem("userRole") === "3") ||
                (1 && <th scope="col">Action</th>)}
            </tr>
          </thead>
          <tbody>
            {!!userfeedBackList &&
              userfeedBackList.map((data, index) => {
                return <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{data.name}</td>
                      <td>{data.firmName}</td>
                      <td>{data.number}</td>
                      <td>{moment(data.createdAt).format("DD/MM/YYYY")}</td>
                      <td>
                        {data.address?.town +
                          "," +
                          data.address?.district +
                          "," +
                          data.address?.state}
                      </td>
                      <td>{data.comment}</td>
                      <td>{data.adminComment}</td>
                      <td>
                        <span
                          className={
                            (data.status == "Pending" && "text-primary") ||
                            (data.status == "Read" && "text-success")
                          }
                        >
                          <b>{data.status}</b>
                        </span>
                      </td>
                      <td>
                        {localStorage.getItem("x-auth-token") &&
                          localStorage.getItem("userRole") === "1" && (
                            <Button
                              className="m-1"
                              variant="danger"
                              onClick={async () => {
                                await dispatch(deleteFeedbackRecord(data?._id));
                                dispatch(getFeedBackList());
                              }}
                            >
                              <i className="bi bi-trash"></i>
                            </Button>
                          )}
                        {localStorage.getItem("x-auth-token") &&
                          (localStorage.getItem("userRole") === "3" ||
                            localStorage.getItem("userRole") === "1" || localStorage.getItem("userRole") === "9") && (
                            <FeedBackAction
                              adminCmnt={data?.adminComment}
                              userStatus={data?.status}
                              id={data?._id}
                            />
                          )}
                      </td>
                    </tr>
              })}
          </tbody>
        </table>
        {!!userfeedBackListBlank && userfeedBackListBlank.length > 0 ? (
          <Row>
            <Button
              onClick={() => {
                setPage(page + 1);
                dispatch(
                  getFeedBackList({
                    state: states,
                    district,
                    userStatus,
                    startDate,
                    endDate,
                    page: page + 1,
                  })
                );
              }}
            >
              Load More
            </Button>
          </Row>
        ) : (
          <p className="text-center">
            <b>No record found 😔.</b>
          </p>
        )}
      </div>
    </>
  );
};

export default FeedBackList;
