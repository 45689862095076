import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as yup from "yup";
import {
  getStatesList,
  getDistrictList,
  createASMPerson,
  getASMPersonsList,
  getOrderListRest,
} from "../reducers/commonReducer";

const schema = yup.object().shape({
  name: yup.string().required(),
  number: yup.string().min(10).max(10).required(),
  userName: yup.string().required(),
  password: yup.string().required(),
  town: yup.string().required(),
  district: yup.string().required(),
  state: yup.string().required(),
});

const ASMCreate = (props) => {
  const dispatch = useDispatch();
  const timeoutIdRef = React.useRef();
  const { statesList, getProductsList, getDistrict } = useSelector(
    (state) => state.commonReducer
  );

  useEffect(() => {
    // dispatch(getDispatchBy());
    dispatch(getStatesList());
    // dispatch(getSelectedDistributor());
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <Button onClick={handleShow}>Create ASM</Button>
      <Modal size="lg" show={show} onHide={handleClose}>
        <div className="row px-2">
          <Row className="text-center">
            <h3 className="mb-4 mt-4">Add ASM</h3>
          </Row>
          <div className="container-fluid">
            <Formik
              validationSchema={schema}
              onSubmit={async (values, { resetForm }) => {
                dispatch(createASMPerson(values));
                resetForm({ values: "" });
                setShow(false);
                dispatch(getOrderListRest());
                clearTimeout(timeoutIdRef.current);
                timeoutIdRef.current = setTimeout(() => {
                  dispatch(getASMPersonsList());
                }, 1000);
              }}
              initialValues={{
                name: "",
                number: "",
                userName: "",
                town: "",
                district: "",
                state: "",
                password: "",
                shyamg: [],
                waahJi: [],
              }}
            >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                values,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit} className="pb-2">
                  <Row className="mb-3">
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          isInvalid={!!errors.name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label>UserName</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="User Name"
                          name="userName"
                          value={values.userName
                            .toLowerCase()
                            .replace(/ /g, "-")}
                          onChange={handleChange}
                          isInvalid={!!errors.userName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.userName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label>Mobile Number</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Mobile Number"
                          name="number"
                          value={values.number}
                          onChange={handleChange}
                          isInvalid={!!errors.number}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.number}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label>Town</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Town"
                          name="town"
                          value={values.town}
                          onChange={handleChange}
                          isInvalid={!!errors.town}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.town}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={6} sm={12}>
                      <Form.Label>State</Form.Label>
                      <br />
                      <Form.Control
                        style={{ textTransform: "capitalize" }}
                        as="select"
                        onChange={async (e) => {
                          await setFieldValue("state", e.target.value);
                          dispatch(getDistrictList(e.target.value));
                        }}
                        name="state"
                        className="form-control"
                        value={values.state}
                        isInvalid={!!errors.state}
                      >
                        <option value="">Select State</option>
                        {statesList.map((data, index) => {
                          return (
                            <option
                              className="first-latter-capital"
                              key={index}
                              value={data.state}
                            >
                              {data.state}
                            </option>
                          );
                        })}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.state}
                      </Form.Control.Feedback>
                    </Col>
                    <Col md={6} sm={12}>
                      <Form.Label>District</Form.Label>
                      <br />
                      <Form.Control
                        style={{ textTransform: "capitalize" }}
                        as="select"
                        name="district"
                        onChange={handleChange}
                        value={values.district}
                        className="form-control"
                        isInvalid={!!errors.district}
                      >
                        <option value="">Select District</option>
                        {!!getDistrict &&
                          getDistrict.map((data, index) => {
                            return (
                              <option
                                className="first-latter-capital"
                                value={data}
                                key={index}
                              >
                                {data}
                              </option>
                            );
                          })}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.district}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} sm={12}>
                      <Form.Label>Select ShyamG Products</Form.Label>
                      <br />
                      <Form.Control
                        as="select"
                        multiple={true}
                        name="shyamg"
                        onChange={handleChange}
                        className="form-control"
                      >
                        {!!getProductsList &&
                          getProductsList.map((data, index) => {
                            return (
                              !data?.itemName.match("WaahJi") && (
                                <option
                                  className="first-latter-capital"
                                  key={index}
                                  value={data.id ? data.id : data._id}
                                >
                                  {data?.itemName}
                                </option>
                              )
                            );
                          })}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.products}
                      </Form.Control.Feedback>
                    </Col>
                    <Col md={6} sm={12}>
                      <Form.Label>Select WaahJi Products</Form.Label>
                      <br />
                      <Form.Control
                        as="select"
                        multiple={true}
                        name="waahJi"
                        onChange={handleChange}
                        className="form-control"
                      >
                        {!!getProductsList &&
                          getProductsList.map((data, index) => {
                            return (
                              data?.itemName.match("WaahJi") && (
                                <option
                                  className="first-latter-capital"
                                  key={index}
                                  value={data.id ? data.id : data._id}
                                >
                                  {data?.itemName}
                                </option>
                              )
                            );
                          })}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.products}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          isInvalid={!!errors.password}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button type="submit">Submit</Button>
                  <Button onClick={handleClose} variant="danger">
                    Close
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ASMCreate;
