import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDispatchBy,
  getOrderListRest,
  getDistrictList,
  getStatesList,
  getSalesPersonWithoutPage,
  getSelectedDistributorWithoutPage,
  getProducts,
} from "../reducers/commonReducer";
import { Link } from "react-router-dom";
import moment from "moment";
import SideMenu from "../Components/SideMenu";
import { Row, Col, Form, Button } from "react-bootstrap";
import ExportData from "../Components/ExportData";
import AddSuperStockists from "../Components/AddSuperStockists";
import SuperStockistsUpdate from "../Components/SuperStockistsUpdate";
import ClosingStock from "../Components/ClosingStock";

const SuperStockists = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState();
  const [page, setPage] = useState(1);
  const [district, setDistrict] = useState();
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [startDate, setStartDate] = useState();
  const { getDistrict, getDispatchByListMsg, statesList, getDispatchByList } =
    useSelector((state) => state.commonReducer);

  const dateMonth = new Date();
  let getMonth = dateMonth.getMonth();

  const header = ["Sr", "Firm Name", "OwnerName", "Address", "Date"];
  let body = [];
  getDispatchByList.map((data, index) => {
    body.push({
      Sr: index + 1,
      FirmName: data.dispatch,
      OwnerName: data?.ownerName,
      Address:
        data?.address?.town +
        ", " +
        data?.address?.district +
        ", " +
        data?.address?.state,
      Date: moment(data.createdAt).format("DD/MM/YYYY"),
    });
  });
  useEffect(() => {
    dispatch(getOrderListRest());
    dispatch(getDispatchBy());
    dispatch(getStatesList());
    dispatch(getSalesPersonWithoutPage());
    dispatch(getProducts());
    dispatch(getSelectedDistributorWithoutPage());
  }, []);
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <Row className="mb-3">
          <Col md={2} sm={12}>
            <AddSuperStockists />
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setPage(1);
                setState(e.target.value);
                dispatch(getOrderListRest());
                dispatch(
                  getDispatchBy({
                    state: e.target.value,
                    startDate,
                    endDate,
                  })
                );
                e.target.value.length > 1 &&
                  dispatch(getDistrictList(e.target.value));
              }}
              name="state"
              className="form-control"
            >
              <option value="">Select State</option>
              {!!statesList &&
                statesList.map((data, index) => {
                  return (
                    <option key={index} value={data?.state}>
                      {data?.state}
                    </option>
                  );
                })}
            </Form.Control>
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setPage(1);
                setDistrict(e.target.value);
                dispatch(getOrderListRest());
                dispatch(
                  getDispatchBy({
                    state,
                    district: e.target.value,
                    startDate,
                    endDate,
                  })
                );
              }}
              name="state"
              className="form-control"
            >
              <option value="">Select District</option>
              {!!getDistrict &&
                getDistrict.map((data, index) => {
                  return (
                    <option key={index} value={data}>
                      {data}
                    </option>
                  );
                })}
            </Form.Control>
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              type="date"
              placeholder="Start Date"
              name="endDate"
              onChange={async (e) => {
                setPage(1);
                setStartDate(e.target.value);
                dispatch(getOrderListRest());
                dispatch(
                  getDispatchBy({
                    state,
                    district,
                    startDate: e.target.value,
                    endDate,
                  })
                );
              }}
              value={startDate}
            />
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              type="date"
              placeholder="End Date"
              name="startDate"
              onChange={async (e) => {
                setPage(1);
                setEndDate(e.target.value);
                dispatch(getOrderListRest());
                dispatch(
                  getDispatchBy({
                    state,
                    district,
                    startDate,
                    endDate: e.target.value,
                  })
                );
              }}
              value={endDate}
            />
          </Col>
          <Col md={2} sm={12} className="mbt-phone">
            <ExportData
              title="Export Super Stockist"
              header={header}
              body={body}
              tableName="ShyamG Super Stockist"
            />
          </Col>
        </Row>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">DispatchBy</th>
              <th scope="col">Order</th>
              <th scope="col">View SalesMan</th>
              <th scope="col">OwnerName</th>
              <th scope="col">UserName</th>
              <th scope="col">Address</th>
              <th scope="col">Status</th>
              <th scope="col">Date</th>
              {localStorage.getItem("x-auth-token") &&
                (localStorage.getItem("userRole") == 1 ||
                  localStorage.getItem("userRole") == 11) && (
                  <>
                    <th scope="col">Action</th>
                    <th scope="col">Stock Status</th>
                    <th scope="col">Stock</th>
                  </>
                )}
            </tr>
          </thead>
          <tbody>
            {!!getDispatchByList &&
              getDispatchByList.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>{data?.dispatch}</td>
                    <td>
                      {data?.other != 0 && (
                        <Link to={`/company-wise-orders/${data?._id}`}>
                          <Button variant="primary" className="m-1">
                            Pending Order({data?.other})
                          </Button>
                        </Link>
                      )}
                      {data?.deliver != 0 && (
                        <Link
                          to={`/company-delivered-orders/${data?._id}`}
                          className="f-right"
                        >
                          <Button variant="success" className="m-1">
                            Delivered Order({data?.deliver})
                          </Button>
                        </Link>
                      )}
                    </td>
                    <td>
                      <Link
                        to={`/super-stockists-wise-salesman/${data?.userName}`}
                      >
                        <Button variant="warning">View SalesMan</Button>
                      </Link>
                    </td>
                    <td>{data?.ownerName}</td>
                    <td>{data?.userName}</td>
                    <td>
                      {data?.address?.town +
                        ", " +
                        data?.address?.district +
                        ", " +
                        data?.address?.state}
                    </td>
                    {data?.status ? (
                      <td className="text-success">
                        {data?.status && "Active"}
                      </td>
                    ) : (
                      <td className="text-danger">
                        {data?.status === false && "Inactive"}
                      </td>
                    )}
                    <td>{moment(data.createdAt).format("DD/MM/YYYY")}</td>
                    {localStorage.getItem("x-auth-token") &&
                      (localStorage.getItem("userRole") == 1 ||
                        localStorage.getItem("userRole") == 11) && (
                        <>
                          <td>
                            <SuperStockistsUpdate
                              id={data._id}
                              distributorList={data?.distributer}
                              soList={data?.soList}
                              name={data?.ownerName}
                              userStatus={data?.status}
                              userAssignproducts={data?.allproducts}
                              district={district}
                              state={state}
                              endDate={endDate}
                              startDate={startDate}
                            />
                          </td>
                          <td>
                            {data?.closingStockMonth === getMonth + 1 ? (
                              <span className="text-success">
                                <b>Done</b>
                              </span>
                            ) : (
                              <span className="text-danger">
                                <b>Pending</b>
                              </span>
                            )}
                          </td>
                          <td>
                            {
                            //  (( moment().startOf("month").format("YYYY-MM-DD") ===
                            //     moment(new Date()).format("YYYY-MM-DD")) &&
                              <ClosingStock
                                btnTitle={"Closing Stock"}
                                title={"Add Closing Stock"}
                                superId={data?._id}
                              />
                              // )
                            }

                            <Link
                              to={`/super-stockist-closing-stock?superId=${data?._id}`}
                            >
                              <Button variant="warning">View Stock</Button>
                            </Link>
                          </td>
                        </>
                      )}
                  </tr>
                );
              })}
          </tbody>
        </table>
        {!!getDispatchByListMsg && getDispatchByListMsg.length > 0 ? (
          <Row>
            <Button
              onClick={() => {
                setPage(page + 1);
                dispatch(
                  getDispatchBy({
                    page: page + 1,
                    startDate: startDate,
                    endDate: endDate,
                    district: district,
                    state: state,
                  })
                );
              }}
            >
              Load More
            </Button>
          </Row>
        ) : (
          <p className="text-center">
            <b>No record found 😔.</b>
          </p>
        )}
      </div>
    </>
  );
};

export default SuperStockists;
