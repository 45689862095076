import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  baseUrl,
  getDispatchbyMonthlyReports,
  getDispatchbyOrderMonthlyReports,
  getDispatchByWithOutPage,
  getOrderListRest,
  getStatesList,
} from "../reducers/commonReducer";
import moment from "moment";
import SideMenu from "../Components/SideMenu";
import { Row, Col, Form, Button } from "react-bootstrap";
import { commonFunction } from "./commonFunction/commonFunction";

const SuperStockistsOrderMonthlyReport = () => {
  const dispatch = useDispatch();
  const [startMonth, setStartMonth] = useState();
  const [endMonth, setEndMonth] = useState();
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const [year, setYear] = useState(moment().format("YYYY"));

  const { getDispatchbyOrderMonthlyReportsList } = useSelector(
    (state) => state.commonReducer
  );
  useEffect(() => {
    dispatch(getOrderListRest());
    dispatch(getStatesList());
    dispatch(
      getDispatchbyOrderMonthlyReports({
        year: moment().format("YYYY"),
      })
    );
  }, []);

  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <Form action={`${baseUrl}/api/superstokistMontly/csv`}>
          <Row className="mb-3">
            <Col md={4} sm={12}>
              <Form.Control
                as="select"
                onChange={async (e) => {
                  setStartMonth(e.target.value);
                  commonFunction(e.target.value);
                }}
                name="startMonth"
                className="form-control"
              >
                <option value="">StartMonth</option>
                <option value="1">Jan</option>
                <option value="2">Feb</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">Aug</option>
                <option value="9">Sep</option>
                <option value="10">Oct</option>
                <option value="11">Nov</option>
                <option value="12">Dec</option>
              </Form.Control>
            </Col>
            <Col md={4} sm={12}>
              <Form.Control
                as="select"
                onChange={async (e) => {
                  setEndMonth(e.target.value);
                }}
                name="endMonth"
                className="form-control"
              >
                <option value="">EndMonth</option>
                <option value="1">Jan</option>
                <option value="2">Feb</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">Aug</option>
                <option value="9">Sep</option>
                <option value="10">Oct</option>
                <option value="11">Nov</option>
                <option value="12">Dec</option>
              </Form.Control>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col md={2} sm={12}>
              <label>Start Date</label>
              <Form.Control
                type="date"
                placeholder="Start Date"
                name="startDate"
                value={startDate}
                onChange={async (e) => {
                  setStartDate(e.target.value);
                }}
              />
            </Col>
            <Col md={2} sm={12}>
              <label>End Date</label>
              <Form.Control
                type="date"
                placeholder="End Date"
                name="endDate"
                value={endDate}
                onChange={async (e) => {
                  setEndDate(e.target.value);
                }}
              />
            </Col>
            <Col md={1} sm={12} className="mt-4">
              <Form.Control
                type="text"
                placeholder="Year"
                name="year"
                value={year}
                onChange={async (e) => {
                  setYear(e.target.value);
                }}
              />
            </Col>
            <Col md="1" className="mt-4">
              <Button
                variant="success"
                onClick={() => {
                  dispatch(
                    getDispatchbyOrderMonthlyReports({
                      startMonth: startMonth,
                      endMonth: endMonth,
                      year: year,
                      startDate,
                      endDate,
                    })
                  );
                }}
              >
                Submit
              </Button>
            </Col>
            <Col md="1" className="mt-4">
              <Button
                variant="danger"
                onClick={() => window.location.reload(false)}
              >
                Clear
              </Button>
            </Col>
            <Col md="2" className="mt-4">
              <Button variant="warning" type="submit">
                Export Report
              </Button>
            </Col>
          </Row>
        </Form>
        <table className="table table-scrolling mt-2">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">
                Super Stockets
                <br />
                (Party Name)
              </th>
              <th scope="col">Address</th>
              <th scope="col">SO</th>
              <th scope="col">ASM</th>
              {!!getDispatchbyOrderMonthlyReportsList?.selectMonth &&
                Object.entries(
                  getDispatchbyOrderMonthlyReportsList?.selectMonth
                ).map(([data, index]) => (
                  <th scope="col">
                    {(data == "jantomaravg" && "Avr.") ||
                      (data == "jantomar" && "Jan to March") ||
                      (data == "aprtojun" && "Apr to Jun") ||
                      (data == "aprtojunavg" && "Avr.") ||
                      (data == "julytosep" && "July to Sep") ||
                      (data == "julytosepavg" && "Avr.") ||
                      (data == "octtodec" && "Oct to Dec") ||
                      (data == "octtodecavg" && "Avr.") ||
                      data}
                  </th>
                ))}
              <th scope="col">Total CB</th>
            </tr>
          </thead>
          <tbody>
            {!!getDispatchbyOrderMonthlyReportsList?.monthOrder &&
              Object.values(
                getDispatchbyOrderMonthlyReportsList?.monthOrder
              ).map((data, index) => {
                return (
                  <>
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{data?.firmName}</td>
                      <td>{`${data?.address?.town}, ${data?.address?.district}, ${data?.address?.state}`}</td>
                      <td>{data?.salesManName}</td>
                      <td>{data?.asmUser?.name}</td>
                      {/* <td>{data?.dispatchUser?.dispatch}</td> */}
                      {!!getDispatchbyOrderMonthlyReportsList?.selectMonth &&
                        Object.keys(
                          getDispatchbyOrderMonthlyReportsList?.selectMonth
                        ).map(function (key, value) {
                          const currentDate = new Date();
                          const currentMonth = currentDate.getMonth() + 1;
                          return (
                            (key == "jantomaravg" && (
                              <td>
                                <b>
                                  {Math.round(
                                    data["jantomar"] /
                                      (currentMonth >= 3
                                        ? 3
                                        : currentMonth % 3)
                                  )}
                                </b>
                              </td>
                            )) ||
                            (key == "aprtojunavg" && (
                              <td>
                                <b>
                                  {Math.round(
                                    data["aprtojun"] /
                                      (currentMonth >= 6
                                        ? 3
                                        : currentMonth % 3)
                                  )}
                                </b>
                              </td>
                            )) ||
                            (key == "julytosepavg" && (
                              <td>
                                <b>
                                  {Math.round(
                                    data["julytosep"] /
                                      (currentMonth >= 9
                                        ? 3
                                        : currentMonth % 3)
                                  )}
                                </b>
                              </td>
                            )) ||
                            (key == "octtodecavg" && (
                              <td>
                                <b>
                                  {Math.round(
                                    data["octtodec"] /
                                      (currentMonth >= 12
                                        ? 3
                                        : currentMonth % 3)
                                  )}
                                </b>
                              </td>
                            )) || <td>{data[key]}</td>
                          );
                        })}
                      <td>{data?.cbtotal}</td>
                    </tr>
                  </>
                );
              })}
            <tr>
              <td colSpan="5" className="text-center">
                <b>Total</b>
              </td>
              {!!getDispatchbyOrderMonthlyReportsList?.total &&
                Object.keys(
                  getDispatchbyOrderMonthlyReportsList?.selectMonth
                ).map(function (key, value) {
                  const currentDate = new Date();
                  const currentMonth = currentDate.getMonth() + 1;
                  return (
                    (key == "jantomaravg" && (
                      <td>
                        <b>
                          {Math.round(
                            getDispatchbyOrderMonthlyReportsList?.total[
                              "jantomar"
                            ] / (currentMonth === 3 ? 3 : currentMonth % 3)
                          )}
                        </b>
                      </td>
                    )) ||
                    (key == "aprtojunavg" && (
                      <td>
                        <b>
                          {Math.round(
                            getDispatchbyOrderMonthlyReportsList?.total[
                              "aprtojun"
                            ] / (currentMonth === 6 ? 3 : currentMonth % 3)
                          )}
                        </b>
                      </td>
                    )) ||
                    (key == "julytosepavg" && (
                      <td>
                        <b>
                          {Math.round(
                            getDispatchbyOrderMonthlyReportsList?.total[
                              "julytosep"
                            ] / (currentMonth === 9 ? 3 : currentMonth % 3)
                          )}
                        </b>
                      </td>
                    )) ||
                    (key == "octtodecavg" && (
                      <td>
                        <b>
                          {Math.round(
                            getDispatchbyOrderMonthlyReportsList?.total[
                              "octtodec"
                            ] / (currentMonth === 12 ? 3 : currentMonth % 3)
                          )}
                        </b>
                      </td>
                    )) || (
                      <td>
                        <b>
                          {getDispatchbyOrderMonthlyReportsList?.total[key]}
                        </b>
                      </td>
                    )
                  );
                })}
              <td>
                <b>
                  {!!getDispatchbyOrderMonthlyReportsList &&
                    getDispatchbyOrderMonthlyReportsList?.total?.mainTotel}
                </b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default SuperStockistsOrderMonthlyReport;
