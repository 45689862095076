import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addContractor,
  updateContractor,
  getContractorList,
} from "../reducers/commonReducer";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import SideMenu from "../Components/SideMenu";
import moment from "moment";
import ContractorAction from "./ContractorAction";

const ContractorList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getContractorList())
  }, []);
  const { getContractorData } =
    useSelector((state) => state.commonReducer);
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <Row className="mb-3">
        <Col>
       {((localStorage.getItem("userRole") === "1") || (localStorage.getItem("userRole") === "12")) && <ContractorAction title={"Add"} api={addContractor} btnColor={"success"}/>}
        </Col>
        </Row>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Name</th>
              <th scope="col">Date</th>
              <th scope="col">Price</th>
              <th scope="col">Contact</th>
              <th scope="col">Address</th>
              {(localStorage.getItem("x-auth-token") && (localStorage.getItem("userRole") === "1" || localStorage.getItem("userRole") === "12")) && <th scope="col">Action</th>}
            </tr>
          </thead>
          <tbody>
            {!!getContractorData &&
              getContractorData.map((data, index) => {
                return <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{data?.name}<br/>
                          <b>({data?.userName} / {data?.realPassword})</b></td>
                      <td>{moment(data.createdAt).format("DD/MM/YYYY")}</td>
                      <td>{data?.price}</td>
                      <td>{data?.number}<br/>
                          {data?.email}</td>
                      <td>{data?.address}</td>
                      <td>
                        {(localStorage.getItem("x-auth-token") && localStorage.getItem("userRole") === "1" ) && (
                            <ContractorAction
                              id={data?._id}
                              name={data?.name}
                              price={data?.price}
                              number={data?.number}
                              email={data?.email}
                              address={data?.address}
                              perKg={data?.perKg}
                              perPec={data?.perPec}
                              title={"Update"}
                              api={updateContractor}
                              btnColor={"warning"}
                              hellperTotal={data?.workers?.hellper?.total}
                              hellperAmount={data?.workers?.hellper?.amount}
                              operaterTotal={data?.workers?.operater?.total}
                              operaterAmount={data?.workers?.operater?.amount}
                              supervisorTotal={data?.workers?.supervisor?.total}
                              supervisorAmount={data?.workers?.supervisor?.amount}
                              ladieshalperAmount={data?.workers?.ladieshalper?.amount}
                              ladieshalperTotal={data?.workers?.ladieshalper?.total}
                              />
                          )}
                          <Link to={`/contractor?id=${data?._id}&amount=${data?.price}&name=${data?.name}`}><Button variant="info" className="mx-1">View</Button></Link>
                      </td>
                    </tr>
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ContractorList;
