function TagsInput({tagValues, setTagValues}){
    function handleKeyDown(e){
        if(e.key !== ',') return
        const value = e.target.value
        if(!value.trim()) return
        setTagValues([...tagValues, value?.replace(/,/g, '')])
        e.target.value = ''
    }

    function removeTag(index){
        setTagValues(tagValues?.filter((el, i) => i !== index))
    }

    return (
        <div className="tags-input-container">
            {!!tagValues && tagValues?.map((tag, index) => (
                <div className="tag-item" key={index}>
                    <span className="text">{tag}</span>
                    <span className="close" onClick={() => removeTag(index)}>&times;</span>
                </div>
            )) }
            <input onKeyDown={handleKeyDown} type="text" className="tags-input" placeholder="Type somthing" />
        </div>
    )
}

export default TagsInput
