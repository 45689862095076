import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  baseUrl,
  getASMPersonsWithOutPage,
  getSalesPersonWithoutPage,
  getDispatchByWithOutPage,
  getDistrictList,
  getOrderListRest,
  getStatesList,
  getFocusTargetVsAchievementReports,
} from "../reducers/commonReducer";
import moment from "moment";
import SideMenu from "../Components/SideMenu";
import { Row, Col, Form, Button } from "react-bootstrap";
import { commonFunction } from "./commonFunction/commonFunction";
import ExportData from "../Components/ExportData";

const FocusTargetActivement = () => {
  const dispatch = useDispatch();
  const [startMonth, setStartMonth] = useState();
  const [endMonth, setEndMonth] = useState();
  const [userName, setUserName] = useState();
  const [year, setYear] = useState(moment().format("YYYY"));
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const [state, setState] = useState();
  const [district, setDistrict] = useState();
  const [salesMan, setSalesMan] = useState();
  const [asm, setAsm] = useState();

  useEffect(() => {
    dispatch(getOrderListRest());
    dispatch(getStatesList());
    dispatch(
      getFocusTargetVsAchievementReports({
        year: moment().format("YYYY"),
      })
    );
    dispatch(getDispatchByWithOutPage())
    dispatch(getASMPersonsWithOutPage())
    dispatch(getSalesPersonWithoutPage())
  }, []);
  const {getFocusTargetVsAchievementData, statesList,getDistrict,asmPersonsWithOutPage, getSalesPersonWithoutPageList, dispatchByWithOutPage, getTargetVsAchievementData } =
    useSelector((state) => state.commonReducer);
  const handleSelectOption = (event) => {
    const option = event.target.options[event.target.selectedIndex].dataset;
    setSelectedOption(option);
    setUserName(event.target.value);
    // setSuperstokist()
  };
var focusAchievT = 0;
var focusTargetT = 0;
var otherAchievT = 0;
var otherTargetT = 0;
var totalAchievT = 0;
var totalTargetT = 0;
const header = [
  "Sr",
  "PartyName",
  "SO",
  "ASM",
  "FocusAchievement",
  "FocusTarget",
  "OtherAchievement",
  "OtherTarget",
  "TotalAchievement",
  "TotalTarget",
];
let body = [];
!!getFocusTargetVsAchievementData && Object.values(getFocusTargetVsAchievementData)?.map((data, index) => {
body.push({
    Sr: index + 1,
    PartyName: data?.firmName,
    SO: data?.salesman,
    ASM: data?.asm,
    FocusAchievement: data?.focusAchiev,
    FocusTarget: data?.focusTarget,
    OtherAchievement : data?.OtherAchiev,
    OtherTarget:data?.otherTarget,
    TotalAchievement: data?.Achievement,
    TotalTarget:data?.focusTarget + data?.otherTarget 
  });
});
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
      <Row>
        <h3><b>Target vs Achievement</b></h3>
      </Row>
        <Form action={`${baseUrl}/api/targetcsv/report`} className="print-hidden">
          <Row className="mb-3">
                <Col md={3} sm={12}>
                    <Form.Control
                        as="select"
                        onChange={async (e) => {
                        setState(e.target.value);
                        e.target.value.length > 1 &&
                            dispatch(getDistrictList(e.target.value));
                        }}
                        name="state"
                        className="form-control">
                        <option value={""}>Select State</option>
                        {!!statesList &&
                        statesList.map((data, index) => {
                            return (
                            <option key={index} value={data?.state}>
                                {data?.state}
                            </option>
                            );
                        })}
                    </Form.Control>
                </Col>
                <Col md={3} sm={12}>
                    <Form.Control
                        as="select"
                        onChange={async (e) => {
                        setDistrict(e.target.value);
                        }}
                        name="district"
                        className="form-control"
                    >
                        <option value="">Select District</option>
                        {!!getDistrict &&
                        getDistrict.map((data, index) => {
                            return (
                            <option key={index} value={data}>
                                {data}
                            </option>
                            );
                        })}
                    </Form.Control>
                </Col>
                <Col md={3} sm={12}>
                    <Form.Control
                        as="select"
                        onChange={async (e) => {
                        setStartMonth(e.target.value);
                        commonFunction(e.target.value);
                        }}
                        name="startMonth"
                        className="form-control"
                    >
                        <option value="">StartMonth</option>
                        <option value="1">Jan</option>
                        <option value="2">Feb</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">Aug</option>
                        <option value="9">Sep</option>
                        <option value="10">Oct</option>
                        <option value="11">Nov</option>
                        <option value="12">Dec</option>
                    </Form.Control>
                </Col>
                <Col md={3} sm={12}>
                    <Form.Control
                        as="select"
                        onChange={async (e) => {
                        setEndMonth(e.target.value);
                        }}
                        name="endMonth"
                        className="form-control"
                    >
                        <option value="">EndMonth</option>
                        <option value="1">Jan</option>
                        <option value="2">Feb</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">Aug</option>
                        <option value="9">Sep</option>
                        <option value="10">Oct</option>
                        <option value="11">Nov</option>
                        <option value="12">Dec</option>
                    </Form.Control>
                </Col>
          </Row>
          <Row className="mt-1">
          <Col md={2} sm={12}>
              <label>Select ASM</label>
              <Form.Control
                as="select"
                onChange={async (e) => {
                  setAsm(e.target.value);
                }}
                name="asm"
                className="form-control"
              >
                <option value="">Select ASM</option>
                {!!asmPersonsWithOutPage &&
                  asmPersonsWithOutPage.map((data, index) => {
                    return (
                      <option key={index} value={data?.userName}>
                        {data?.name}
                      </option>
                    );
                  })}
              </Form.Control>
            </Col>
            <Col md={2} sm={12}>
              <label>Select SO</label>
              <Form.Control
                as="select"
                onChange={async (e) => {
                  setSalesMan(e.target.value);
                }}
                name="so"
                className="form-control"
              >
                <option value="">Select SO</option>
                {!!getSalesPersonWithoutPageList &&
                  getSalesPersonWithoutPageList.map((data, index) => {
                    return (
                      <option key={index} value={data?.userName}>
                        {data?.name}
                      </option>
                    );
                  })}
              </Form.Control>
            </Col>
            <Col md={2} sm={12}>
            <label>Select DispatchBy</label>
                  <Form.Control
                    as="select"
                    onChange={handleSelectOption}
                    name="superstokist"
                    className="form-control"
                  >
                    <option value={"All"}>Select DispatchBy</option>
                    {!!dispatchByWithOutPage &&
                      dispatchByWithOutPage.map((data, index) => {
                        return (
                          <option
                            key={index}
                            value={data?._id}
                          >
                            {data?.dispatch}
                          </option>
                        );
                      })}
                  </Form.Control>
            </Col>
            <Col md={2} sm={12}>
              <label>Start Date</label>
              <Form.Control
                type="date"
                placeholder="Start Date"
                name="startDate"
                value={startDate}
                onChange={async (e) => {
                  setStartDate(e.target.value);
                }}
              />
            </Col>
            <Col md={2} sm={12}>
              <label>End Date</label>
              <Form.Control
                type="date"
                placeholder="End Date"
                name="endDate"
                value={endDate}
                onChange={async (e) => {
                  setEndDate(e.target.value);
                }}
              />
            </Col>
            <Col md={1} sm={12} className="mt-4">
              <Form.Control
                type="text"
                placeholder="Year"
                name="year"
                value={year}
                onChange={async (e) => {
                  setYear(e.target.value);
                }}
              />
            </Col>
            <Col md="1" className="mt-4">
              <Button
                variant="success"
                onClick={() => {
                  dispatch(
                    getFocusTargetVsAchievementReports({
                      superstokist: userName,
                      startMonth: startMonth,
                      endMonth: endMonth,
                      year: year,
                      state: state,
                      district: district,
                      startDate,
                      endDate,
                      salesMan,
                      asm
                    })
                  );
                }}
              >
                Submit
              </Button>
            </Col>
            <Col md={2} className="mt-4">
              <Button
                variant="danger"
                onClick={() => window.location.reload(false)}
              >
                Clear
              </Button>
            </Col>
            <Col md={4} className="mt-4">
              <ExportData title="Export" header={header} body={body} tableName="Focus Products"/>
            </Col>
            <Col md={6} className="text-align-end mt-4">
              <Button onClick={() => window.print()}>
                <i className="bi bi-printer-fill"></i>
              </Button>
            </Col>
          </Row>
        </Form>
        <table className="table table-scrolling mt-2">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Party Name</th>
              <th scope="col">Address</th>
              <th scope="col">SO</th>
              <th scope="col">ASM</th>
              <th scope="col">Focus Achievement</th>
              <th scope="col">Focus Target</th>
              <th scope="col">Other Achievement</th>
              <th scope="col">Other Target</th>
              <th scope="col">Total Achievement</th>
              <th scope="col">Total Target</th>
            </tr>
          </thead>
          <tbody>
            {!!getFocusTargetVsAchievementData &&
              Object.values(
                getFocusTargetVsAchievementData
              ).map((data, index) => {
                focusAchievT += +data?.focusAchiev;
                focusTargetT += +data?.focusTarget;
                otherTargetT += +data?.otherTarget;
                otherAchievT += +data?.OtherAchiev;
                totalAchievT += +data?.Achievement;
                totalTargetT += data?.focusTarget + data?.otherTarget;
                return (
                  <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>{data?.firmName}</td>
                    <td>{`${data?.address?.town}, ${data?.address?.district}, ${data?.address?.state}`}</td>
                    <td>{data?.salesman}</td>
                    <td>{data?.asm}</td>
                    <td>{data?.focusAchiev}</td>
                    <td>{data?.focusTarget}</td>
                    <td>{data?.OtherAchiev}</td>
                    <td>{data?.otherTarget}</td>
                    <td>{data?.Achievement}</td>
                    <td>{data?.focusTarget + data?.otherTarget}</td>
                  </tr>
                );
              })}
            <tr>
              <td colSpan="5" className="text-center">
                <b>Total</b>
              </td>
               <td className="text-center">
                <b>{focusAchievT}</b>
              </td>
              <td className="text-center">
                <b>{focusTargetT}</b>
              </td>
              <td className="text-center">
                <b>{otherAchievT}</b>
              </td>
              <td className="text-center">
                <b>{otherTargetT}</b>
              </td>
              <td className="text-center">
                <b>{totalAchievT}</b>
              </td>
              <td className="text-center">
                <b>{totalTargetT}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default FocusTargetActivement;
