import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import Row from "react-bootstrap/Row";
import { Formik } from "formik";
import { getIndustrialVisitList, updateIndustrialVisit} from "../reducers/commonReducer";
import Modal from 'react-bootstrap/Modal';

function IndustrialVisitAction(props) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {id, status, comment, startDate, endDate, filterStatus} = props;
  return (
    <>
     <Button variant="primary" onClick={handleShow}>
        Update
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Industrial Visit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <div className="container-fluid">
                  <Formik
                    onSubmit={async (values, { resetForm }) => {
                     await dispatch(updateIndustrialVisit(values))
                     await dispatch(getIndustrialVisitList({
                      startDate, endDate, status:filterStatus
                     }))
                     handleClose();
                      resetForm({ values: "" });
                    }}
                    initialValues={{
                    id,
                    status,
                    comment
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      values,
                      errors,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Row className="mb-3">
                        <Col md={12} sm={12}>
                        <Form.Label>Select Status</Form.Label>
                          <Form.Control
                            as="select"
                            multiple={false}
                            name="status"
                            onChange={handleChange}
                            className="form-control"
                            value={values.status}
                            isInvalid={!!errors.status}
                          >
                                <option value="">Select Status</option>
                                <option value="PENDING">PENDING</option>
                                <option value="DISCUSSION">DISCUSSION</option>
                                <option value="SLOT ALLOTED">SLOT ALLOTED</option>
                                <option value="VISIT DONE">VISIT DONE</option>
                                <option value="REJECTED">REJECTED</option>
                                <option value="DENIED">DENIED</option>
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {errors.department}
                          </Form.Control.Feedback>
                        </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md={12} sm={12}>
                            <Form.Group >
                              <Form.Label>
                                Write Your Comment
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Write Your Comment...."
                                name="comment"
                                value={values.comment}
                                onChange={handleChange}
                                isInvalid={!!errors.comment}
                                as="textarea"
                                rows={3}
                              />

                              <Form.Control.Feedback type="invalid">
                                {errors.comment}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Button type="submit">Submit</Button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Modal.Body>
              </Modal>
    </>
  );
}

export default IndustrialVisitAction;
