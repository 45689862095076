import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  baseUrl,
  getDispatchbyProductReports,
  getDispatchByWithOutPage,
  getOrderListRest,
  getStatesList,
} from "../reducers/commonReducer";
import moment from "moment";
import SideMenu from "../Components/SideMenu";
import { Row, Col, Form, Button } from "react-bootstrap";
import { commonFunction } from "./commonFunction/commonFunction";

const SuperStockistsProductReport = () => {
  const dispatch = useDispatch();
  const [superstokist, setSuperstokist] = useState();
  const [startMonth, setStartMonth] = useState();
  const [endMonth, setEndMonth] = useState();
  const [userName, setUserName] = useState();
  const [year, setYear] = useState(moment().format("YYYY"));
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const { dispatchByWithOutPage, getDispatchbyProductReportsList } =
    useSelector((state) => state.commonReducer);
  useEffect(() => {
    {
      localStorage.getItem("x-auth-token") &&
        localStorage.getItem("userRole") == 1 &&
        dispatch(getDispatchByWithOutPage());
    }
    dispatch(getOrderListRest());
    dispatch(getStatesList());
    dispatch(
      getDispatchbyProductReports({
        year: moment().format("YYYY"),
      })
    );
  }, []);

  const handleSelectOption = (event) => {
    const option = event.target.options[event.target.selectedIndex].dataset;
    setSelectedOption(option);
    setUserName(event.target.value);
    // setSuperstokist()
  };
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <Form action={`${baseUrl}/api/superstokist/products/csv`}>
          <Row className="mb-3">
            {localStorage.getItem("x-auth-token") &&
              localStorage.getItem("userRole") == 1 && (
                <Col md={4} sm={12}>
                  <Form.Control
                    as="select"
                    onChange={handleSelectOption}
                    name="superstokist"
                    className="form-control"
                  >
                    <option value={"All"}>Select DispatchBy</option>
                    {!!dispatchByWithOutPage &&
                      dispatchByWithOutPage.map((data, index) => {
                        return (
                          <option
                            key={index}
                            data-number={data?.number}
                            data-town={data?.address?.town}
                            data-district={data?.address?.district}
                            data-userState={data?.address?.state}
                            data-email={data?.email}
                            value={data?._id}
                          >
                            {data?.dispatch}
                          </option>
                        );
                      })}
                  </Form.Control>
                </Col>
              )}
            <Col md={4} sm={12}>
              <Form.Control
                as="select"
                onChange={async (e) => {
                  setStartMonth(e.target.value);
                  commonFunction(e.target.value);
                }}
                name="startMonth"
                className="form-control"
              >
                <option value="">StartMonth</option>
                <option value="1">Jan</option>
                <option value="2">Feb</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">Aug</option>
                <option value="9">Sep</option>
                <option value="10">Oct</option>
                <option value="11">Nov</option>
                <option value="12">Dec</option>
              </Form.Control>
            </Col>
            <Col md={4} sm={12}>
              <Form.Control
                as="select"
                onChange={async (e) => {
                  setEndMonth(e.target.value);
                }}
                name="endMonth"
                className="form-control"
              >
                <option value="">EndMonth</option>
                <option value="1">Jan</option>
                <option value="2">Feb</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">Aug</option>
                <option value="9">Sep</option>
                <option value="10">Oct</option>
                <option value="11">Nov</option>
                <option value="12">Dec</option>
              </Form.Control>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col md={2} sm={12}>
              <label>Start Date</label>
              <Form.Control
                type="date"
                placeholder="Start Date"
                name="startDate"
                value={startDate}
                onChange={async (e) => {
                  setStartDate(e.target.value);
                }}
              />
            </Col>
            <Col md={2} sm={12}>
              <label>End Date</label>
              <Form.Control
                type="date"
                placeholder="End Date"
                name="endDate"
                value={endDate}
                onChange={async (e) => {
                  setEndDate(e.target.value);
                }}
              />
            </Col>
            <Col md={1} sm={12} className="mt-4">
              <Form.Control
                type="text"
                placeholder="Year"
                name="year"
                value={year}
                onChange={async (e) => {
                  setYear(e.target.value);
                }}
              />
            </Col>
            <Col md="1" className="mt-4">
              <Button
                variant="success"
                onClick={() => {
                  dispatch(
                    getDispatchbyProductReports({
                      superstokist: userName,
                      startMonth: startMonth,
                      endMonth: endMonth,
                      year: year,
                      startDate,
                      endDate,
                    })
                  );
                }}
              >
                Submit
              </Button>
            </Col>
            <Col md="1" className="mt-4">
              <Button
                variant="danger"
                onClick={() => window.location.reload(false)}
              >
                Clear
              </Button>
            </Col>
            <Col md="1" className="mt-4">
              <Button variant="warning" type="submit">
                Export
              </Button>
            </Col>
          </Row>
        </Form>
        {localStorage.getItem("x-auth-token") &&
          localStorage.getItem("userRole") == 1 && (
            <Row>
              <Col md={6}>
                <h4>
                  <b>Product Wise Sale</b>
                </h4>
                <p className="mb-0">
                  <b>DispatchBy: </b> {userName}
                </p>
                <p className="mb-0">
                  <b>Headquarter: </b>{" "}
                  {!!selectedOption?.town &&
                    selectedOption?.town +
                      ", " +
                      selectedOption?.district +
                      ", " +
                      selectedOption?.userstate}
                </p>
                <p className="mb-0">
                  <b>Mobile No.: </b> {selectedOption?.number}
                </p>
                <p className="mb-0">
                  <b>Email: </b>
                  {selectedOption?.email}
                </p>
              </Col>
            </Row>
          )}
        <table className="table table-scrolling">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Party Name</th>
              <th scope="col">Address</th>
              <th scope="col">SO</th>
              <th scope="col">ASM</th>
              {/* <th scope="col">DispatchBy</th> */}
              {!!getDispatchbyProductReportsList &&
                !!getDispatchbyProductReportsList?.products &&
                Object.entries(getDispatchbyProductReportsList?.products).map(
                  ([key, value]) => <th scope="col">{value}</th>
                )}
              <th scope="col">Total CB</th>
            </tr>
          </thead>
          <tbody>
            {!!getDispatchbyProductReportsList &&
              !!getDispatchbyProductReportsList?.productWiseOrder &&
              Object.values(
                getDispatchbyProductReportsList?.productWiseOrder
              ).map((data, index) => {
                return (
                  <>
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{data?.firmName}</td>
                      <td>{`${data?.address?.town}, ${data?.address?.district}, ${data?.address?.state}`}</td>
                      <td>{data?.salesManName}</td>
                      <td>{data?.asmUser?.name}</td>
                      {/* <td>{data?.dispatchUser?.dispatch}</td> */}
                      {!!getDispatchbyProductReportsList?.products &&
                        Object.keys(
                          getDispatchbyProductReportsList?.products
                        ).map(function (key) {
                          return <td>{data[key]}</td>;
                        })}
                      <td>{data?.cbtotal}</td>
                    </tr>
                  </>
                );
              })}
            <tr>
              <td colSpan="5" className="text-center">
                <b>Total</b>
              </td>
              {!!getDispatchbyProductReportsList &&
                !!getDispatchbyProductReportsList?.total &&
                Object.keys(getDispatchbyProductReportsList?.products).map(
                  function (key, value) {
                    return (
                      <td>
                        <b>{getDispatchbyProductReportsList?.total[key]}</b>
                      </td>
                    );
                  }
                )}
              <td>
                <b>
                  {!!getDispatchbyProductReportsList &&
                    getDispatchbyProductReportsList?.total?.cbTotel}
                </b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default SuperStockistsProductReport;
