import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as yup from "yup";
import { getProducts, seniorStateDistributor } from "../reducers/commonReducer";

const schema = yup.object().shape({
  name: yup.string().required(),
  number: yup.number().required(),
  state: yup.string().required(),
  password: yup.string().required(),
  status: yup.number().required(),
});

const StateWiseDistributorSeniorAction = (props) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const{api, title, id, name, number, state, status, password} = props;
  const { statesList} = useSelector(
    (state) => state.commonReducer
  );
  return (
    <>
      <Button variant="success" className={title === "Add" && "float-right"} onClick={handleShow}>
        {title}
      </Button>
      <Modal size={"lg"} show={show} onHide={handleClose}>
        <div className="row px-3 py-2">
          <Row className="text-center">
            <h3 className="mb-4 mt-4">{title} Super Distributor</h3>
          </Row>
          <div className="container-fluid">
            <Formik
              validationSchema={schema}
              onSubmit={async (values, { resetForm }) => {
                await dispatch(api(values));
                resetForm({ values: "" });
                dispatch(seniorStateDistributor());
                setShow(false);
              }}
              initialValues={{
                id, name, number, state, password, status
              }}
            >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                values,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Row className="mb-3">
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          isInvalid={!!errors.name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Phone"
                          name="number"
                          value={values.number}
                          onChange={handleChange}
                          isInvalid={!!errors.number}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.number}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                      <Col md={4} sm={12}>
                      <Form.Label>State</Form.Label>
                      <br />
                      <Form.Control
                        as="select"
                        onChange={handleChange}
                        name="state"
                        className="form-control"
                        value={values.state}
                        isInvalid={!!errors.state}
                      >
                        <option value="">Select State</option>
                        {statesList?.map((data, index) => {
                          return (
                            <option
                              key={index}
                              value={data?._id}
                            >
                              {data.state}
                            </option>
                          );
                        })}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.state}
                      </Form.Control.Feedback>
                    </Col>
                    <Col md={4} sm={12}>
                        <Form.Group>
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="password"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            isInvalid={!!errors.password}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.password}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={4} sm={12}>
                      <Form.Label>Status</Form.Label>
                      <br />
                      <Form.Control
                        as="select"
                        onChange={handleChange}
                        name="status"
                        className="form-control"
                        value={values.status}
                        isInvalid={!!errors.status}
                      >
                        <option value="">Status</option>
                        <option value={1}>Active</option>
                        <option value={0}>InActive</option>
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.status}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Button type="submit" className="mt-3">
                    Submit
                  </Button>
                  <Button
                    variant="danger"
                    onClick={handleClose}
                    className="mt-3"
                  >
                    Close
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default StateWiseDistributorSeniorAction;
