import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getASMPersonsWithOutPage,
  getDistrictList,
  getOrderdDeliverdMonthlyReports,
  getOrderListRest,
  getSalesPersonWithoutPage,
  getSelectedSalesPerson,
  getStatesList,
} from "../reducers/commonReducer";
import moment from "moment";
import SideMenu from "../Components/SideMenu";
import { Row, Col, Form, Button } from "react-bootstrap";
import { commonFunction } from "./commonFunction/commonFunction";

const OrderdDeliverdMonthly = () => {
  const dispatch = useDispatch();
  const [startMonth, setStartMonth] = useState();
  const [endMonth, setEndMonth] = useState();
  const [state, setState] = useState();
  const [district, setDistrict] = useState();
  const [selectedSO, setselectedSO] = useState();
  const [selectedAsm, setselectedASM] = useState();
  const [year, setYear] = useState(moment().format("YYYY"));

  const {
    statesList,
    getDistrict,
    getSalesPersonWithoutPageList,
    asmPersonsWithOutPage,
    OrderdDeliverdMRpotList,
  } = useSelector((state) => state.commonReducer);
  useEffect(() => {
    dispatch(getOrderListRest());
    dispatch(getOrderdDeliverdMonthlyReports({ year: year }));
    dispatch(getStatesList());
    dispatch(getASMPersonsWithOutPage());
    dispatch(getSalesPersonWithoutPage());
  }, []);
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <Row className="mb-3">
          <Col md={2} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setState(e.target.value);
                e.target.value.length > 1 &&
                  dispatch(getDistrictList(e.target.value));
              }}
              name="state"
              className="form-control"
            >
              <option value={""}>Select State</option>
              {!!statesList &&
                statesList.map((data, index) => {
                  return (
                    <option key={index} value={data?.state}>
                      {data?.state}
                    </option>
                  );
                })}
            </Form.Control>
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setDistrict(e.target.value);
              }}
              name="state"
              className="form-control"
            >
              <option value="">Select District</option>
              {!!getDistrict &&
                getDistrict.map((data, index) => {
                  return (
                    <option key={index} value={data}>
                      {data}
                    </option>
                  );
                })}
            </Form.Control>
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setselectedASM(e.target.value);
                dispatch(
                  getSalesPersonWithoutPage({
                    asm: e.target.value,
                  })
                );
              }}
              name="asm"
              className="form-control"
            >
              <option value="">Select ASM</option>
              {!!asmPersonsWithOutPage &&
                asmPersonsWithOutPage.map((data, index) => {
                  return (
                    <option key={index} value={data?.userName}>
                      {data?.userName}
                    </option>
                  );
                })}
            </Form.Control>
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setselectedSO(e.target.value);
              }}
              name="so"
              className="form-control"
            >
              <option value="">Select SO</option>
              {!!getSalesPersonWithoutPageList &&
                getSalesPersonWithoutPageList.map((data, index) => {
                  return (
                    <option key={index} value={data?.userName}>
                      {data?.name}
                    </option>
                  );
                })}
            </Form.Control>
          </Col>
          <Col md={1} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setStartMonth(e.target.value);
                commonFunction(e.target.value);
              }}
              name="state"
              className="form-control"
            >
              <option value="">StartMonth</option>
              <option value="1">Jan</option>
              <option value="2">Feb</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">Aug</option>
              <option value="9">Sep</option>
              <option value="10">Oct</option>
              <option value="11">Nov</option>
              <option value="12">Dec</option>
            </Form.Control>
          </Col>
          <Col md={1} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setEndMonth(e.target.value);
              }}
              name="state"
              className="form-control"
            >
              <option value="">EndMonth</option>
              <option value="1">Jan</option>
              <option value="2">Feb</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">Aug</option>
              <option value="9">Sep</option>
              <option value="10">Oct</option>
              <option value="11">Nov</option>
              <option value="12">Dec</option>
            </Form.Control>
          </Col>
          <Col md={1} sm={12}>
            <Form.Control
              type="text"
              placeholder="Year"
              name="year"
              value={year}
              onChange={async (e) => {
                setYear(e.target.value);
              }}
            />
          </Col>
          <Col md="1">
            <Button
              variant="success"
              onClick={() => {
                dispatch(
                  getOrderdDeliverdMonthlyReports({
                    startMonth: startMonth,
                    endMonth: endMonth,
                    year: year,
                    state: state,
                    asm: selectedAsm,
                    so: selectedSO,
                    district: district,
                  })
                );
              }}
            >
              Submit
            </Button>
          </Col>
          <Col md="1">
            <Button
              variant="danger"
              onClick={() => window.location.reload(false)}
            >
              Clear
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <h4>
              <b>
                (Company, State, District, ASM, SO)
                <br />
                Month Wise Orderd and Deliverd
              </b>
            </h4>
            <p className="mb-0">
              <b>Year: </b> {year}
            </p>
          </Col>
        </Row>
        <table className="table table-scrolling">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Party Name</th>
              <th scope="col">Address</th>
              {!!OrderdDeliverdMRpotList &&
                !!OrderdDeliverdMRpotList?.selectMonth &&
                Object.entries(OrderdDeliverdMRpotList?.selectMonth).map(
                  ([data, index]) => (
                    <th scope="col" key={index}>
                      {(data == "jantomaravg" && "Avr.") ||
                        (data == "jantomar" && "Jan to March") ||
                        (data == "aprtojun" && "Apr to Jun") ||
                        (data == "aprtojunavg" && "Avr.") ||
                        (data == "julytosep" && "July to Sep") ||
                        (data == "julytosepavg" && "Avr.") ||
                        (data == "octtodec" && "Oct to Dec") ||
                        (data == "octtodecavg" && "Avr.") ||
                        data}
                    </th>
                  )
                )}
              <th scope="col">Total CB</th>
            </tr>
          </thead>
          <tbody>
            {!!OrderdDeliverdMRpotList &&
              !!OrderdDeliverdMRpotList?.monthOrder &&
              Object.values(OrderdDeliverdMRpotList?.monthOrder).map(
                (data, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{data?.name}</td>
                      <td>{`${data?.address?.town}, ${data?.address?.district}, ${data?.address?.state}`}</td>

                      {!!OrderdDeliverdMRpotList?.selectMonth &&
                        Object.keys(OrderdDeliverdMRpotList?.selectMonth).map(
                          function (key, value) {
                            const currentDate = new Date();
                            const currentMonth = currentDate.getMonth() + 1;
                            return (
                              (key == "jantomaravg" && (
                                <td>
                                  <tr>
                                    <td>Orderd</td>
                                    <td>Deliverd</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>
                                        {Math.round(
                                          data["jantomar"]?.Other /
                                            (currentMonth === 3
                                              ? 3
                                              : currentMonth % 3)
                                        )}
                                      </b>
                                    </td>
                                    <td>
                                      <b>
                                        {Math.round(
                                          data["jantomar"]?.Deliver /
                                            (currentMonth === 3
                                              ? 3
                                              : currentMonth % 3)
                                        )}
                                      </b>
                                    </td>
                                  </tr>
                                </td>
                              )) ||
                              (key == "aprtojunavg" && (
                                <td>
                                  <tr>
                                    <td>Orderd</td>
                                    <td>Deliverd</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>
                                        {Math.round(
                                          data["aprtojun"]?.Other /
                                            (currentMonth === 6
                                              ? 3
                                              : currentMonth % 3)
                                        )}
                                      </b>
                                    </td>
                                    <td>
                                      <b>
                                        {Math.round(
                                          data["aprtojun"]?.Deliver /
                                            (currentMonth === 6
                                              ? 3
                                              : currentMonth % 3)
                                        )}
                                      </b>
                                    </td>
                                  </tr>
                                </td>
                              )) ||
                              (key == "julytosepavg" && (
                                <td>
                                  <tr>
                                    <td>Orderd</td>
                                    <td>Deliverd</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>
                                        {Math.round(
                                          data["julytosep"]?.Other /
                                            (currentMonth === 9
                                              ? 3
                                              : currentMonth % 3)
                                        )}
                                      </b>
                                    </td>
                                    <td>
                                      <b>
                                        {Math.round(
                                          data["julytosep"]?.Deliver /
                                            (currentMonth === 9
                                              ? 3
                                              : currentMonth % 3)
                                        )}
                                      </b>
                                    </td>
                                  </tr>
                                </td>
                              )) ||
                              (key == "octtodecavg" && (
                                <td>
                                  <tr>
                                    <td>Orderd</td>
                                    <td>Deliverd</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>
                                        {Math.round(
                                          data["octtodec"]?.Other /
                                            (currentMonth === 12
                                              ? 3
                                              : currentMonth % 3)
                                        )}
                                      </b>
                                    </td>
                                    <td>
                                      <b>
                                        {Math.round(
                                          data["octtodec"]?.Deliver /
                                            (currentMonth === 12
                                              ? 3
                                              : currentMonth % 3)
                                        )}
                                      </b>
                                    </td>
                                  </tr>
                                </td>
                              )) || (
                                <td>
                                  <tr>
                                    <td>Orderd</td>
                                    <td>Deliverd</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {data[key]?.Other ? (
                                        <b>{data[key]?.Other}</b>
                                      ) : (
                                        0
                                      )}
                                    </td>
                                    <td>
                                      {data[key]?.Deliver ? (
                                        <b>{data[key]?.Deliver}</b>
                                      ) : (
                                        0
                                      )}
                                    </td>
                                  </tr>
                                </td>
                              )
                            );
                          }
                        )}
                      <td>
                        <tr>
                          <td>Orderd</td>
                          <td>Deliverd</td>
                        </tr>
                        <tr>
                          <td>
                            <b>{data?.cbTotal?.Other}</b>
                          </td>
                          <td>
                            <b>{data?.cbTotal?.Deliver}</b>
                          </td>
                        </tr>
                      </td>
                    </tr>
                  );
                }
              )}
            <tr>
              <td colSpan="3" className="text-center">
                <b>Total</b>
              </td>
              {!!OrderdDeliverdMRpotList &&
                !!OrderdDeliverdMRpotList?.total &&
                Object.keys(OrderdDeliverdMRpotList?.selectMonth).map(function (
                  key,
                  value
                ) {
                  const currentDate = new Date();
                  const currentMonth = currentDate.getMonth() + 1;
                  return (
                    (key == "jantomaravg" && (
                      <td>
                        <tr>
                          <td>Orderd</td>
                          <td>Deliverd</td>
                        </tr>
                        <tr>
                          <td>
                            <b>
                              {OrderdDeliverdMRpotList?.total["jantomar"]?.Other
                                ? Math.round(
                                    OrderdDeliverdMRpotList?.total["jantomar"]
                                      ?.Other /
                                      (currentMonth === 3
                                        ? 3
                                        : currentMonth % 3)
                                  )
                                : 0}
                            </b>
                          </td>
                          <td>
                            <b>
                              {OrderdDeliverdMRpotList?.total["jantomar"]
                                ?.Deliver
                                ? Math.round(
                                    OrderdDeliverdMRpotList?.total["jantomar"]
                                      ?.Deliver /
                                      (currentMonth === 3
                                        ? 3
                                        : currentMonth % 3)
                                  )
                                : 0}
                            </b>
                          </td>
                        </tr>
                      </td>
                    )) ||
                    (key == "aprtojunavg" && (
                      <td>
                        <tr>
                          <td>Orderd</td>
                          <td>Deliverd</td>
                        </tr>
                        <tr>
                          <td>
                            <b>
                              {OrderdDeliverdMRpotList?.total["aprtojun"]?.Other
                                ? Math.round(
                                    OrderdDeliverdMRpotList?.total["aprtojun"]
                                      ?.Other /
                                      (currentMonth === 6
                                        ? 3
                                        : currentMonth % 3)
                                  )
                                : 0}
                            </b>
                          </td>
                          <td>
                            <b>
                              {OrderdDeliverdMRpotList?.total["aprtojun"]
                                ?.Deliver
                                ? Math.round(
                                    OrderdDeliverdMRpotList?.total["aprtojun"]
                                      ?.Deliver /
                                      (currentMonth === 6
                                        ? 3
                                        : currentMonth % 3)
                                  )
                                : 0}
                            </b>
                          </td>
                        </tr>
                      </td>
                    )) ||
                    (key == "julytosepavg" && (
                      <td>
                        <tr>
                          <td>Orderd</td>
                          <td>Deliverd</td>
                        </tr>
                        <tr>
                          <td>
                            <b>
                              {OrderdDeliverdMRpotList?.total["julytosep"]
                                ?.Other
                                ? Math.round(
                                    OrderdDeliverdMRpotList?.total["julytosep"]
                                      ?.Other /
                                      (currentMonth === 9
                                        ? 3
                                        : currentMonth % 3)
                                  )
                                : 0}
                            </b>
                          </td>
                          <td>
                            <b>
                              {OrderdDeliverdMRpotList?.total["julytosep"]
                                ?.Deliver
                                ? Math.round(
                                    OrderdDeliverdMRpotList?.total["julytosep"]
                                      ?.Deliver /
                                      (currentMonth === 9
                                        ? 3
                                        : currentMonth % 3)
                                  )
                                : 0}
                            </b>
                          </td>
                        </tr>
                      </td>
                    )) ||
                    (key == "octtodecavg" && (
                      <td>
                        <tr>
                          <td>Orderd</td>
                          <td>Deliverd</td>
                        </tr>
                        <tr>
                          <td>
                            <b>
                              {OrderdDeliverdMRpotList?.total["octtodec"]?.Other
                                ? Math.round(
                                    OrderdDeliverdMRpotList?.total["octtodec"]
                                      ?.Other /
                                      (currentMonth === 12
                                        ? 3
                                        : currentMonth % 3)
                                  )
                                : 0}
                            </b>
                          </td>
                          <td>
                            <b>
                              {OrderdDeliverdMRpotList?.total["octtodec"]
                                ?.Deliver
                                ? Math.round(
                                    OrderdDeliverdMRpotList?.total["octtodec"]
                                      ?.Deliver /
                                      (currentMonth === 12
                                        ? 3
                                        : currentMonth % 3)
                                  )
                                : 0}
                            </b>
                          </td>
                        </tr>
                      </td>
                    )) || (
                      <td>
                        <tr>
                          <td>Orderd</td>
                          <td>Deliverd</td>
                        </tr>
                        <tr>
                          <td>
                            <b>
                              {OrderdDeliverdMRpotList?.total[key]?.Other
                                ? OrderdDeliverdMRpotList?.total[key]?.Other
                                : 0}
                            </b>
                          </td>
                          <td>
                            <b>
                              {OrderdDeliverdMRpotList?.total[key]?.Deliver
                                ? OrderdDeliverdMRpotList?.total[key]?.Deliver
                                : 0}
                            </b>
                          </td>
                        </tr>
                      </td>
                    )
                  );
                })}
              <td>
                <tr>
                  <td>Orderd</td>
                  <td>Deliverd</td>
                </tr>
                <tr>
                  <td>
                    <b>
                      {!!OrderdDeliverdMRpotList &&
                        OrderdDeliverdMRpotList?.total?.mainTotel?.Other}
                    </b>
                  </td>
                  <td>
                    <b>
                      {!!OrderdDeliverdMRpotList &&
                        OrderdDeliverdMRpotList?.total?.mainTotel?.Deliver}
                    </b>
                  </td>
                </tr>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default OrderdDeliverdMonthly;
