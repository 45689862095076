import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { careerUpdate, getCareerList, getOrderListRest } from "../reducers/commonReducer";

function CareerAction(props) {
  const dispatch = useDispatch();
  const { userUpdateMsg } = useSelector((state) => state.commonReducer);
  const { userStatus, dataUserStatus, userComment, id, department, endDate, startDate } = props;
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState(dataUserStatus);
  const [comment, setComment] = useState(userComment);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const updateUser = async () => {
    await dispatch(careerUpdate({ status, comment, id }));
    await dispatch(getOrderListRest());
    dispatch(getCareerList({department, userStatus, endDate, startDate}));
  };
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        <i className="bi bi-pencil-square"></i>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Career</Modal.Title>
        </Modal.Header>
        <Form.Group className="p-3">
          <Form.Label>Enter Your Comment</Form.Label>
          <Form.Control
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="p-3">
          <Form.Select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="">Select User Status</option>
            <option value={"Pending"}>Pending</option>
            <option value={"Selected"}>Selected</option>
            <option value={"Confirmd"}>Confirmd</option>
            <option value={"Read"}>Read</option>
            <option value={"Discussion"}>Discussion</option>
            <option value={"Reject"}>Reject</option>
          </Form.Select>
        </Form.Group>
        {/* <p className="p-3"><b>{userUpdateMsg}</b></p> */}
        <Modal.Footer>
          <Button variant="primary" onClick={updateUser}>
            Save
          </Button>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CareerAction;
