import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import {
  getOrderListRest,
  getDispatchBy,
  updateDispatchBy,
} from "../reducers/commonReducer";

const schema = yup.object().shape({
  password: yup.string(),
  status: yup.string(),
  id: yup.string().required(),
  name: yup.string().required(),
  distributorList: yup.array(),
  salesmanIdList: yup.array(),
});

function SuperStockistsUpdate(props) {
  const timeoutIdRef = React.useRef();
  const dispatch = useDispatch();
  const { id, distributorList, userAssignproducts, soList, name, userStatus, district, state, startDate, endDate } =
    props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = async () => {
    setShow(true);
  };
  const {
    getSalesPersonWithoutPageList,
    selectedDistributorWithoutPage,
    getProductsList,
  } = useSelector((state) => state.commonReducer);
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        <i className="bi bi-pencil-square"></i>
      </Button>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update DispatchBy</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            await dispatch(updateDispatchBy(values));
            resetForm({ values: "" });
            dispatch(getOrderListRest());
            clearTimeout(timeoutIdRef.current);
            timeoutIdRef.current = setTimeout(() => {
              dispatch(getDispatchBy({district, state, startDate, endDate}));
            }, 1000);
            setShow(false);
          }}
          initialValues={{
            password: "",
            id: id,
            status: userStatus,
            distributorList:
              !!distributorList && distributorList.map((data) => data?._id),
            salesmanIdList: !!soList && soList.map((data) => data),
            name,
            shyamg:
              !!userAssignproducts &&
              userAssignproducts.map((data) => {
                if (data?.itemName.match("WaahJi")) {
                  return null;
                } else {
                  return data?._id ? data?._id : data?.id;
                }
              }),
            waahJi:
              !!userAssignproducts &&
              userAssignproducts.map((data) => {
                if (!data?.itemName.match("WaahJi")) {
                  return null;
                } else {
                  return data?._id ? data?._id : data?.id;
                }
              }),
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
            <Form noValidate onSubmit={handleSubmit} className="px-3 pb-3">
              <Row className="pb-2">
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="pb-2">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      isInvalid={!!errors.password}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="pb-2">
                    <label>
                      <b>Select SO</b>
                    </label>
                    <Form.Control
                      as="select"
                      multiple={true}
                      onChange={handleChange}
                      name="salesmanIdList"
                      className="form-control"
                      value={values.salesmanIdList}
                      isInvalid={!!errors.salesmanIdList}
                    >
                      {!!getSalesPersonWithoutPageList &&
                        getSalesPersonWithoutPageList.map((data, index) => {
                          return (
                            <option
                              value={data?._id ? data?._id : data?.id}
                              key={index}
                            >
                              {data?.name}
                            </option>
                          );
                        })}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="pb-2">
                    <label>
                      <b>Select Distributors</b>
                    </label>
                    <Form.Control
                      as="select"
                      multiple={true}
                      onChange={handleChange}
                      name="distributorList"
                      className="form-control"
                      value={values.distributorList}
                      isInvalid={!!errors.distributorList}
                    >
                      {!!selectedDistributorWithoutPage &&
                        selectedDistributorWithoutPage.map((data) => {
                          return (
                            <option
                              value={data?._id}
                            >{`${data?.firmName}(${data?.address?.town})`}</option>
                          );
                        })}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6} sm={12}>
                  <Form.Label>
                    <b>Select ShyamG Products</b>
                  </Form.Label>
                  <br />
                  <Form.Control
                    as="select"
                    multiple={true}
                    name="shyamg"
                    value={values.shyamg}
                    onChange={handleChange}
                    className="form-control"
                  >
                    {!!getProductsList &&
                      getProductsList.map((data, index) => {
                        return (
                          !data?.itemName.match("WaahJi") && (
                            <option
                              className="first-latter-capital"
                              key={index}
                              value={data._id}
                            >
                              {data?.itemName}
                            </option>
                          )
                        );
                      })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.products}
                  </Form.Control.Feedback>
                </Col>
                <Col md={6} sm={12}>
                  <Form.Label>
                    <b>Select WaahJi Products</b>
                  </Form.Label>
                  <br />
                  <Form.Control
                    as="select"
                    multiple={true}
                    name="waahJi"
                    onChange={handleChange}
                    value={values.waahJi}
                    className="form-control"
                  >
                    {!!getProductsList &&
                      getProductsList.map((data, index) => {
                        return (
                          data?.itemName.match("WaahJi") && (
                            <option
                              className="first-latter-capital"
                              key={index}
                              value={data._id}
                            >
                              {data?.itemName}
                            </option>
                          )
                        );
                      })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.products}
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <Row>
                <Col md={6} sm={12}>
                  <Form.Label>Select Status</Form.Label>
                  <br />
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      await setFieldValue("status", e.target.value);
                    }}
                    name="status"
                    className="form-control"
                    value={values.status}
                    isInvalid={!!errors.status}
                  >
                    <option value="">Select Status</option>
                    <option value={true}>Active</option>
                    <option value={false}>Inactive</option>
                  </Form.Control>
                </Col>
              </Row>
              <Button type="submit" className="mt-1">
                Submit
              </Button>
              <Button variant="danger" onClick={handleClose} className="mt-1">
                Close
              </Button>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default SuperStockistsUpdate;
