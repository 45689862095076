import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import { Formik } from "formik";
import * as yup from "yup";
import {getCareerOpening} from "../reducers/commonReducer";
import Modal from 'react-bootstrap/Modal';

const schema = yup.object().shape({
  department: yup.string().required(),
  designation: yup.string().required(),
  requirement: yup.string().required(),
  experience: yup.string().required(),
  jdpdf: yup.mixed().required(),
  salary: yup.string().required(),
  location: yup.string().required(),

});

function CareerOpeningAction(props) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {id, title, api,department,
    designation,
    requirement,
    experience,
    jdpdf,
    salary,
    location,
    jobdescription} = props;
    const {departmentDropDownList } =
    useSelector((state) => state.commonReducer);
  return (
    <>
     <Button variant="primary" onClick={handleShow}>
        {title}
      </Button>
      <Modal size={'lg'} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title} Career Opening</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <div className="container-fluid">
                  <Formik
                    validationSchema={schema}
                    onSubmit={async (values, { resetForm }) => {
                     await dispatch(api(values))
                     await dispatch(getCareerOpening())
                     handleClose();
                      resetForm({ values: "" });
                    }}
                    initialValues={{
                    id,
                    department,
                    designation,
                    requirement,
                    experience,
                    jdpdf,
                    salary,
                    location,
                    jobdescription,
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      setFieldValue,
                      values,
                      errors,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Row className="mb-3">
                        <Col md={6} sm={12}>
                        <Form.Label>Select Department</Form.Label>
                          <Form.Control
                            as="select"
                            multiple={false}
                            name="department"
                            onChange={handleChange}
                            className="form-control"
                            value={values.department}
                            isInvalid={!!errors.department}
                          >
                                <option value="">Select Departments</option>
                                {
                                  !!departmentDropDownList && departmentDropDownList?.map((data, index) => <option key={index} value={data?.name}>{data?.name}</option>)
                                }
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {errors.department}
                          </Form.Control.Feedback>
                        </Col>
                          <Col md={6} sm={12}>
                            <Form.Group >
                              <Form.Label>Designation</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter your designation"
                                name="designation"
                                value={values.designation}
                                onChange={handleChange}
                                isInvalid={!!errors.designation}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.designation}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                        <Col md={6} sm={12}>
                            <Form.Group >
                              <Form.Label>Requirement</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Enter your Requirement"
                                name="requirement"
                                value={values.requirement}
                                onChange={handleChange}
                                isInvalid={!!errors.requirement}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.requirement}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md={6} sm={12}>
                            <Form.Group>
                              <Form.Label for="myfile">JD Pfd</Form.Label>
                              <Form.Control
                                id="myfile"
                                type="file"
                                name="jdpdf"
                                onChange={(event) => {
                                  const imageFile = event.target.files[0];
                                  setFieldValue("jdpdf", imageFile);
                                }}
                                isInvalid={!!errors.jdpdf}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.jdpdf}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                        <Col md={4} sm={12}>
                            <Form.Group >
                              <Form.Label>Experience</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter your experience"
                                name="experience"
                                value={values.experience}
                                onChange={handleChange}
                                isInvalid={!!errors.experience}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.experience}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md={4} sm={12}>
                            <Form.Group >
                              <Form.Label>Salary</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter your salary"
                                name="salary"
                                value={values.salary}
                                onChange={handleChange}
                                isInvalid={!!errors.salary}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.salary}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md={4} sm={12}>
                            <Form.Group >
                              <Form.Label>Location</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter your Location"
                                name="location"
                                value={values.location}
                                onChange={handleChange}
                                isInvalid={!!errors.location}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.location}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md={12} sm={12}>
                            <Form.Group >
                              <Form.Label>
                                Write Your Job Description.
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Write Your Job Description...."
                                name="jobdescription"
                                value={values.jobdescription}
                                onChange={handleChange}
                                isInvalid={!!errors.jobdescription}
                                as="textarea"
                                rows={3}
                              />

                              <Form.Control.Feedback type="invalid">
                                {errors.jobdescription}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Button type="submit">Submit</Button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Modal.Body>
              </Modal>
    </>
  );
}

export default CareerOpeningAction;
