import { toast } from "react-toastify"

export const messages = async (msg, apiStatus) => {
    if(apiStatus === 200){
        toast.success(msg + " 😊")
     }else{
         toast.error(msg + " 😔")
     }
}

export const postApi = async (api, type, body, successMsg)=>{
   const res = await fetch(api,{
         method:type,
         headers:{
             "Content-Type":"application/json",
             "x-auth-token":localStorage.getItem('x-auth-token')
         },
         body:JSON.stringify(body)
     })
     return await res.json()
 }

export const allApi = async (api, type, successMsg)=>{
    const res =  await fetch(api,{
         method:type,
         headers:{
             "Content-Type":"application/json",
             "x-auth-token":localStorage.getItem('x-auth-token')
         }
     })
     return await res.json()
 }
 export const postApiUploader = async (api, type, userDetails) => {
    const formData = new FormData();
    Object.entries(userDetails).map(([key, value]) => {
      formData.append(key, value);
    });
    const myHeaders = new Headers();
    myHeaders.append("x-auth-token", localStorage.getItem("x-auth-token"));
    const res = await fetch(api, {
      method: type,
      body: formData,
      headers: myHeaders,
    });
    return await res.json();
  };
 export const postApiFile = async (api, type, userDetails, successMsg)=>{
    const formData = new FormData();
    formData.append("resume", userDetails.resume);
    formData.append("department", userDetails.department);
    formData.append("email", userDetails.email);
    formData.append("name", userDetails.name);
    formData.append("number", userDetails.number);
    formData.append("reqQuestions", userDetails.reqQuestions);
    const res =  await fetch(api,{
         method: type,
         body : formData,
         headers:{
            // 'Accept': 'application/json',
            // "Content-Type": "multipart/form-data" ,
            //  "x-auth-token":localStorage.getItem('x-auth-token')
         }
     })
     return await res.json()
 }
 export const queryFileWithArray = async (api, type, details) => {
    const formData = new FormData();
    formData.append('extradata', JSON.stringify(details));
    Object.entries(details).forEach(([key, value]) => {
      if(key == 'resume') {   
        formData.append(key, value);
      }  
    });
    const myHeaders = new Headers();
    myHeaders.append("x-auth-token", localStorage.getItem("x-auth-token"));
    const res = await fetch(api, {
      method: type,
      body: formData,
      headers: myHeaders,
    });
    return await res.json();
  };
 export const postApiProduct= async (api, type, userDetails, successMsg)=>{
    const formData = new FormData();
    formData.append("comment", userDetails.comment);
    formData.append("district", userDetails.district);
    formData.append("email", userDetails.email);
    formData.append("name", userDetails.name);
    formData.append("firmName", userDetails.firmName);
    formData.append("complaintType", userDetails.complaintType);
    formData.append("number", userDetails.number);
    formData.append("state", userDetails.state);
    formData.append("town", userDetails.town);
    formData.append("image", userDetails.uploadedImg);
    const res =  await fetch(api,{
         method: type,
         body : formData,
         headers:{
            // 'Accept': 'application/json',
            // "Content-Type": "multipart/form-data" ,
            //  "x-auth-token":localStorage.getItem('x-auth-token')
         }
     })
     return await res.json()
 }