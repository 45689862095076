import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getDistrictList,
  getProducts,
  getSelectedDistributor,
  getOrderListRest,
  getStatesList,
} from "../reducers/commonReducer";
import moment from "moment";
import SideMenu from "../Components/SideMenu";
import { Row, Col, Form, Button } from "react-bootstrap";
import ExportData from "../Components/ExportData";
import CreateOrder from "../Components/CreateOrder";

const DistributorSalesManWise = () => {
  let params = useParams();
  const queryParameters = new URLSearchParams(window.location.search);
  const dispatchName = queryParameters.get("dispatch");
  const dispatch = useDispatch();
  const [state, setState] = useState();
  const [district, setDistrict] = useState();
  const [userStatus, setUserStatus] = useState();
  const [page, setPage] = useState(1);
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [startDate, setStartDate] = useState();
  const {
    getSelectedDistributorList,
    getDistrict,
    getSelectedDistributorListMsg,
    statesList,
  } = useSelector((state) => state.commonReducer);
  const header = [
    "Sr",
    "Name",
    // "UserName",
    "ContactNo",
    "Address",
    "Date",
    "Status",
    "GSTIN",
    "DispatchBy",
    "Email",
    "FirmName",
    "SalesMan Name",
  ];
  let body = [];
  !!getSelectedDistributorList &&
    getSelectedDistributorList.map((data, index) => {
      body.push({
        Sr: index + 1,
        Name: data.name,
        // UserName: data?.userName,
        ContactNo: data?.number,
        Address:
          data?.address?.town +
          ", " +
          data?.address?.district +
          ", " +
          data?.address?.state,
        Date: moment(data.createdAt).format("DD/MM/YYYY"),
        Status: data?.status,
        GSTIN: data?.GSTIN,
        dispatchBy: data?.dispatchBy,
        email: data?.email,
        firmName: data?.firmName,
        salesmanName: data?.salesmanName,
      });
    });
  useEffect(() => {
    dispatch(getOrderListRest());
    dispatch(
      getSelectedDistributor({
        salesmanName: params?.name,
        dispatch: dispatchName,
      })
    );
    dispatch(getStatesList());
    dispatch(getProducts());
  }, []);
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <Row className="mb-3">
          <Col md={2} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setState(e.target.value);
                dispatch(getOrderListRest());
                dispatch(
                  getSelectedDistributor({
                    state: e.target.value,
                    userStatus: userStatus,
                    salesmanName: params?.name,
                  })
                );
                e.target.value.length > 1 &&
                  dispatch(getDistrictList(e.target.value));
              }}
              name="state"
              className="form-control"
            >
              <option value="">Select State</option>
              {!!statesList &&
                statesList.map((data, index) => {
                  return (
                    <option key={index} value={data?.state}>
                      {data?.state}
                    </option>
                  );
                })}
            </Form.Control>
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setDistrict(e.target.value);
                dispatch(getOrderListRest());
                dispatch(
                  getSelectedDistributor({
                    state: state,
                    district: e.target.value,
                    userStatus: userStatus,
                    salesmanName: params?.name,
                  })
                );
              }}
              name="state"
              className="form-control"
            >
              <option value="">Select District</option>
              {!!getDistrict &&
                getDistrict.map((data, index) => {
                  return (
                    <option key={index} value={data}>
                      {data}
                    </option>
                  );
                })}
            </Form.Control>
          </Col>
          {/* <Col md={2} sm={12}>
            <Form.Control
              as="select"
              onChange={async (e) => {
                setUserStatus(e.target.value);
                dispatch(
                  getSelectedDistributor({
                    userStatus: e.target.value,
                  })
                );
              }}
              name="userStatus"
              className="form-control"
            >
              <option value="">Select Status</option>
              <option value="Active">Active</option>
              <option value={"Inactive"}>Inactive</option>
            </Form.Control>
          </Col> */}
          <Col md={2} sm={12}>
            <Form.Control
              type="date"
              placeholder="Start Date"
              name="endDate"
              onChange={async (e) => {
                setStartDate(e.target.value);
                dispatch(getOrderListRest());
                dispatch(
                  getSelectedDistributor({
                    userStatus: userStatus,
                    startDate: e.target.value,
                    endDate: endDate,
                    salesmanName: params?.name,
                  })
                );
              }}
              value={startDate}
            />
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              type="date"
              placeholder="End Date"
              name="startDate"
              onChange={async (e) => {
                setEndDate(e.target.value);
                dispatch(getOrderListRest());
                dispatch(
                  getSelectedDistributor({
                    userStatus: userStatus,
                    startDate: startDate,
                    endDate: e.target.value,
                    salesmanName: params?.name,
                  })
                );
              }}
              value={endDate}
            />
          </Col>
          <Col md="1">
            <Button
              variant="danger"
              onClick={() => window.location.reload(false)}
            >
              Clear
            </Button>
          </Col>
          <Col md={3} className="mbt-phone">
            <ExportData
              title="Export Distributor"
              header={header}
              body={body}
              tableName={"Distributor Salesman Wise"}
            />
          </Col>
        </Row>
        <table className="table table-scrolling">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Name</th>
              <th scope="col">FirmName</th>
              <th scope="col">Contact No.</th>
              <th scope="col">Address</th>
              <th scope="col">Date</th>
              <th scope="col">DispatchBy</th>
              <th scope="col">Orders</th>
              {localStorage.getItem("x-auth-token") &&
                (localStorage.getItem("userRole") === "7" ||
                  localStorage.getItem("userRole") === "10") && (
                  <th scope="col">Create Order</th>
                )}
            </tr>
          </thead>
          <tbody>
            {!!getSelectedDistributorList &&
              getSelectedDistributorList.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>{data.name}</td>
                    <td>{data?.firmName}</td>
                    <td>{data?.number}</td>
                    <td>
                      {data?.address?.town +
                        ", " +
                        data?.address?.district +
                        ", " +
                        data?.address?.state}
                    </td>
                    <td>{moment(data.createdAt).format("DD/MM/YYYY")}</td>
                    <td>
                      {!!data?.dispatchBy &&
                        data?.dispatchBy.map((data, index) => (
                          <span>{`${
                            data?.dispatch ? data?.dispatch : data?.userName
                          },`}</span>
                        ))}
                    </td>
                    <td>
                      {data?.other != 0 && (
                        <Link
                          to={`/distributor-orders/${data?.userName}?distributor=${data?.userName}&so=${data?.salesmanName?.userName}`}
                        >
                          <Button variant="primary" className="m-1">
                            Pending Order({data?.other})
                          </Button>
                        </Link>
                      )}
                      {data?.deliver != 0 && (
                        <Link
                          to={`/distributor-delivered-orders/${data?.userName}?distributor=${data?.userName}&so=${data?.salesmanName?.userName}`}
                          className="f-right"
                        >
                          <Button variant="success" className="m-1">
                            Delivered Order({data?.deliver})
                          </Button>
                        </Link>
                      )}
                    </td>
                    {localStorage.getItem("x-auth-token") &&
                      (localStorage.getItem("userRole") === "7" ||
                        localStorage.getItem("userRole") === "10") && (
                        <td>
                          <CreateOrder
                            town={data?.address?.town}
                            district={data?.address?.district}
                            state={data?.address?.state}
                            salesMan={params?.name}
                            distributor={data?.firmName}
                            number={data?.number}
                            firmName={data?.firmName}
                            userName={data?.userName}
                            dispatchBy={data?.dispatchBy}
                          />
                        </td>
                      )}
                  </tr>
                );
              })}
          </tbody>
        </table>
        {!!getSelectedDistributorListMsg &&
        getSelectedDistributorListMsg.length > 0 ? (
          <Row>
            <Button
              onClick={() => {
                setPage(page + 1);
                dispatch(
                  getSelectedDistributor({
                    salesmanName: params?.name,
                    dispatch: dispatchName,
                    page: page + 1,
                  })
                );
              }}
            >
              Load More
            </Button>
          </Row>
        ) : (
          <p className="text-center">
            <b>No record found 😔.</b>
          </p>
        )}
      </div>
    </>
  );
};

export default DistributorSalesManWise;
