import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCareerOpening,
  deleteCareerOpening,
  getStatesList,
  getDealershipOpening,
  addDealershipOpening,
  updateDealershipOpening,
  deleteDealershipOpening,
} from "../reducers/commonReducer";
import { useNavigate } from "react-router-dom";
import { Button, Row, Col } from "react-bootstrap";
import SideMenu from "../Components/SideMenu";
import moment from "moment";
import DilershipOpeningAction from "./DilershipOpeningAction";

const DilershipOpeningList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getDealershipOpening())
    dispatch(getStatesList())
  }, []);
  const { dealershipOpeningList } =
    useSelector((state) => state.commonReducer);
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <Row className="mb-3">
        <Col>
        <DilershipOpeningAction title={"Add"} api={addDealershipOpening}/>
        </Col>
        </Row>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Date</th>
              <th scope="col">Type</th>
              <th scope="col">State</th>
              <th scope="col">City</th>
              <th scope="col">Desc</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {!!dealershipOpeningList &&
              dealershipOpeningList.map((data, index) => {
                return <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{moment(data.updatedAt).format("DD/MM/YYYY")}</td>
                      <td>{data?.type}</td>
                      <td>{data?.state}</td>
                      <td>{data?.citys}</td>
                      <td>{data.desc}</td>
                      <td>{data.status ? <span className="text-success"><b>Active</b></span> : <span className="text-danger"><b>InActive</b></span>}</td>
                      <td>
                            <DilershipOpeningAction
                              id={data?._id}
                              type={data?.type}
                              state={data?.state}
                              citys={data?.citys}
                              desc={data?.desc}
                              status={data?.status}
                              title={"Update"}
                              api={updateDealershipOpening}
                              />
                              {(localStorage.getItem("x-auth-token") &&
                          (localStorage.getItem("userRole") === "1" || localStorage.getItem("userRole") === "9")) && (
                            <Button
                              className="m-1"
                              variant="danger"
                              onClick={async () => {
                                await(dispatch(deleteDealershipOpening(data?._id)));
                                dispatch(getDealershipOpening());
                              }}
                            >
                              <i className="bi bi-trash"></i>
                            </Button>
                          )}
                      </td>
                    </tr>
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default DilershipOpeningList;
