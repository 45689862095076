import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getContactList,
  deletContactRecord,
  getClientReviewList,
  addClientReview,
  updateClientReview,
} from "../reducers/commonReducer";
import { useNavigate } from "react-router-dom";
import { Button, Row, Col } from "react-bootstrap";
import SideMenu from "../Components/SideMenu";
import moment from "moment";
import ClientReviewAction from "./ClientReviewAction";

const WebBanners = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getClientReviewList())
  }, []);
  const { getClientReviewData } =
    useSelector((state) => state.commonReducer);
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <Row className="mb-3">
        <Col>
        <ClientReviewAction title={"Add"} api={addClientReview}/>
        </Col>
        </Row>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Name</th>
              <th scope="col">Profession</th>
              <th scope="col">Review</th>
              <th scope="col">Date</th>
             <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {!!getClientReviewData &&
              getClientReviewData.map((data, index) => {
                return <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{data?.name}</td>
                      <td>{data?.profession}</td>
                      <td>{data.desc}</td>
                      <td>{moment(data.updatedAt).format("DD/MM/YYYY")}</td>
                      <td>
                        {(localStorage.getItem("x-auth-token") &&
                          (localStorage.getItem("userRole") === "1" || localStorage.getItem("userRole") === "9")) && (
                            <Button
                              className="m-1"
                              variant="danger"
                              onClick={async () => {
                                await(dispatch(deletContactRecord(data?._id)));
                                dispatch(getContactList());
                              }}
                            >
                              <i className="bi bi-trash"></i>
                            </Button>
                          )}
                       
                            <ClientReviewAction
                              id={data?._id}
                              name={data?.name}
                              profession={data?.profession}
                              desc={data?.desc}
                              title={"Update"}
                              api={updateClientReview}
                              />
                      </td>
                    </tr>
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default WebBanners;
