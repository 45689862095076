import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as yup from "yup";
import {
  getDistrictList,
  getDispatchBy,
  createDispatchBy,
  getOrderListRest,
  getSalesPersonWithoutPage,
  getSelectedDistributorWithoutPage,
} from "../reducers/commonReducer";

const schema = yup.object().shape({
  dispatch: yup.string().required(),
  userName: yup.string().required(),
  password: yup.string().required(),
  town: yup.string().required(),
  district: yup.string().required(),
  state: yup.string().required(),
  ownerName: yup.string(),
  distributerList: yup.array(),
  salesmanIdList: yup.array(),
});

const AddSuperStockists = (props) => {
  const dispatch = useDispatch();
  const {
    getSalesPersonWithoutPageList,
    statesList,
    getDistrict,
    selectedDistributorWithoutPage,
    getProductsList,
  } = useSelector((state) => state.commonReducer);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="success mb-phone" onClick={handleShow}>
        Add SuperStockist
      </Button>
      <Modal size="lg" show={show} onHide={handleClose}>
        <div className="row px-2">
          <Row className="text-center">
            <h3 className="mb-4 mt-4">Add Super Stockist</h3>
          </Row>
          <div className="container-fluid">
            <Formik
              validationSchema={schema}
              onSubmit={async (values, { resetForm }) => {
                await dispatch(createDispatchBy(values));
                resetForm({ values: "" });
                setShow(false);
                dispatch(getOrderListRest());
                dispatch(getDispatchBy());
                dispatch(getSelectedDistributorWithoutPage());
                dispatch(getSalesPersonWithoutPage());
              }}
              initialValues={{
                dispatch: "",
                town: "",
                district: "",
                state: "",
                ownerName: "",
                password: "",
                distributerList: [],
                salesmanIdList: [],
                userName: "",
                shyamg: [],
                waahJi: [],
              }}
            >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                values,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label>Dispatch By Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Dispatch By Name"
                          name="dispatch"
                          value={values.dispatch}
                          onChange={handleChange}
                          isInvalid={!!errors.dispatch}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.dispatch}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label>Town</Form.Label>
                        <br />
                        <Form.Control
                          type="text"
                          placeholder="Town"
                          name="town"
                          value={values.town}
                          onChange={handleChange}
                          isInvalid={!!errors.town}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.town}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={6} sm={12}>
                      <Form.Label>Select Salesman</Form.Label>
                      <br />
                      <Form.Control
                        as="select"
                        multiple={true}
                        name="salesmanIdList"
                        onChange={handleChange}
                        className="form-control"
                        value={values.salesmanIdList}
                        isInvalid={!!errors.salesmanIdList}
                      >
                        {!!getSalesPersonWithoutPageList &&
                          getSalesPersonWithoutPageList.map((data, index) => {
                            return (
                              <option
                                className="first-latter-capital"
                                key={index}
                                value={data.id ? data.id : data._id}
                              >
                                {data?.name}
                              </option>
                            );
                          })}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.salesmanIdList}
                      </Form.Control.Feedback>
                    </Col>
                    {!!values.salesmanIdList && (
                      <Col md={6} sm={12}>
                        <Form.Label>Select Distributors</Form.Label>
                        <br />
                        <Form.Control
                          as="select"
                          multiple={true}
                          onChange={handleChange}
                          name="distributerList"
                          className="form-control"
                          value={values.distributerList}
                          isInvalid={!!errors.distributerList}
                        >
                          {!!selectedDistributorWithoutPage &&
                            selectedDistributorWithoutPage.map(
                              (data, index) => {
                                return (
                                  <option
                                    className="first-latter-capital"
                                    key={index}
                                    value={data._id}
                                  >
                                    {data?.firmName}
                                  </option>
                                );
                              }
                            )}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.distributerList}
                        </Form.Control.Feedback>
                      </Col>
                    )}
                  </Row>
                  <Row className="mb-3">
                    <Col md={6} sm={12}>
                      <Form.Label>State</Form.Label>
                      <br />
                      <Form.Control
                        style={{ textTransform: "capitalize" }}
                        as="select"
                        onChange={async (e) => {
                          await setFieldValue("state", e.target.value);
                          dispatch(getDistrictList(e.target.value));
                        }}
                        name="state"
                        className="form-control"
                        value={values.state}
                        isInvalid={!!errors.state}
                      >
                        <option value="">Select State</option>
                        {statesList.map((data, index) => {
                          return (
                            <option
                              className="first-latter-capital"
                              key={index}
                              value={data.state}
                            >
                              {data.state}
                            </option>
                          );
                        })}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.state}
                      </Form.Control.Feedback>
                    </Col>
                    <Col md={6} sm={12}>
                      <Form.Label>District</Form.Label>
                      <br />
                      <Form.Control
                        style={{ textTransform: "capitalize" }}
                        as="select"
                        name="district"
                        onChange={handleChange}
                        value={values.district}
                        className="form-control"
                        isInvalid={!!errors.district}
                      >
                        <option value="">Select District</option>
                        {!!getDistrict &&
                          getDistrict.map((data, index) => {
                            return (
                              <option
                                className="first-latter-capital"
                                value={data}
                                key={index}
                              >
                                {data}
                              </option>
                            );
                          })}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.district}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={12} sm={12}>
                      <Form.Group>
                        <Form.Label>OwnerName</Form.Label>
                        <br />
                        <Form.Control
                          type="text"
                          placeholder="OwnerName"
                          name="ownerName"
                          value={values.ownerName}
                          onChange={handleChange}
                          isInvalid={!!errors.ownerName}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} sm={12}>
                      <Form.Label>Select ShyamG Products</Form.Label>
                      <br />
                      <Form.Control
                        as="select"
                        multiple={true}
                        name="shyamg"
                        onChange={handleChange}
                        className="form-control"
                      >
                        {!!getProductsList &&
                          getProductsList.map((data, index) => {
                            return (
                              !data?.itemName.match("WaahJi") && (
                                <option
                                  className="first-latter-capital"
                                  key={index}
                                  value={data.id ? data.id : data._id}
                                >
                                  {data?.itemName}
                                </option>
                              )
                            );
                          })}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.products}
                      </Form.Control.Feedback>
                    </Col>
                    <Col md={6} sm={12}>
                      <Form.Label>Select WaahJi Products</Form.Label>
                      <br />
                      <Form.Control
                        as="select"
                        multiple={true}
                        name="waahJi"
                        onChange={handleChange}
                        className="form-control"
                      >
                        {!!getProductsList &&
                          getProductsList.map((data, index) => {
                            return (
                              data?.itemName.match("WaahJi") && (
                                <option
                                  className="first-latter-capital"
                                  key={index}
                                  value={data.id ? data.id : data._id}
                                >
                                  {data?.itemName}
                                </option>
                              )
                            );
                          })}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.products}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={6} sm={12}>
                      <Form.Group>
                        <Form.Label className="text-danger">
                          Unique UserName(For Login)
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="userName"
                          name="userName"
                          value={values.userName
                            .toLowerCase()
                            .replace(/ /g, "-")}
                          onChange={handleChange}
                          isInvalid={!!errors.userName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.userName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                      <Form.Group >
                        <Form.Label className="text-danger">
                          Please enter unique Password.
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          isInvalid={!!errors.password}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button type="submit" className="mt-3">
                    Submit
                  </Button>
                  <Button
                    variant="danger"
                    onClick={handleClose}
                    className="mt-3"
                  >
                    Close
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddSuperStockists;
