import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import { Formik } from "formik";
import * as yup from "yup";
import {
  ContactForm, getClientReviewList,
} from "../reducers/commonReducer";
import Modal from 'react-bootstrap/Modal';

const schema = yup.object().shape({
    name: yup.string().required(),
  profession: yup.string().required(),
  desc: yup.string().required(),
});

function ClientReviewAction(props) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {id, name, profession, desc, title, api} = props;
  return (
    <>
     <Button variant="primary" onClick={handleShow}>
        {title}
      </Button>
      <Modal size={'lg'} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title} Review</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <div className="container-fluid">
                  <Formik
                    validationSchema={schema}
                    onSubmit={async (values, { resetForm }) => {
                     await dispatch(api(values))
                     await dispatch(getClientReviewList())
                     handleClose();
                      resetForm({ values: "" });
                    }}
                    initialValues={{
                    id,
                    name,
                    profession,
                    desc
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      values,
                      errors,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Row className="mb-3">
                          <Col md={6} sm={12}>
                            <Form.Group >
                              <Form.Label>Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Name"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                isInvalid={!!errors.name}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.name}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md={6} sm={12}>
                            <Form.Group >
                              <Form.Label>Profession</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter your profession"
                                name="profession"
                                value={values.profession}
                                onChange={handleChange}
                                isInvalid={!!errors.profession}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.profession}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md={12} sm={12}>
                            <Form.Group >
                              <Form.Label>
                                Write Your Review.
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Write Your Review...."
                                name="desc"
                                value={values.desc}
                                onChange={handleChange}
                                isInvalid={!!errors.desc}
                                as="textarea"
                                rows={3}
                              />

                              <Form.Control.Feedback type="invalid">
                                {errors.desc}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Button type="submit">Submit</Button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Modal.Body>
              </Modal>
    </>
  );
}

export default ClientReviewAction;
