import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addNewProduct, deleteProductRecord, getCategoryList, getGroupsList, getProducts, updateProduct } from "../reducers/commonReducer";
import moment from "moment";
import SideMenu from "../Components/SideMenu";
import { Row, Col, Button } from "react-bootstrap";
import ProductAction from "./ProductAction";
import ExportData from "../Components/ExportData";

const Products = () => {
  const dispatch = useDispatch();
  const { getProductsList } = useSelector((state) => state.commonReducer);
  const header = ["Sr", "ProductName", "Price", "Date"];
  let body = [];
  !!getProductsList &&
    getProductsList.map((data, index) => {
      body.push({
        Sr: index + 1,
        ProductName: data?.itemName,
        Price: data?.prise + "/-",
        Date: moment(data.createdAt).format("DD/MM/YYYY"),
      });
    });
  useEffect(() => {
    dispatch(getProducts());
    dispatch(getCategoryList({
      status:true
    }))
    dispatch(getGroupsList({
      status:true
    }))
  }, []);
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <Row className="mb-3">
          <Col md={6} sm={12}>
            {localStorage.getItem("userName") == "masteradmin" && <ProductAction api={addNewProduct} title={"Add"}/>}
          </Col>
          <Col md={6} sm={12} className="p-mobile">
            <ExportData title="Export Products" header={header} body={body} />
          </Col>
        </Row>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Product Name</th>
              <th scope="col">Image</th>
              <th scope="col">Price</th>
              <th scope="col">Date</th>
              <th scope="col">Categories</th>
              <th scope="col">Group</th>
              {localStorage.getItem("x-auth-token") &&
                (localStorage.getItem("userRole") == 1 || localStorage.getItem("userRole") == 11) && (
                  <th scope="col">Action</th>
                )}
            </tr>
          </thead>
          <tbody>
            {!!getProductsList &&
              getProductsList.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>{data?.itemName}</td>
                    <td><img className="table-img" src={`/${data?.image}`}/></td>
                    <td>{data?.prise + "/-"}</td>
                    <td>{moment(data.createdAt).format("DD/MM/YYYY")}</td>
                    <td>{data?.category?.name}</td>
                    <td>{data?.group?.name}</td>
                    {localStorage.getItem("x-auth-token") &&
                       (localStorage.getItem("userRole") == 1 || localStorage.getItem("userRole") == 11) && (
                        <td>
                          <ProductAction api={updateProduct} title={"Edit"} id={data?._id} category={data?.category?._id} 
                          group={data?.group?._id} itemName={data?.itemName} prise={data?.prise}/>
                          {localStorage.getItem("userName") == "masteradmin" && <Button
                            className="m-1"
                            variant="danger"
                            onClick={async () => {
                              await dispatch(deleteProductRecord(data?._id));
                              dispatch(getProducts());
                            }}
                          >
                            <i className="bi bi-trash"></i>
                          </Button>}
                        </td>
                      )}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Products;
