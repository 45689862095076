import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  baseUrl,
  getASMPersonsWithOutPage,
  getAsmProductReporting,
  getOrderListRest,
  getSalesPersonWithoutPage,
  getStatesList,
} from "../reducers/commonReducer";
import moment from "moment";
import SideMenu from "../Components/SideMenu";
import { Row, Col, Form, Button } from "react-bootstrap";
import { commonFunction } from "./commonFunction/commonFunction";

const AsmDistributorWiseProductReport = () => {
  const dispatch = useDispatch();
  const [startMonth, setStartMonth] = useState();
  const [endMonth, setEndMonth] = useState();
  const [userName, setUserName] = useState();
  const [year, setYear] = useState(moment().format("YYYY"));
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const [soName, setSoName] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedSoOption, setSelectedSoOption] = useState(null);

  const {
    asmPersonsWithOutPage,
    getSalesPersonWithoutPageList,
    getAsmProductReportingList,
  } = useSelector((state) => state.commonReducer);
  useEffect(() => {
    dispatch(getOrderListRest());
    {
      localStorage.getItem("x-auth-token") &&
        localStorage.getItem("userRole") === "1" &&
        dispatch(getASMPersonsWithOutPage());
    }
    dispatch(getSalesPersonWithoutPage());
    dispatch(getStatesList());
    dispatch(getAsmProductReporting({ year: moment().format("YYYY") }));
  }, []);

  const handleSelectOption = (event) => {
    const option = event.target.options[event.target.selectedIndex].dataset;
    setSelectedOption(option);
    setUserName(event.target.value);
  };
  const handleSoSelectOption = (event) => {
    const option = event.target.options[event.target.selectedIndex].dataset;
    setSelectedSoOption(option);
    setSoName(event.target.value);
  };

  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <Form action={`${baseUrl}/api/asmproducts/csv`}>
          <Row className="mb-3">
            {localStorage.getItem("x-auth-token") &&
              localStorage.getItem("userRole") === "1" && (
                <Col md={3} sm={12}>
                  <Form.Control
                    as="select"
                    onChange={handleSelectOption}
                    name="asm"
                    className="form-control"
                  >
                    <option value={"All"}>Select ASM</option>
                    {!!asmPersonsWithOutPage &&
                      asmPersonsWithOutPage.map((data, index) => {
                        return (
                          <option
                            key={index}
                            data-town={data?.address?.town}
                            data-district={data?.address?.district}
                            data-userState={data?.address?.state}
                            data-email={data?.email}
                            data-number={data?.number}
                            value={data?.dispatch}
                          >
                            {data?.userName}
                          </option>
                        );
                      })}
                  </Form.Control>
                </Col>
              )}
            <Col md={3} sm={12}>
              <Form.Control
                as="select"
                onChange={handleSoSelectOption}
                name="salesMan"
                className="form-control"
              >
                <option value={"All"}>Select SO</option>
                {!!getSalesPersonWithoutPageList &&
                  getSalesPersonWithoutPageList.map((data, index) => {
                    return (
                      <option
                        key={index}
                        data-town={data?.address?.town}
                        data-district={data?.address?.district}
                        data-userState={data?.address?.state}
                        data-sonumber={data?.number}
                        value={data?.userName}
                      >
                        {data?.name}
                      </option>
                    );
                  })}
              </Form.Control>
            </Col>
            <Col md={3} sm={12}>
              <Form.Control
                as="select"
                onChange={async (e) => {
                  setStartMonth(e.target.value);
                  commonFunction(e.target.value);
                }}
                name="startMonth"
                className="form-control"
              >
                <option value="">StartMonth</option>
                <option value="1">Jan</option>
                <option value="2">Feb</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">Aug</option>
                <option value="9">Sep</option>
                <option value="10">Oct</option>
                <option value="11">Nov</option>
                <option value="12">Dec</option>
              </Form.Control>
            </Col>
            <Col md={3} sm={12}>
              <Form.Control
                as="select"
                onChange={async (e) => {
                  setEndMonth(e.target.value);
                }}
                name="endMonth"
                className="form-control"
              >
                <option value="">EndMonth</option>
                <option value="1">Jan</option>
                <option value="2">Feb</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">Aug</option>
                <option value="9">Sep</option>
                <option value="10">Oct</option>
                <option value="11">Nov</option>
                <option value="12">Dec</option>
              </Form.Control>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col md={2} sm={12}>
              <label>Start Date</label>
              <Form.Control
                type="date"
                placeholder="Start Date"
                name="startDate"
                value={startDate}
                onChange={async (e) => {
                  setStartDate(e.target.value);
                }}
              />
            </Col>
            <Col md={2} sm={12}>
              <label>End Date</label>
              <Form.Control
                type="date"
                placeholder="End Date"
                name="endDate"
                value={endDate}
                onChange={async (e) => {
                  setEndDate(e.target.value);
                }}
              />
            </Col>
            <Col md={1} sm={12} className="mt-4">
              <Form.Control
                type="text"
                placeholder="Year"
                name="year"
                value={year}
                onChange={async (e) => {
                  setYear(e.target.value);
                }}
              />
            </Col>
            <Col md="1" className="mt-4">
              <Button
                variant="success"
                onClick={() => {
                  dispatch(
                    getAsmProductReporting({
                      asm: userName,
                      startMonth: startMonth,
                      endMonth: endMonth,
                      year: year,
                      salesMan: soName,
                      startDate,
                      endDate,
                    })
                  );
                }}
              >
                Submit
              </Button>
            </Col>
            <Col md="1" className="mt-4">
              <Button
                variant="danger"
                onClick={() => window.location.reload(false)}
              >
                Clear
              </Button>
            </Col>
            <Col md="2" className="mt-4">
              <Button variant="warning" type="submit">
                Export Export
              </Button>
            </Col>
          </Row>
        </Form>
        {localStorage.getItem("x-auth-token") &&
          localStorage.getItem("userRole") === "1" && (
            <Row>
              <h4>
                <b>Product Wise Sale</b>
              </h4>
              <Col md={6}>
                <p className="mb-0">
                  <b>ASM: </b> {userName}
                </p>
                <p className="mb-0">
                  <b>Address: </b>{" "}
                  {!!selectedOption?.town &&
                    selectedOption?.town +
                      ", " +
                      selectedOption?.district +
                      ", " +
                      selectedOption?.userstate}
                </p>
                <p className="mb-0">
                  <b>Mobile No.: </b> {selectedOption?.number}
                </p>
                <p className="mb-0">
                  <b>Year: </b> {year}
                </p>
              </Col>
              <Col md={6}>
                <p className="mb-0">
                  <b>SO: </b> {soName}
                </p>
                <p className="mb-0">
                  <b>Address: </b>{" "}
                  {!!selectedSoOption?.town &&
                    selectedSoOption?.town +
                      ", " +
                      selectedSoOption?.district +
                      ", " +
                      selectedSoOption?.userstate}
                </p>
                <p className="mb-0">
                  <b>Mobile No.: </b> {selectedSoOption?.sonumber}
                </p>
                <p className="mb-0">
                  <b>Year: </b> {year}
                </p>
              </Col>
            </Row>
          )}
        <table className="table table-scrolling">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Party Name</th>
              <th scope="col">Address</th>
              <th scope="col">SO</th>
              {/* <th scope="col">ASM</th> */}
              <th scope="col">DispatchBy</th>
              {!!getAsmProductReportingList &&
                !!getAsmProductReportingList?.products &&
                Object.entries(getAsmProductReportingList?.products).map(
                  ([key, value]) => <th scope="col">{value}</th>
                )}
              <th scope="col">Total CB</th>
            </tr>
          </thead>
          <tbody>
            {!!getAsmProductReportingList &&
              !!getAsmProductReportingList?.productWiseOrder &&
              Object.values(getAsmProductReportingList?.productWiseOrder).map(
                (data, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <td>{index + 1}.</td>
                        <td>{data?.firmName}</td>
                        <td>{`${data?.address?.town}, ${data?.address?.district}, ${data?.address?.state}`}</td>
                        <td>{data?.salesManName}</td>
                        {/* <td>{data?.asmUser?.name}</td> */}
                        <td>{data?.dispatchUser?.dispatch}</td>
                        {!!getAsmProductReportingList?.products &&
                          Object.keys(getAsmProductReportingList?.products).map(
                            function (key, value) {
                              return <td>{data[key]}</td>;
                            }
                          )}
                        <td>{data?.cbtotal}</td>
                      </tr>
                    </>
                  );
                }
              )}
            <tr>
              <td colSpan="5" className="text-center">
                <b>Total</b>
              </td>
              {!!getAsmProductReportingList &&
                !!getAsmProductReportingList?.total &&
                Object.keys(getAsmProductReportingList?.products).map(function (
                  key,
                  value
                ) {
                  return (
                    <td>
                      <b>{getAsmProductReportingList?.total[key]}</b>
                    </td>
                  );
                })}
              <td>
                <b>
                  {!!getAsmProductReportingList &&
                    getAsmProductReportingList?.total?.cbTotel}
                </b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AsmDistributorWiseProductReport;
