import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  distributorUpdate,
  getDistributorList,
  getOrderListRest,
} from "../reducers/commonReducer";

function DistributorUpdate(props) {
  const dispatch = useDispatch();
  const { userStatus, userComment, filterStatus, id, userDistance, userExactParty, states, district, startDate, endDate } = props;
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState(userStatus);
  const [comment, setComment] = useState(userComment);
  const [distance, setDistance] = useState(userDistance);
  const [exactParty, setExactParty] = useState(userExactParty);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const updateUser = async () => {
     dispatch(
     await distributorUpdate({ status, comment, id, distance, exactParty })
    );
    dispatch(await getOrderListRest());
    dispatch(await getDistributorList({userStatus, status:filterStatus, state:states, district, startDate, endDate}));
    setShow(false);
  };
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        <i className="bi bi-pencil-square"></i>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Distributor</Modal.Title>
        </Modal.Header>
        <Form.Group className="p-3">
          <Form.Label>Enter Your Comment</Form.Label>
          <Form.Control
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="p-3">
          <Form.Label>Enter Distance</Form.Label>
          <Form.Control
            value={distance}
            onChange={(e) => setDistance(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="p-3">
          <Form.Label>Enter Exact Party</Form.Label>
          <Form.Control
            value={exactParty}
            onChange={(e) => setExactParty(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="p-3">
          <Form.Select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="">Select User Status</option>
            <option value={"Pending"}>Pending</option>
            {/* <option value={"Selected"}>Selected</option> */}
            <option value={"Confirmd"}>Confirmd</option>
            <option value={"Read"}>Read</option>
            <option value={"Discussion"}>Discussion</option>
            <option value={"ForFuture"}>For Future</option>
            <option value={"Reject"}>Reject</option>
            <option value={"Warm"}>Warm</option>
            <option value={"Hot"}>Hot</option>
          </Form.Select>
        </Form.Group>
        <Modal.Footer>
          <Button variant="primary" onClick={updateUser}>
            Save
          </Button>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DistributorUpdate;
