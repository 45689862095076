import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addNewProduct, addSeniorStateDistributor, deleteProductRecord, getCategoryList, getGroupsList, getProducts, getStatesList, seniorStateDistributor, updateProduct, updateSeniorStateDistributor } from "../reducers/commonReducer";
import moment from "moment";
import SideMenu from "../Components/SideMenu";
import { Row, Col, Button } from "react-bootstrap";
import StateWiseDistributorSeniorAction from "./StateWiseDistributorSeniorAction";

const StateWiseDistributorSenior = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStatesList());
    dispatch(seniorStateDistributor())
  }, []);
  const { seniorStateDistributors } = useSelector((state) => state.commonReducer);
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <Row className="mb-3">
          <Col md={6} sm={12}>
            <h3>State Wise Distributor Senior</h3>
          </Col>
          <Col md={6} sm={12} >
            <StateWiseDistributorSeniorAction api={addSeniorStateDistributor} title={"Add"}/>
          </Col>
        </Row>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Name</th>
              <th scope="col">UserName</th>
              <th scope="col">Password</th>
              <th scope="col">Phone</th>
              <th scope="col">State</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {!!seniorStateDistributors &&
              seniorStateDistributors?.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>{data?.name}</td>
                    <td>{data?.userName}</td>
                    <td>{data?.realPassword}</td>
                    <td>{data?.number}</td>
                    <td>{data?.state?.state}</td>
                    <td>{data?.status ? <span className="text-success">Active</span> : <span className="text-danger">InActive</span>}</td>
                    <td>
                      <StateWiseDistributorSeniorAction 
                        api={updateSeniorStateDistributor} title={"Edit"} 
                        id={data?._id}
                        name={data?.name}
                        phone={data?.number}
                        state={data?.state?._id}
                        status={data?.status}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default StateWiseDistributorSenior;
