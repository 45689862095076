import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { complaintUpdate, userComplaintList } from "../reducers/commonReducer";

function EditComplaint(props) {
  const dispatch = useDispatch();
  const { complaintUpdateMsg } = useSelector((state) => state.commonReducer);
  const { userStatus, adminCmnt, id, state, district, endDate, startDate,filterStatus } = props;
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState(userStatus);
  const [adminComment, setAdminComment] = useState(adminCmnt);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const updateUser = async () => {
    await dispatch(complaintUpdate({ adminComment, status, id }));
    dispatch(userComplaintList({
      state, district, endDate, startDate,userStatus:filterStatus
    }));
    setShow(false);
  };
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        <i className="bi bi-pencil-square"></i>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Complaint Status</Modal.Title>
        </Modal.Header>
        <Form.Group className="p-3">
          <Form.Label>Comment</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={adminComment}
            onChange={(e) => setAdminComment(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="p-3">
          <Form.Select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="">Select User Status</option>
            <option value={"Pending"}>Pending</option>
            <option value={"Resolved"}>Resolved</option>
            <option value={"Discussion"}>Discussion</option>
            <option value={"Reject"}>Reject</option>
          </Form.Select>
        </Form.Group>
        {/* <p className="p-3"><b>{complaintUpdateMsg}</b></p> */}
        <Modal.Footer>
          <Button variant="success" onClick={updateUser}>
            Save
          </Button>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditComplaint;
