import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIndustrialVisitList, industrialVisitDelete } from "../reducers/commonReducer";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button, Form } from "react-bootstrap";
import SideMenu from "../Components/SideMenu";
import moment from "moment";
import ExportData from "../Components/ExportData";
import IndustrialVisitAction from "./IndustrialVisitAction";

const IndustrialVisitList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [startDate, setStartDate] = useState();
  const [status, setStatus] = useState();
 
  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(getIndustrialVisitList())
  }, []);
  const { getIndustrialVisitData } =
    useSelector((state) => state.commonReducer);
    const header = [
      "Sr",
      "Industry/School Name",
      "Address",
      "Phone",
      "Email",
      "Standard",
      "No. Of Person",
      "Date"
    ];
  let body = [];
!!getIndustrialVisitData && getIndustrialVisitData?.map((data, index) => {
  body.push({
        Sr: index + 1,
        IndustrySchoolName: data?.name,
        Address: `${data?.address}, ${data?.city} - ${data?.pinCode}, ${data?.state}`,
        Phone: data?.phone,
        Email: data?.email,
        Standard: data?.standerd,
        NoOfPerson : data?.number,
        Date:moment(data.createdAt).format("DD/MM/YYYY")
      });
    });
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
      <Row className="mb-3">
          <Col md={2} sm={12}>
            <Form.Control
              style={{ textTransform: "capitalize" }}
              as="select"
              onChange={async (e) => {
                setStatus(e.target.value);
                dispatch(
                  getIndustrialVisitList({
                    status: e.target.value,
                    startDate, endDate
                  })
                );
              }}
              name="status"
              className="form-control"
            >
              <option value="">Select Status</option>
              <option value="PENDING">PENDING</option>
              <option value="DISCUSSION">DISCUSSION</option>
              <option value="SLOT ALLOTED">SLOT ALLOTED</option>
              <option value="VISIT DONE">VISIT DONE</option>
              <option value="REJECTED">REJECTED</option>
              <option value="DENIED">DENIED</option>
            </Form.Control>
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              type="date"
              placeholder="Start Date"
              name="startDate"
              onChange={async (e) => {
                setStartDate(e.target.value);
                dispatch(
                  getIndustrialVisitList({
                    status,
                    startDate: e.target.value,
                    endDate,
                  })
                );
              }}
              value={startDate}
            />
          </Col>
          <Col md={2} sm={12}>
            <Form.Control
              type="date"
              placeholder="End Date"
              name="endDate"
              onChange={async (e) => {
                setEndDate(e.target.value);
                dispatch(
                  getIndustrialVisitList({
                    status,
                    startDate,
                    endDate: e.target.value,
                  })
                );
              }}
              value={endDate}
            />
          </Col>
          <Col md="1">
            <Button
              variant="danger"
              onClick={() => window.location.reload(false)}
            >
              Clear
            </Button>
          </Col>
          <Col md={2}>
            <ExportData title="Export" header={header} body={body} tableName="Industrial Visit"/>
          </Col>
        </Row>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Industry/School Name</th>
              <th scope="col">Address</th>
              <th scope="col">Phone</th>
              <th scope="col">Email</th>
              <th scope="col">Standard</th>
              <th scope="col">No. of Person</th>
              <th scope="col">Referral Website</th>
              <th scope="col">Date</th>
              <th scope="col">Status</th>
              <th scope="col">Comment</th>
              {
                ((localStorage.getItem("userRole") === "1") || (localStorage.getItem("userRole") === "4")) && <th scope="col">Action</th>
              }
            </tr>
          </thead>
          <tbody>
            {!!getIndustrialVisitData &&
              getIndustrialVisitData.map((data, index) => {
                return <tr key={index}>
                      <td>{index + 1}.</td>
                      <td><b>{data?.name}</b></td>
                      <td>{`${data?.address}, ${data?.city} - ${data?.pinCode}, ${data?.state}`}</td>
                      <td>{data.phone}</td>
                      <td>{data.email}</td>
                      <td>{data.standerd}</td>
                      <td><b>{data.number}</b></td>
                       <td><b>{data.websiteUrl}</b></td>
                      <td>{moment(data.createdAt).format("DD/MM/YYYY")}</td>
                      <td><b>{((data?.status === "PENDING") && <span className="text-primary">PENDING</span>) ||
                          ((data?.status === "DISCUSSION") && <span className="text-warning">DISCUSSION</span>) ||
                          ((data?.status === "SLOT ALLOTED") && <span className="text-info">SLOT ALLOTED</span>) ||
                          ((data?.status === "VISIT DONE") && <span className="text-success">VISIT DONE</span>) ||
                          ((data?.status === "REJECTED") && <span className="text-danger">REJECTED</span>) ||
                          ((data?.status === "DENIED") && <span className="text-secondary">DENIED</span>)}</b></td>
                      <td>
                        {data?.comment}
                      </td>
                      {(localStorage.getItem("userRole") === "1") && <td>
                        <Button
                            className="m-1"
                            variant="danger"
                            onClick={async () => {
                              await dispatch(industrialVisitDelete(data?._id));
                              dispatch(getIndustrialVisitList());
                            }}
                          >
                            <i className="bi bi-trash"></i>
                        </Button>
                      <IndustrialVisitAction id={data?._id} status={data?.status} startDate={startDate} endDate={endDate} filterStatus={status} comment={data?.comment}/>
                    </td>
                      }
                      {(localStorage.getItem("userRole") === "4") && <td>
                      <IndustrialVisitAction id={data?._id} status={data?.status} startDate={startDate} endDate={endDate} filterStatus={status} comment={data?.comment}/>
                    </td>
                      }
                    </tr>
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default IndustrialVisitList;
