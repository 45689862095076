import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import {
  getSelectedDistributor,
  getOrderListRest,
  updateSelectedDistributor,
  getDispatchByWithOutPage,
  getDistrictList,
} from "../reducers/commonReducer";

const schema = yup.object().shape({
  status: yup.string(),
  password: yup.string(),
  id: yup.string().required(),
  salesmanName: yup.string(),
  number: yup.string().min(10).max(10).required(),
  GSTIN: yup.string(),
  name: yup.string().required(),
  email: yup.string(),
  firmName: yup.string(),
  dispatchStatus: yup.string().required(),
  dispatchBy: yup
    .array()
    .max(2, "Please select no more than two dispatchBy")
    .required("Please select at least one dispatchBy"),
});

function UpdateSelectedDistributor(props) {
  const timeoutIdRef = React.useRef();
  let params = useParams();
  const dispatch = useDispatch();
  const { getSalesPersonWithoutPageList } = useSelector(
    (state) => state.commonReducer
  );
  const {
    userStatus,
    id,
    userSalesmanName,
    dispatchList,
    name,
    number,
    gst,
    email,
    firmName,
    dispatchStatus,
    uaddress,status,state, district, endDate, startDate
  } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { dispatchByWithOutPage, statesList,getDistrict } = useSelector((state) => state.commonReducer);
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        <i className="bi bi-pencil-square"></i>
      </Button>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Distributor</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            await dispatch(updateSelectedDistributor(values));
            resetForm({ values: "" });
            dispatch(getOrderListRest());
            clearTimeout(timeoutIdRef.current);
            timeoutIdRef.current = setTimeout(() => {
              dispatch(
                getSelectedDistributor({
                  salesmanName: params?.name,
                  mode: "OffLine",
                  status,state, district, endDate, startDate
                })
              );
            }, 1000);
            setShow(false);
          }}
          initialValues={{
            status: userStatus,
            password: "",
            id: id,
            salesmanName: userSalesmanName?.id
              ? userSalesmanName?.id
              : userSalesmanName?._id,
            dispatchBy:
              !!dispatchList &&
              dispatchList.map((data) => (data?._id ? data?._id : data?.id)),
            name,
            number,
            GSTIN: gst,
            email,
            firmName,
            dispatchStatus,
            town:uaddress?.town,
            district:uaddress?.district,
            state:uaddress?.state,
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
            <Form noValidate onSubmit={handleSubmit} className="px-3 pb-3">
              <Row className="pb-2">
                <Col md={6}>
                  <Form.Group>
                    <label>Status</label>
                    <Form.Control
                      as="select"
                      onChange={handleChange}
                      name="status"
                      className="form-control"
                      value={values.status}
                      isInvalid={!!errors.status}
                    >
                      <option value="">Select Status</option>
                      <option value={"Active"}>Active</option>
                      <option value={"Inactive"}>Inactive</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <label>Name</label>
                    <Form.Control
                      type="text"
                      placeholder="name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12} sm={12}>
                  <Form.Label className="text-danger">
                    You are SuperStockist
                  </Form.Label>
                  <br />
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      await setFieldValue(
                        "dispatchStatus",
                        /true/.test(e.target.value)
                      );
                    }}
                    name="dispatchStatus"
                    className="form-control"
                    value={values.dispatchStatus}
                    isInvalid={!!errors.dispatchStatus}
                  >
                    <option value="">Select User Type</option>
                    <option className="first-latter-capital" value={false}>
                      No, I am Distributor
                    </option>
                    <option className="first-latter-capital" value={true}>
                      Yes, I am SuperStockist
                    </option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.dispatchStatus}
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <Row className="pb-2">
                <Col md={6}>
                  <Form.Group className="pb-2">
                    <Form.Label>Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="number"
                      name="number"
                      value={values.number}
                      onChange={handleChange}
                      isInvalid={!!errors.number}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="pb-2">
                    <Form.Label>GSTIN</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="GSTIN"
                      name="GSTIN"
                      value={values.GSTIN}
                      onChange={handleChange}
                      isInvalid={!!errors.GSTIN}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="pb-2">
                    <Form.Label>Select SO</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={async (e) => {
                        await setFieldValue("salesmanName", e.target.value);
                        dispatch(
                          getDispatchByWithOutPage({
                            salesMan: e.target.value,
                          })
                        );
                      }}
                      name="salesmanName"
                      className="form-control"
                      value={values.salesmanName}
                      isInvalid={!!errors.salesmanName}
                    >
                      <option value="">Select SO</option>
                      {!!getSalesPersonWithoutPageList &&
                        getSalesPersonWithoutPageList.map((data, index) => {
                          return (
                            <option
                              value={data?.id ? data?.id : data?._id}
                              key={index}
                            >
                              {data?.name}
                            </option>
                          );
                        })}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="pb-2">
                    <Form.Label>FirmName</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="FirmName"
                      name="firmName"
                      value={values.firmName}
                      onChange={handleChange}
                      isInvalid={!!errors.firmName}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <p>
                <b>DispatchBy:</b>{" "}
                {!!dispatchList &&
                  dispatchList.map((data) => `${data?.userName}, `)}
              </p>
              <Form.Group className="pb-2">
                <label>Select DispatchBy</label>
                <Form.Control
                  as="select"
                  multiple={true}
                  onChange={handleChange}
                  name="dispatchBy"
                  className="form-control"
                  value={values.dispatchBy}
                  isInvalid={!!errors.dispatchBy}
                >
                  {!!dispatchByWithOutPage &&
                    dispatchByWithOutPage.map((data) => {
                      return (
                        <option value={data?._id ? data?._id : data?.id}>
                          {data?.dispatch}
                        </option>
                      );
                    })}
                </Form.Control>
              </Form.Group>
              <Row className="pb-2">
                <Col md={6}>
                  <Form.Group className="pb-2">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      isInvalid={!!errors.password}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="pb-2">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={4} sm={12}>
                      <Form.Group>
                        <Form.Label>Town</Form.Label>
                        <br />
                        <Form.Control
                          type="text"
                          placeholder="Town"
                          name="town"
                          value={values.town}
                          onChange={handleChange}
                          isInvalid={!!errors.town}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={4} sm={12}>
                      <Form.Group>
                        <Form.Label>State</Form.Label>
                        <br />
                        <Form.Control
                          style={{ textTransform: "capitalize" }}
                          as="select"
                          onChange={async (e) => {
                            setFieldValue("state", e.target.value);
                            dispatch(getDistrictList(e.target.value));
                          }}
                          name="state"
                          className="form-control"
                          value={values.state}
                          isInvalid={!!errors.state}
                        >
                          <option value="">Select State</option>
                          {statesList.map((data, index) => {
                            return (
                              <option
                                className="first-latter-capital"
                                key={index}
                                value={data.state}
                              >
                                {data.state}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md={4} sm={12}>
                      <Form.Group>
                        <Form.Label>District</Form.Label>
                        <br />
                          <Form.Control
                            style={{ textTransform: "capitalize" }}
                            as="select"
                            name="district"
                            onChange={handleChange}
                            value={values.district}
                            className="form-control"
                            isInvalid={!!errors.district}
                          >
                            <option value="">Select District</option>
                            {!!getDistrict &&
                              getDistrict.map((data, index) => {
                                return (
                                  <option
                                    className="first-latter-capital"
                                    value={data}
                                    key={index}
                                  >
                                    {data}
                                  </option>
                                );
                              })}
                          </Form.Control>
                      </Form.Group>
                    </Col>
              </Row>
              <Button type="submit" className="mt-1">
                Submit
              </Button>
              <Button variant="danger" onClick={handleClose} className="mt-1">
                Close
              </Button>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default UpdateSelectedDistributor;
