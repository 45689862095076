import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import * as yup from "yup";
import { getProducts, createOrder, getSelectedDistributor, getOrderListRest } from "../reducers/commonReducer";

const schema = yup.object().shape({
  status: yup.string().required(),
  invoiceNumber: yup.string().required(),
  dispatchBy: yup.string().required(),
  userPayment : yup.string().required(),
});

const CreateOrder = (props) => {
  let params = useParams();
  const dispatch = useDispatch();
  const { getProductsList } = useSelector(
    (state) => state.commonReducer
  );
  const { town, district, state, salesMan, assignDispatchBy, userName, number, firmName, dispatchBy } =
    props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="success" onClick={handleShow}>
        +Order
      </Button>
      <Modal show={show} onHide={handleClose}>
        <div className="row px-2">
          <Row className="text-center">
            <h3 className="mb-4 mt-4">Create and Dispatch Order</h3>
          </Row>
          <hr/>
          <div className="container-fluid pb-3">
            <Formik
              validationSchema={schema}
              onSubmit={async (values, { resetForm }) => {
                await dispatch(createOrder(values));
                resetForm({ values: "" });
                dispatch(getOrderListRest())
                dispatch(getSelectedDistributor({
                  salesmanName: params?.name
                }))
                dispatch(getProducts());
              }}
              initialValues={{
                order:
                  !!getProductsList &&
                  getProductsList.map((data) => ({
                    itemName: data?.itemName,
                    prise: data?.prise,
                    itemId: data?._id,
                    quantity: 0,
                    dquantity: 0
                  })),
                town: town,
                district: district,
                state: state,
                salesMan: salesMan,
                number: number,
                firmName: userName,
                invoiceNumber: '',
                dispatchBy:'',
                status:'',
                deliverDate:'',
                comment:'',
                userPayment:''
              }}
            >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                values,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Row className="pb-2">
                      <Col md={6}>
                        <Form.Group >
                          <label>Select DispatchBy</label>
                          <Form.Control
                            as="select"
                            onChange={handleChange}
                            name="dispatchBy"
                            className="form-control"
                            value={values.dispatchBy}
                            isInvalid={!!errors.dispatchBy}
                          >
                            <option>Select DispatchBy</option>
                            {!!assignDispatchBy && assignDispatchBy.map((data) => <option value={data?._id ? data?._id : data?.id}>{data?.dispatch}</option>)}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                              {errors.dispatchBy}
                            </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6} sm={6}>
                          <Form.Group>
                          <label>Invoice Number</label>
                            <Form.Control
                                type="text"
                                placeholder="Enter invoice Number"
                                name={`invoiceNumber`}
                                onChange={handleChange}
                                isInvalid={!!errors.invoiceNumber}
                              />
                            <Form.Control.Feedback type="invalid">
                              {errors.invoiceNumber}
                            </Form.Control.Feedback>
                          </Form.Group>
                      </Col>
                  </Row>
                  <Row className="pb-2">
                      <Col md={4}>
                        <Form.Group >
                          <label>Order Status</label>
                          <Form.Control
                            as="select"
                            onChange={handleChange}
                            name="status"
                            className="form-control"
                            value={values.status}
                            isInvalid={!!errors.status}
                          >
                            <option>Select Status</option>
                            <option value={"Pending"}>Pending</option>
                            <option value={"Processing"}>Processing</option>
                            <option value={"Deliver"}>Deliver</option>
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                              {errors.status}
                            </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group >
                          <label>Payment Status</label>
                          <Form.Control
                            as="select"
                            onChange={handleChange}
                            name="userPayment"
                            className="form-control"
                            value={values.userPayment}
                            isInvalid={!!errors.userPayment}
                          >
                            <option>Select Status</option>
                            <option value={"Pending"}>Pending</option>
                            <option value={"Confirmd"}>Confirmd</option>
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                              {errors.userPayment}
                            </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={4} sm={4}>
                          <Form.Group>
                          <label>Order Deliver Date</label>
                            <Form.Control
                                type="date"
                                placeholder="date"
                                name={`deliverDate`}
                                onChange={handleChange}
                                isInvalid={!!errors.deliverDate}
                              />
                            <Form.Control.Feedback type="invalid">
                              {errors.deliverDate}
                            </Form.Control.Feedback>
                          </Form.Group>
                      </Col>
                  </Row>
                  <Row className="mb-3">
                    {!!getProductsList &&
                      getProductsList.map((data, index) => {
                        return <Row className="mt-3" key={index}>
                              <Col md={6} sm={6}>
                                {data?.itemName}
                              </Col>
                              <Col md={6} sm={6}>
                                <Form.Group>
                                  <Form.Control
                                    type="number"
                                    placeholder="Product Quantity in Box"
                                    name={`order.${index}.quantity`}
                                    value={values.order[index].quantity}
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      setFieldValue(`order.${index}.quantity`, +value);
                                      setFieldValue(`order.${index}.dquantity`, +value);
                                    }}
                                    isInvalid={!!errors.order}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.order}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                            </Row>
                      })}
                  </Row>
                  <Row className="mt-2">
                      <Form.Group>
                        <Form.Label>Enter Your Comment</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Comment"
                          name="comment"
                          value={values.comment}
                          onChange={handleChange}
                          as="textarea"
                          rows={3}
                        />
                      </Form.Group>
                    </Row>
                  <Button type="submit" className="mt-3">
                    Submit
                  </Button>
                  <Button
                    variant="danger"
                    onClick={handleClose}
                    className="mt-3"
                  >
                    Close
                  </Button>
                </Form>
              )}
            </Formik>
            {/* <p>
              <b>{distributorAddMsg}</b>
            </p> */}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CreateOrder;
