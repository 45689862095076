import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getStatesList,
  getDistrictList,
  deleteFeedbackRecord,
  getOrderListRest,
  getContactList,
  deletContactRecord,
} from "../reducers/commonReducer";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Button } from "react-bootstrap";
import SideMenu from "../Components/SideMenu";
import moment from "moment";
import ExportData from "../Components/ExportData";
import WebSiteContactAction from "../Components/WebSiteContactAction";

const WebSiteContactList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [states, setStates] = useState();
  const [page, setPage] = useState(1);
  const [district, setDistrict] = useState();
  const [userStatus, setUserStatus] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const { getContactListData, statesList, userContactListBlank, getDistrict } =
    useSelector((state) => state.commonReducer);

  useEffect(() => {
    dispatch(getOrderListRest());
    dispatch(getContactList())
    dispatch(getStatesList());
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid close-menu-tab">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Sr</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Contact No.</th>
              <th scope="col">Date</th>
              <th scope="col">Address</th>
              <th scope="col">Subject</th>
              <th scope="col">Message</th>
              <th scope="col">Comment</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {!!getContactListData &&
              getContactListData.map((data, index) => {
                return <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{data?.userName}</td>
                      <td>{data?.email}</td>
                      <td>{data.number}</td>
                      <td>{moment(data.createdAt).format("DD/MM/YYYY")}</td>
                      <td>
                        {data?.town +
                          "," +
                          data?.city +
                          "," +
                          data?.state}
                      </td>
                      <td>{data?.subject}</td>
                      <td>{data?.message}</td>
                      <td>{data?.adminComment}</td>
                      <td>
                        <span
                          className={
                            (data.status == "Pending" && "text-primary") ||
                            (data.status == "Read" && "text-success")
                          }
                        >
                          <b>{data.status}</b>
                        </span>
                      </td>
                      <td>
                        {localStorage.getItem("x-auth-token") &&
                          localStorage.getItem("userRole") === "1" && (
                            <Button
                              className="m-1"
                              variant="danger"
                              onClick={async () => {
                                await(dispatch(deletContactRecord(data?._id)));
                                dispatch(getOrderListRest())
                                dispatch(getContactList());
                              }}
                            >
                              <i className="bi bi-trash"></i>
                            </Button>
                          )}
                            <WebSiteContactAction
                               adminCmnt={data?.adminComment}
                              userStatus={data?.status}
                              id={data?._id}/>
                      </td>
                    </tr>
              })}
          </tbody>
        </table>
        {!!userContactListBlank && userContactListBlank.length > 0 ? (
          <Row>
            <Button
              onClick={() => {
                setPage(page + 1);
                dispatch(
                  getContactList({
                    state: states,
                    district,
                    userStatus,
                    startDate,
                    endDate,
                    page: page + 1,
                  })
                );
              }}
            >
              Load More
            </Button>
          </Row>
        ) : (
          <p className="text-center">
            <b>No record found 😔.</b>
          </p>
        )}
      </div>
    </>
  );
};

export default WebSiteContactList;
