import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import * as yup from "yup";
import { Formik } from "formik";
import { getSelectedDistributor, getOrderListRest, userWiseTarget } from "../reducers/commonReducer";

const schema = yup.object().shape({
  includedTargets: yup.number().required(),
  year: yup.number().required(),
  month: yup.number().required(),
});

const TargetAction = (props) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const userid = queryParameters.get("userid");
  const dispatch = useDispatch();
  const { getProductsList } = useSelector(
    (state) => state.commonReducer
  );
  const { distributor, target, title, api, month, year, targetId, includedTargets } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button variant="warning" onClick={handleShow}>
       {title === "Add" ? `+ Target` : <i className="bi bi-pen-fill"></i>} 
      </Button>
      <Modal size={"lg"} show={show} onHide={handleClose}>
        <div className="row px-2">
          <Row className="text-center">
            <h3 className="mb-2 mt-2">{title} Target</h3>
          </Row>
          <hr/>
          <div className="container-fluid pb-3">
            <Formik
              validationSchema={schema}
              onSubmit={async (values, { resetForm }) => {
                await dispatch(api(values));
                resetForm({ values: "" });
                dispatch(getOrderListRest())
                title === "Add" ? dispatch(getSelectedDistributor({mode: "OffLine"})) : dispatch(userWiseTarget({
                 "distributor" : userid
                }))
              }}
              initialValues={{
                id:targetId,
                includedTargets,
                distributor,
                month, year,
                targets : target ? target.map((data) => ({
                  itemId: data?._id,
                  quantity: data?.quantity,
                })) :
                  !!getProductsList &&
                  getProductsList.map((data) => ({
                    itemId: data?._id,
                    quantity: 0,
                  })),
              }}
            >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                values,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Row>
                      <Col md={6}>
                        <Form.Group>
                        <Form.Control
                          as="select"
                          onChange={(e) => { 
                            setFieldValue("month", +e.target.value);
                          }}
                          name="month"
                          value={values.month}
                          className="form-control"
                          isInvalid={!!errors.month}
                      >
                          <option value="">Select Month</option>
                          <option value={1}>Jan</option>
                          <option value={2}>Feb</option>
                          <option value={3}>March</option>
                          <option value={4}>April</option>
                          <option value={5}>May</option>
                          <option value={6}>June</option>
                          <option value={7}>July</option>
                          <option value={8}>Aug</option>
                          <option value={9}>Sep</option>
                          <option value={10}>Oct</option>
                          <option value={11}>Nov</option>
                          <option value={12}>Dec</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                                {errors.month}
                              </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                      <Form.Group>
                      <Form.Control
                        type="number"
                        placeholder="Enter Year"
                        name="year"
                        value={values.year}
                        onChange={handleChange}
                        isInvalid={!!errors.year}
                      />
                       <Form.Control.Feedback type="invalid">
                                {errors.year}
                              </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    {!!getProductsList &&
                      getProductsList.map((data, index) => {
                        return <Col md={6} className="mt-3" key={index}>
                          <Row>
                              <Col md={8} sm={8}>
                               <b>{index + 1}</b>. {data?.itemName}
                              </Col>
                              <Col md={4} sm={4}>
                                <Form.Group> 
                                  <Form.Control
                                    type="number"
                                    placeholder="Enter Box"
                                    value={values?.targets?.[index]?.quantity || ""}
                                    name={`targets.${index}.quantity`}
                                    onChange={handleChange}
                                    isInvalid={!!errors.targets }
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.targets }
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                              </Row>
                            </Col>
                      })}
                  </Row>
                  <Row>
                  <Col md={12} sm={12}>
                            <Form.Group >
                              <Form.Label><b>Total Target</b></Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Total Target"
                                name="includedTargets"
                                value={values.includedTargets}
                                onChange={handleChange}
                                isInvalid={!!errors.includedTargets}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.includedTargets}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                  </Row>
                  <Button type="submit" className="mt-3">
                    Submit
                  </Button>
                  <Button
                    variant="danger"
                    onClick={handleClose}
                    className="mt-3"
                  >
                    Close
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TargetAction;
